import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Switch from "react-switch";
import FileUploadWithPreview from 'file-upload-with-preview'
import Change_password from './../Pop_up/Change_password'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.$;

class Myprofile extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {file: '',imagePreviewUrl: ''};
  }
 

_handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleChange(checked) {
    this.setState({ checked });
  }




	componentDidMount(){

}

render() {

 let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} />);
    } else {
      $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }


  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing myprofile_form">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
        <h1 className="main_title">
           My Profile
        </h1>
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">
<p className="form_heading_lebel">Personal Details</p>



<div className="form-inner-box full_width">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>First Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Last Name*</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Registered Email*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" " disabled />

<label>Phone*</label>
</div>
</div>	


<div className="form-inner-box full_with label_class extra_top">	
<label>Email Signature *</label>				
<div className="floating-label">      
            <CKEditor
                    editor={ ClassicEditor }
                    data="<p></p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


</div>
</div>


<form action="">

<div className="form-submit-button mt-50">
<div className="save_btn mypro_change"> <Change_password/> </div>
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>

</div>

</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Myprofile;