import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Review_pop = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle} className="save_job fa fa-star-o" ></i>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Submit Review </ModalHeader>
        <ModalBody>
        <div className="row">

					<div className="form-radio">
						<p><input type="radio" name="radio"/> <span>Gerenal Review</span></p>
						<p><input type="radio" name="radio"/> <span>Screening Review</span></p>
					</div>


<div className="rating_section_block">
<div className="section">
<div className="col-lg-6">
<p>Rating</p>
</div>
<div className="col-lg-6">
<div className="starRating without_border">
  <span className="btn one"><i className="fa fa-star"></i></span>
  <span className="btn two"><i className="fa fa-star"></i></span>
  <span className="btn three"><i className="fa fa-star"></i></span>
  <span className="btn four"><i className="fa fa-star"></i></span>
  <span className="btn five"><i className="fa fa-star"></i></span>
</div>
</div>
</div>
</div>

<div className="rating_section_block">
<div className="section">
<div className="col-lg-6">
<p>Choose Job Open</p>
</div>
<div className="col-lg-6">
<div className="starRating">
          <Button onClick={toggleNested}><i className="fa fa-briefcase"></i></Button>
          <Modal className="nested_model" isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined}>
            
            <ModalBody>
            
            <div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Related Job Openings</option>
<option value="2">All Job Openings</option>
</select>

<label>Show</label>
</div>
</div>

<div className="form-inner-box">
<div className="form_search_lits">					
<input type="text" className="jobPost_model"/>
<span className="jobpost_seh_icon">
<i className="fa fa-search"></i>
</span>
</div>
</div>

<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Job Opening ID</th>
<th>Posting Title</th>
<th>Target Date</th>
<th>Job Opening Status</th>
<th>Client Name</th>
<th>Assigned Recuriter(s)</th>
</tr>
</thead>
<tbody>
<tr>
<td><input type="radio" /> 12345 </td>
<td>Senior Manager</td>
<td>Mar 22 , 2020</td>
<td>Waiting for Approval</td>
<td>Paul</td>
<td>Sanjeev</td>
</tr>
</tbody>

</table>
</div>
            
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleNested}>Cancel</Button>{' '}
                    
              <Button color="primary" onClick={toggleNested}>Done</Button>{' '}
            </ModalFooter>
          </Modal>
</div>
</div>
</div>
</div>

<div className="rating_section_block">
<div className="section">
<div className="col-lg-6">
<p>Overall Comment</p>
</div>
<div className="col-lg-6">
<div className="starRating">
<textarea></textarea>
</div>
</div>
</div>
</div>



				</div>

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Review_pop;