import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Skills from './.././Dropdown/Skills'

const Job_filter = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle}  className="fa fa-filter"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader className="filter_header" toggle={toggle}>  <div className="filter_header_model">
        <div className="filter_modelheading">
        <span className="filter_tbs">Filter By</span>
        <span className="filter_over_view">Job Location</span>
        </div>
        </div>  </ModalHeader>
        <ModalBody  className="filter_model_box">
        <div className="filter_main_body">

<div className="filter_box">

            <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
      <TabList className="filter_type_menu">
<Tab tabFor="vertical-tab-one">Job Location</Tab>
<Tab tabFor="vertical-tab-two">Job Type</Tab>
<Tab tabFor="vertical-tab-three">Work Auth</Tab>
<Tab tabFor="vertical-tab-four">Client Manager</Tab>
<Tab tabFor="vertical-tab-five">Job Status</Tab>
<Tab tabFor="vertical-tab-six">Contract Type</Tab>
<Tab tabFor="vertical-tab-seven">Job Fill Type</Tab>
<Tab tabFor="vertical-tab-eight">Job priority</Tab>
<Tab tabFor="vertical-tab-nine">Job Category</Tab>
<Tab tabFor="vertical-tab-ten">Experience</Tab>
<Tab tabFor="vertical-tab-one_one">Job Open Date</Tab>
<Tab tabFor="vertical-tab-two_one">Account Manager</Tab>
<Tab tabFor="vertical-tab-three_one">Job Req Type</Tab>
<Tab tabFor="vertical-tab-four_one">Skills</Tab>
<Tab tabFor="vertical-tab-five_one">Qualification</Tab>
<Tab tabFor="vertical-tab-six_one">Degree</Tab>
<Tab tabFor="vertical-tab-seven_one">Course Type</Tab>
<Tab tabFor="vertical-tab-eight_one">Contract Duration</Tab>
<Tab tabFor="vertical-tab-nine_one">Rate Type</Tab>
      </TabList>
<div className="tablist_block_section">

      <TabPanel tabId="vertical-tab-one">
<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">

        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Huntsville</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Little Rock</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Los Angeles</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>	Hartford</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Washington</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Miami</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Pensacola</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Atlanta</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Boston</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>New York</span>
        </div>
        </div>
        </div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-two">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Any</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Contract</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Freelance</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Full time</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Part time</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Permanent</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Seasonal</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Temporary</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Training</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Volunteer</span>
        </div>
        </div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-three">
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>E3</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>EAD GC</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Green Card</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>H1B</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>L2 EAD</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Opt EAD</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>TN</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>US Citizen</span>
        </div>
        </div>
      </TabPanel>

           <TabPanel tabId="vertical-tab-four">
<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">

        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Azad Hussain</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Sanjeev</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Avinash</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Rakesh</span>
        </div>
        </div>
</div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-five">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Active</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Filled</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Onhold</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Cancelled</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Inactive</span>
        </div>
        </div>


      </TabPanel>

      <TabPanel tabId="vertical-tab-six">

        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>1099</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>C2C</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>W2</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Payroll</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Permanent Hire</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Subcon Company</span>
        </div>
        </div>


      </TabPanel>

           <TabPanel tabId="vertical-tab-seven">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Hard</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Intermatiate</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Regular</span>
        </div>
        </div>

      </TabPanel>

      <TabPanel tabId="vertical-tab-eight">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>High</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Medium</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Low</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>None</span>
        </div>
        </div>


      </TabPanel>

      <TabPanel tabId="vertical-tab-nine">
<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">

        <div className="filter_listing  single_line">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Frontend Develope</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Backend Developer</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>IT Infrastructure</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Mobile Applications</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Digital Marketing</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>DevOps</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Data Science and Analytics</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Web Designer</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Graphic Designer</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Emerging Technologies</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Consulting/Advisory</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Project Management</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Product Management</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>UI/UX Design</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Embedded Technology</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Software Testing</span>
        </div>
</div>

        </div>
      </TabPanel>

           <TabPanel tabId="vertical-tab-ten">
 <div className="filter_listing fromto">

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Min Exp (Years)
</label>
</div>
</div>

<span> To </span>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Max Exp (Years)
</label>
</div>
</div>

        </div>


      </TabPanel>

      <TabPanel tabId="vertical-tab-one_one">
 <div className="filter_listing fromto">

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

{/* <label>From job open date</label> */}
</div>
</div>

<span> To </span>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

{/* <label>Max Exp (Years)</label> */}
</div>
</div>

        </div>

      </TabPanel>

      <TabPanel tabId="vertical-tab-two_one">
<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">

 <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Azad</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Avinash</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Sanjeev</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span> Rakesh </span>
        </div>
        </div>
 </div>
      </TabPanel>

           <TabPanel tabId="vertical-tab-three_one">
 <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Back Fill</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>New Need</span>
        </div>
        </div>
       
      </TabPanel>

      <TabPanel tabId="vertical-tab-four_one">
<Skills/>
      </TabPanel>

      <TabPanel tabId="vertical-tab-five_one">
      <div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">
<div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Secondary Education or High School</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Vocational Qualification</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Undergraduate</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Certificate / Diploma</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Bachelor's Degree</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Master's Degree</span>
        </div>
         <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Doctorate or Professional Degree</span>
        </div>
        </div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-six_one">

<div className="degree_section">
<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>
<div className="tab_inner_section">

 <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>MBA/PGDM</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>CA</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>ICAI</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>LLB/LLM</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>B Tech / BE</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Barch</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>BSc</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>BCA</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>PhD</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>BBA</span>
        </div>
        </div>
</div>
</div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-seven_one">

<div className="degree_section">


<div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Full Time</span>
        </div>
       <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Part Time</span>
        </div>
      <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Online</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Distance or Correspondence</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Executive Program</span>
        </div>
        <div className="filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Certification</span>
        </div>

</div>


      </TabPanel>

      <TabPanel tabId="vertical-tab-eight_one">
 <div className="filter_listing fromto">

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

{/* <label>From job open date</label> */}
</div>
</div>

<span> To </span>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

{/* <label>Max Exp (Years)</label> */}
</div>
</div>

        </div>
      </TabPanel>

      <TabPanel tabId="vertical-tab-nine_one">

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Annual</option>
<option value="2">Monthly</option>
<option value="3">Weekly</option>
<option value="4">Hourly</option>
</select>

<label>Rate Type*</label>
</div>
</div>

 <div className="filter_listing fromto">

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Min Salary
</label>
</div>
</div>

<span> To </span>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Max Salary
</label>
</div>
</div>

        </div>

      </TabPanel>




</div>
    </Tabs>
    
</div>
</div>


        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="secondary" onClick={toggle}>Reset</Button>          
          <Button color="primary" onClick={toggle}>Apply</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Job_filter;