import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Homepage extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  
  <div className="homepage-outer">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>
<div className="homepage-inner container-fluid">

<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 user_action">
<div className="inner-block user_action_block">
<div className="user-action-img">
    <img src="" alt=""/>
</div>
<div className="user-action-name">
    <p><a href=""> Add/Manage User</a></p>
</div>
</div>
</div>

<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 user_action">
<div className="inner-block user_action_block">
<div className="user-action-img">
    <img src="" alt=""/>
</div>
<div className="user-action-name">
    <p><a href="">Customize</a></p>
</div>
</div>
</div>

<div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 user_action">
<div className="inner-block user_action_block">
<div className="user-action-img">
    <img src="" alt=""/>
</div>
<div className="user-action-name">
    <p><a href="">Get started</a></p>
</div>
</div>
</div>

</div>
</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Homepage;