import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Mapping from './../ATS_common/Mapping'
import Candidate_hiring_stages from './Candidate_hiring_stages'
import Candidate_status from './Candidate_status'
import Candidate_status_settings from './Candidate_status_settings'
import Reason_of_rejection from './Reason_of_rejection'
const $ = window.$;

class Candidate_status_management extends React.Component {

  constructor(){
    super();
    this.state={ }
  }
	componentDidMount(){
    }
    render() {
    return (
        <>
    <div className="main-body emp_body">
    <Header_after_login/>
        <div className="homepage-outer Hiring_status_mgmt_detail CSC CSM">
            <div className="top_user_detail_block">
                <div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
            </div>
            <div className="listing_section_block_section hrirng_status container">

                    <div className="deshboard_ouuter">
                        <h1>Candidate Status Management</h1>
                    </div>
                    <div className="share_job">
                        <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                            <TabList className="filter_type_menu">
                                <Tab tabFor="vertical-tab-one"> 
                                    <div className="job_upper_menu">
                                        <span>Candidate Hiring Stages</span>
                                    </div>
                                </Tab>
                                <Tab tabFor="vertical-tab-two">
                                    <div className="job_upper_menu">
                                        <span>Candidate Status</span>
                                    </div>

                                </Tab>
                                <Tab tabFor="vertical-tab-three">
                                    <div className="job_upper_menu">
                                        <span>Candidate Status Settings</span>
                                    </div>

                                </Tab>
                                <Tab tabFor="vertical-tab-four">
                                    <div className="job_upper_menu">
                                        <span>Reason of Rejection</span>
                                    </div>

                                </Tab>                                

                            </TabList>
                            <TabPanel tabId="vertical-tab-one">
                                <Candidate_hiring_stages/>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-two">
                                <Candidate_status/>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-three">
                                <Candidate_status_settings/>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-four">
                                <Reason_of_rejection/>
                            </TabPanel>
                        </Tabs>    
                    </div>    

            </div>
        </div>


        <Footer/>
    </div>
    </>
    );
    }

}
export default Candidate_status_management;