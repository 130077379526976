import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import classNames from 'classnames'
import Email_pop from './../Pop_up/Email_pop'
import Attachment_pop from './../Pop_up/Attachment_pop'
  import Choose_candidate from './../Pop_up/Choose_candidate'
import Job_filter from './../Pop_up/Job_filter'
import Share_job from './../Pop_up/Share_job'


const $ = window.$;

class Job_listing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {addClass: false}
  }
//   toggle() {
//     this.setState({addClass: !this.state.addClass});
//   }

	componentDidMount(){

}
toggleMobileFilter =()=>{
  var element = document.getElementById("app_pr_engment_panel");
  element.classList.toggle("active");
}
render() {
    let boxClass = ["box"];
    if(this.state.addClass) {
      boxClass.push('green');
    }
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing ">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="listing_section_block_section  container">
<div className="job_list_section_block">

<div className="top_section_menu">
<div className="title_name_of_page">
    <h1>Job Listing</h1>
</div>
<div className="add_new_to_page">
    <span><Link to="/Job_post_form"><i className="fa fa-plus"></i> New job</Link></span>
</div>
</div>

<div className="top_page_filter_set">
<div className="search_filter_box">
<input type="text" name=""/><span><i className="fa fa-search"></i></span>
</div>
{/* <div className="mtd_filter_box">
<span> <i className="fa fa-calendar"></i><select><option>MTD</option><option>Yesterday</option><option>last Week</option></select></span>
</div> */}
{/* <div className="posted_on_filter">
<span><i className="fa fa-folder"></i><select><option>My jobs</option><option>Team Job</option><option>Share Job</option></select></span>
</div> */}
    <div className="add_new_to_page mtd_filter_box">
        <span className="title_name_drop_down">
        <i className="fa fa-folder"></i> My jobs <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>Team jobs</li>
                <li>Shared jobs</li>
            </ul>
        </div>
    </div>
    <div className="add_new_to_page mtd_filter_box">
        <span className="title_name_drop_down">
        <i className="fa fa-calendar"></i> MTD <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>Yesterday</li>
                <li>Last Week</li>
            </ul>
        </div>
    </div>

<div className="main_filter_box">
<span><Job_filter/></span>
</div>
<div className="date_filter_box">
<span><i className="fa fa-sort-amount-desc"id="asc-desc" onClick={ ()=>this.sort() }>Sort asc</i> 
{
(this.state.sortby=='asc')? <div></div>: <div></div>
}
</span>
</div>

</div>

<div className="list_view_result_view">
<div className="left_side_result_view">
<span>Results</span><span className="list_count"> : 10</span>
</div>
<div className="filter_box">
<div className="filter_title">
<span>Applied Filter</span>
<div className="filter_result">
<div className="filter_single">
<span>Html</span><i className="fa fa-times"></i>
</div>
</div>
</div>
</div>

{/* <div className="right_side_result_view">
<span>view :</span>
<span id="list_view" className="click_to_change list_view_system active">List View</span> |
<span id="grid_view" className="click_to_change grid_view_system">Grid View</span>
</div> */}

</div>
<div className="all_type_job_listing_hide">
<div className="all_job_listing_section-outer" id="main_grid_view">

<div className="all_job_listing_section-inner">
<div className="listing_top_row">	
<div className="job_role top_left_side"><Link to="/Job/Job_Detail"> Front End Developer <span className="job_id_ref">(Job ID/Reference Code/4-6 Yrs)</span></Link></div>
<div className="job_top top_right_side">
    <div className="job_status">Job Status <span>Active</span></div>
    <div className="edit_job"><Link to=""><i className="fa fa-pencil"></i>Edit</Link></div>
    <div className="save_job"><i className="fa fa-bookmark"></i>Save</div>
</div>
</div>
<div className="job_mid_section">
<div className="job_main_detail">
    <div className="company_address">
        <span className="company_name">Infosys Public Services,</span>
        <span className="company_location">  Rockville, Maryland</span>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Job Type :
            </span>
            <span className="type_wor_auth_view">
                Full Time
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Work Auth :
            </span>
            <span className="type_wor_auth_view">
                H1B, EAD
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Contract type :
            </span>
            <span className="type_wor_auth_view">
                Full-Time
            </span>
        </div>
    </div>

    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                job Fill Type :
            </span>
            <span className="type_wor_auth_view">
                Intermediate
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Job Priority :
            </span>
            <span className="type_wor_auth_view">
                High
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Req. type :
            </span>
            <span className="type_wor_auth_view">
                New Need
            </span>
        </div>

    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Client Mgr :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr. Dev Brown</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Account Mgr : 
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr. Dev Prakash</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Opening :
            </span>
            <span className="type_wor_auth_view">
                3
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Open Date :
            </span>
            <span className="type_wor_auth_view">
                Feb. 20, 2020
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Target Date :
            </span>
            <span className="type_wor_auth_view">
                Apr. 20, 2020
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
               Posted On :
            </span>
            <span className="type_wor_auth_view">
                Feb 20, 2020
            </span>
        </div>
    </div>

    <div  className="job_type_auth_contact">
    
        <div className="job_type">
            <span className="type_wor_auth">
                Rate Type :
            </span>
            <span className="type_wor_auth_view">
                Hourly
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Rate($) :
            </span>
            <span className="type_wor_auth_view">
                30$-50$
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
               Posted by :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Rakesh yadav</a>
            </span>
        </div>

</div>
    <div  className="job_type_auth_contact">
    
        <div className="job_type">
            <span className="type_wor_auth">
            Last Updated :
            </span>
            <span className="type_wor_auth_view">
            Apr. 1, 2020
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Updated by :
            </span>
            <span className="type_wor_auth_view">
            <a href="">Rakesh yadav</a>
            </span>
        </div>
    </div>



</div>
<div className="job_image_section">
<img src="./images/defult_logo.png" alt=""/>
</div>

<div className="all_icon_list_row">

<div className="skill_section_listing">
<span className="type_wor_auth">Essential Skills :</span>
<div className="skill_fill_section">
<span className="skill_set_listing">React Js</span>
<span className="skill_set_listing">Angular Js</span>
<span className="skill_set_listing">Node Js</span>
</div>
</div>
{/* 
<div className="post_last_update">
<span className="type_wor_auth">
Posted On :
</span>
<span className="type_wor_auth_view">
Feb 20, 2020
</span> 

 <span className="type_wor_auth">
Posted by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

<span className="type_wor_auth">
Last Updated :
</span>
<span className="type_wor_auth_view">
Apr. 1, 2020
</span>

<span className="type_wor_auth">
Updated by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

</div> */}
<div class="all_sup_icon">
<i className="fa fa-file" title="Archieve"></i>
<i className="fa fa-refresh" aria-hidden="true" title="Refresh"></i>
<Email_pop/>
<Share_job/>
<Attachment_pop/>
<Choose_candidate/>
<i className="fa fa-reorder" aria-hidden="true" title="Other Actions"></i>
</div>

</div>

<div className="buttom_job_list_section">
<div className="app_pr_engment">
<div className="app_pr_engment_flip">
    <span className="app_pr_eng_name">Applicants Progressive Engagement:</span>
    <span className="app_pr_number">2/8</span>
    <span className="app_pr_team"><strong>Team </strong>: 3</span>
    <span className="app_pr_team"><strong>Team </strong>: 3</span>
        <span id="app_pr_engment_flip" className={boxClass.join(' ')} onClick={()=>this.toggleMobileFilter ()}>
        <i className="fa fa-chevron-down"> </i>
    </span>
</div>
<div className="app_pr__follow_up top_most_uper">
<div className="follow_up_internal">
<div className="no_before have_before internal_list follow_up_yes">
    <span>8</span>
    <span>internal Submission</span>
</div>
</div>
<div className="follow_up_client">
<div className="client_list back_arrow_in_list have_before follow_up_yes">
    <span>6</span>
    <span>Client Submission</span>
</div>
</div>
    <div className="follow_up_offered">
<div className="offered_list back_arrow_in_list have_before follow_up_yes">
    <span>3</span>
    <span>Offered</span>
</div>
</div>
<div className="follow_up_accpted">
<div className="accpted_list back_arrow_in_list have_before follow_up_yes">
    <span>1</span>
    <span>Joined</span>
</div>
</div>


</div>

<div id="app_pr_engment_panel">
    
<div className="app_pr__follow_up">
<div className="follow_up_internal">
<div className="no_before have_before internal_list follow_up_no">
    <span>2</span>
    <span>Internal Screen Rejected</span>
</div>
</div>
<div className="follow_up_client">
<div className="client_list have_before follow_up_no">
    <span>1</span>
    <span>Client Screen Rejected</span>
</div>
<div className="no_before have_before client_list follow_up_yes">
    <span>5</span>
    <span>Client Interview</span>
</div>
<div className="no_before have_before client_list follow_up_no">
    <span>2</span>
    <span>Interview Rejected</span>
</div>
<div className="no_before have_before client_list follow_up_yes">
    <span>3</span>
    <span>Ready to Offer</span>
</div>
</div>
    <div className="follow_up_offered">
<div className="offered_list have_before follow_up_no">
    <span>1</span>
    <span>Accepted</span>
</div>
<div className="offered_list have_before follow_up_yes">
    <span>1</span>
    <span>Declined/No show</span>
</div>
</div>
<div className="follow_up_accpted">

</div>
</div>
</div>

</div>
</div>
</div>

</div>



</div>


<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul>
</nav>
</div>
</div>
<div className="all_job_listing_view" id="main_list_view">

<table className="table table-bordered">
<tr>
    <th>Job Ref. Code</th>
    <th>Job Title</th>
    <th>Assigned Recruiter(s)</th>
    <th>Target Date</th>
    <th>Job Status</th>
    <th>Location</th>
    <th>Actions</th>
</tr>
<tr>
    <td>2134</td>
    <td>Front End Developer</td>
    <td>Azad hussain</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
<tr>
    <td>2134</td>
    <td>Back End Developer</td>
    <td>Avinash</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
<tr>
    <td>2134</td>
    <td>Web Designer</td>
    <td>Rakesh Yadav</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
</table>

</div>


</div>


</div>

</div>


  <Footer/>
 
    </>
  );
}

}
export default Job_listing;