import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Choose_job from './../Pop_up/Choose_job'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.$;

class Interview_form extends React.Component {

constructor(){
super();
this.state={ }
}

componentDidMount(){

}

render() {
return (
<>


<div className="main-body emp_body">
<Header_after_login/>

<div className="homepage-outer form-container-listing interview_form">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="form-container">

<div className="inner-form-container container">
<div className="all-form-width interview">

<div className="signup-form-homepage">
<h1 className="main_title">  Create Interview</h1>


<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Internal Interview</option>
<option value="2">Gernal interview</option>
<option value="3">Online Interview</option>
<option value="4">Phone Interview</option>
</select>

<label>Interview Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value="">Rakesh yadav</option>
<option value="1">Azad</option>
<option value="2">Nida</option>
</select>

<label>Candidate Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value="">Long Finch</option>
<option value="1">Techvilla</option>
</select>

<label>Client Name
</label>
</div>
</div>

<div className="form-inner-box box-choose">
<span class="choose_jobs_">Job_title</span>				
<Choose_job/>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Interviewer Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Interview Email
</label>
</div>
</div>



<div className="form-inner-box inter_timedte full_with">					

<div class="interview_time_dte">
<div className="form-inner-box left_one">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
  <option value="1">Apr 10, 2020</option>
  <option value="2">Apr 11, 2020</option>
  <option value="3">Apr 12, 2020</option>
  <option value="4">Apr 13, 2020</option>
  <option value="5">Apr 14, 2020</option>
  <option value="6">Apr 15, 2020</option>
</select>

<label>Interview Date</label>
</div>
</div>

<div className="form-inner-box rigt_one">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">09:00 P.M</option>
<option value="2">09:30 P.M</option>
<option value="3">10:00 P.M</option>
<option value="4">10:30 P.M</option>
<option value="4">11:00 P.M</option>
<option value="4">11:30 P.M</option>
<option value="4">12:00 P.M</option>
<option value="4">12:30 P.M</option>
<option value="4">01:00 P.M</option>
</select>

<label>Interview Time
</label>
</div>
</div>

<div className="form-inner-box left_two">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">PST</option>
<option value="2">EST</option>
<option value="3">CST</option>
</select>

<label>Time Zone
</label>
</div>
</div>

<div className="form-inner-box right_two">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">F2F</option>
<option value="2">Skype</option>
<option value="3">Telephonic</option>
<option value="4">Other</option>
</select>

<label>Interview Mode
</label>
</div>
</div>

</div>

</div>

<div className="form-inner-box notify full_with">
<div className="form-inner-box">
<p>Notify Interviewer By</p>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Email</label>
<input type="checkbox" name=""/>
</div>
</div>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Sms</label>
<input type="checkbox" name=""/>
</div>
</div>
</div>


<div className="form-inner-box">
<p>Notify Interviewer By</p>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Email</label>
<input type="checkbox" name=""/>
</div>
</div>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Sms</label>
<input type="checkbox" name=""/>
</div>
</div>
</div>
</div>


<div className="form-inner-box full_with">
<div className="form-inner-box left-margin">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">30 min before</option>
<option value="2">60 min before</option>
<option value="3">90 min before</option>
<option value="4">120 min before</option>
</select>

<label>Send reminder to Interviewer
</label>
</div>
</div>

<div className="form-inner-box right-margin">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">30 min before</option>
<option value="2">60 min before</option>
<option value="3">90 min before</option>
<option value="4">120 min before</option>
</select>

<label>Send reminder to Candidate
</label>
</div>
</div>
</div>

<div className="form-inner-box full_with label_class extra_top">	
<label>Invite Venue</label>					
<div className="floating-label">      
            <CKEditor
                    editor={ ClassicEditor }
                    data="<p></p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


</div>
</div>

<div className="form-inner-box full_with label_class extra_top">	
<label>Invite Message</label>					
<div className="floating-label">      
            <CKEditor
                    editor={ ClassicEditor }
                    data="<p></p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


</div>
</div>





<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Schedule Interview</button>
</div>



</form>

</div>

</div>

</div>

</div>

</div>



<Footer/>
</div>
</>
);
}

}
export default Interview_form;