import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header from './../ATS_common/Header'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Company_detail extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header/>



        <div className="job_filter_name_heading">
            <div className="job_heding_name">
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">
	
	<div className="inner-form-container container">
		<div className="all-form-width">
		<div className="signup-form-homepage">
			<p>Company Detail</p>
			<form action="">

				<div className="form-inner-box">
					<input type="text" name="company" placeholder="Company Name" />
				</div>

				<div className="form-inner-box">
					<input type="text" name="phone" placeholder="Phone Number" />
				</div>
					
				<div className="form-inner-box">
					<input type="text" name="website" placeholder="Website" />
				</div>

				<div className="form-inner-box">
					<select>
						<option selected disabled>Time Zone</option>
						<option>PST</option>
						<option>EST</option>
					</select>
				</div>
					
				<div className="form-inner-box">
					<select>
						<option selected disabled>Country for ATS</option>
						<option>USA</option>
						<option>England</option>
						<option>India</option>
						<option>Austria</option>
					</select>
				</div>

				<div className="form-submit-button">
					<button type="submit"> Submit </button>
				</div>
				
			</form>

		</div>

	</div>
	
</div>

</div>



  <Footer/>
  </div>
    </>
  );
}

}
export default Company_detail;