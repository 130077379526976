
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import Choose_job from './../Pop_up/Choose_job'
const Update_job_status = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <span onClick={toggle}className="update_ststuss">Update Status</span>
    <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader className="filter_header" toggle={toggle}>  
            <div className="filter_header_model">
                <div className="filter_modelheading">
                    <span className="filter_tbs">Update Status</span>
                </div>
            </div>  
        </ModalHeader>
        <ModalBody>
                <div className="row">

                    <div className="rating_section_block">
                        <div className="section">
                            <div className="col-lg-6">
                                <p>Select Job Status</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-inner-box">					
                                    <div className="floating-label">      
                                        <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                                            <option value=""></option>
                                            <option value="1">Active</option>
                                            <option value="2">Covered</option>
                                            <option value="3">Hold</option>
                                            <option value="4">Closed</option>
                                            <option value="5">InActive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="rating_section_block">
                        <div className="section">
                            <div className="col-lg-6">
                                <p>Comment</p>
                            </div>
                            <div className="col-lg-6">
                                <div className="starRating">
                                    <textarea></textarea>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>          
            <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
    </Modal>
    </>
  );
}

export default Update_job_status;