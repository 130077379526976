import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Master_list_filter from './../Component/Master_component/Master_list_filter'
const $ = window.$;

class Homepage extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  
  <div className="homepage-outer">

  <div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>


<div className="homepage-inner container">

<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 master_all_list">
<div id="master_list">
<Master_list_filter/>

</div>
</div>
<div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 master_table_form">
    <div className="master_inner">
<div className="master_apply_form">
    <div className="master_mai_for">
        <h1 className="main_title">Assessment Master</h1>
            <div className="mast_form_start">
            <div className="form-inner-box full_with">
                <div className="floating-label">      
                <input className="floating-input" type="text" placeholder=" "/>

                <label>Assessment Type</label>
                </div>
                </div>
                <div className="floating-label master_checkbox">
                <label>Active Status</label>
                <input type="checkbox" name=""/>
                </div>
<div className="master_subcan_btn">
    <button className="master_save">Save</button>
    <button className="master_cancel">Cancel</button>
</div>

            </div>
    </div>
</div>

<div className="master_filled_table">

<table  className="table-bordered table">
    <tr>
        <th>Assessment Type</th>
        <th>Created On</th>
        <th>Created By</th>
        <th>Updated On</th>
        <th>Updated By</th>
        <th>Active Status</th>
    </tr>
<tr>
    <td>Background Screening</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td><input type="checkbox" name=""/></td>
</tr>
<tr>
    <td>Behavioral Screening</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td><input type="checkbox" name=""/></td>
</tr>
<tr>
    <td>Behavioural Interview</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td><input type="checkbox" name=""/></td>
</tr>
<tr>
    <td>Pre-Screening</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td><input type="checkbox" name=""/></td>
</tr>
<tr>
    <td>General</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td><input type="checkbox" name=""/></td>
</tr>

</table>
</div>
</div>
</div>
</div>
  </div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Homepage;