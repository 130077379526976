
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

const Client_filter = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle}  className="fa fa-filter"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader className="filter_header" toggle={toggle}>  <div className="filter_header_model">
        <div className="filter_modelheading">
        <span className="filter_tbs">Filter By</span>
        <span className="filter_over_view">Vendor Name</span>
        </div>
        </div>  </ModalHeader>
        <ModalBody  className="filter_model_box">
        <div className="filter_main_body">

<div className="filter_box">

            <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
      <TabList className="filter_type_menu">
<Tab tabFor="vertical-tab-one">Client Name</Tab>
<Tab tabFor="vertical-tab-two">Client Location</Tab>
      </TabList>
<div className="tablist_block_section">

<div className="filter_search_box">
<input type="text" className="filter_search_input" placeholder="Search"/>
</div>


           <TabPanel tabId="vertical-tab-one">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Azad Hussain</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Sanjeev</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Avinash</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Rakesh</span>
        </div>
        </div>

      </TabPanel>

      <TabPanel tabId="vertical-tab-two">
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Huntsville</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Little Rock</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Los Angeles</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>	Hartford</span>
        </div>
        </div>
        <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Washington</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Miami</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Pensacola</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Atlanta</span>
        </div>
        </div>
         <div className="filter_listing">
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>Boston</span>
        </div>
        <div className="single_filter_listing">
        <span> <input type="checkbox"/> </span>
        <span>New York</span>
        </div>
        </div>
      </TabPanel>

</div>
    </Tabs>
    
</div>
</div>


        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="secondary" onClick={toggle}>Reset</Button>          
          <Button color="primary" onClick={toggle}>Apply</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Client_filter;