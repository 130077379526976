import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Choose_job from './../Pop_up/Choose_job'
const $ = window.$;

class Assessment extends React.Component {

constructor(){
super();
this.state={ showOption: false, }
}

componentDidMount(){

}


   handleOptionChange = (event) => {
      event.preventDefault();
      this.setState({
      showOption : true
    }) 
    console.log('changed')
   }
   
render() {
return (
<>


<div className="main-body emp_body">
<Header_after_login/>

<div className="homepage-outer form-container-listing">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="form-container">

<div className="inner-form-container container">
<div className="all-form-width interview assesment_form_">

<div className="signup-form-homepage">
<h1 className="main_title">  Create Assessment</h1>

<div className="assesment_top_">
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Internal Assessment</option>
<option value="2">Interview Assessment</option>
</select>

<label>Assessment
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Assessment Name</label>
</div>
</div>




</div>

<div className="assessment_question_and_answer">
  <div className="form-inner-box choose_category">					
    <div className="floating-label">      
    <select className="floating-select" id="colorselector">
    <option value=""></option>
    <option value="multiple">Multiple</option>
    <option value="single">Single Choice</option>
    <option value="long">Long answer</option>
    <option value="short">Short answer</option>
    </select>
    <label>Assessment Type</label>
    </div>
  </div>
    <div id="multiple" className="assess multiple">
        <div className="form-inner-box full_with">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>What would you like to ask?
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="add_question">
        
        <span className="add_que"><i className="fa fa-plus adqu"></i> Add more Option </span>
        </div>

        <form action="">

        <div className="form-submit-button">
        <button className="cancel_btn" type="submit"> Cancel </button>
        <button className="save_btn" type="submit"> Save </button>
        </div>



        </form>

    </div>



    <div id="single" className="assess single">
        <div className="form-inner-box full_with">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>What would you like to ask?
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>


        <div className="add_question">
            
             <span className="add_que"><i className="fa fa-plus adqu"></i> Add more Options  </span>
        </div>

            <form action="">

            <div className="form-submit-button">
            <button className="cancel_btn" type="submit"> Cancel </button>
            <button className="save_btn" type="submit"> Save </button>
            </div>



            </form>

    </div>






    <div id="short" className="assess short">
    <div className="form-inner-box full_with">					
    <div className="floating-label">      
    <textarea className="floating-input floating-textarea" placeholder=" "></textarea>
    <span>Not more than 200 Words</span>
    <label>What would you like to ask?</label>
    </div>
    </div>

    <form action="">

    <div className="form-submit-button">
    <button className="cancel_btn" type="submit"> Cancel </button>
    <button className="save_btn" type="submit"> Save </button>
    </div>



    </form>
    </div>






    <div id="long" className="assess long">
    <div className="form-inner-box full_with">					
    <div className="floating-label">      
    <textarea className="floating-input floating-textarea" placeholder=" "></textarea>
    <span>Not More than 500 words</span>
    <label>What would you like to ask?</label>
    </div>
    </div>

    <form action="">

    <div className="form-submit-button">
    <button className="cancel_btn" type="submit"> Cancel </button>
    <button className="save_btn" type="submit"> Save </button>
    </div>



    </form>

    </div>



    </div>






{/* <div class="output">
  <div id="red" class="colors red">  “Good artists copy, great artists steal” Pablo Picasso</div>
  <div id="yellow" class="colors yellow"> “Art is the lie that enables us to realize the truth” Pablo Picasso</div>
  <div id="blue" class="colors blue"> “If I don't have red, I use blue” Pablo Picasso</div>
</div> */}


</div>

</div>

</div>

</div>

</div>



<Footer/>
</div>
</>
);
}

}
export default Assessment;