import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Admin extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}


render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  
  <div className="homepage-outer">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>
<div className="homepage-inner container-fluid">
<h1 className="admin_heading">Admin - Control Panel</h1>
<div className=" col-xs-12 admin_">

<div className="inner-block user_action_block">
<div className="user-action-name">
    <p><Link to="/master" target="_blank" > Manage Masters</Link></p>
</div>
</div>
</div>

<div className=" col-xs-12 admin_">
<div className="inner-block user_action_block">
<div className="user-action-name">
    <p><Link to="manage_user_list" target="_blank" > Manage Users</Link></p>
</div>
</div>
</div>

<div className=" col-xs-12 admin_">
<div className="inner-block user_action_block">
<div className="user-action-name">
    <p><Link to="/user_access" target="_blank" > User Access</Link></p>
</div>
</div>
</div>

<div className=" col-xs-12 admin_">
  <div className="inner-block user_action_block">
    <div className="user-action-name">
        <p><Link to="/Candidate_status_management" target="_blank" > Candidate Status Management</Link></p>
    </div>
  </div>
</div>

<div className=" col-xs-12 admin_">
<div className="inner-block user_action_block">
<div className="user-action-name">
    <p><Link to="/company_profile" target="_blank" > Company Profile</Link></p>
</div>
</div>
</div>

<div className=" col-xs-12 admin_">
  <div className="inner-block user_action_block">
    <div className="user-action-name">
        <p><Link to="/system_config" target="_blank" > System Configurations</Link></p>
    </div>
  </div>
</div>
</div>
</div>
        <Footer/>
  </div>
    </>
  );
}

}
export default Admin;