import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';

import { get } from './../../services/Apicall';

const $ = window.$;

class Header extends React.Component {





render() {
  return (
    <>



<header>
<div className="main-container ats_contaiber">
  <nav className="navbar navbar-default">
    <div className="inner-container container">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar7">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
	      <a className="navbar-brand" href="">
	      	<img src="./images/logo.png" alt="Dispute Bills"/>
     	  </a>
      </div>
    
      <div id="navbar7" className="navbar-collapse collapse">
        <ul className="nav navbar-nav navbar-right">
          <li className="menu_item"><Link to="/Signin">Login</Link></li>
        </ul>
      </div>
      {/* <!--/.nav-collapse --> */}
    </div>
    {/* <!--/.container-fluid --> */}
  </nav>
</div>

</header>


</>
);
}

}


const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default Header;
