import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Industry from './.././Dropdown/Industry'

import Switch from "react-switch";
import FileUploadWithPreview from 'file-upload-with-preview'

const $ = window.$;

class Client_form extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {file: '',imagePreviewUrl: ''};
  }
 

_handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleChange(checked) {
    this.setState({ checked });
  }




	componentDidMount(){

}

render() {



  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
        <h1 className="main_title">
           Add New Client
        </h1>
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">

<p className="form_heading_lebel">Client Information</p>



<div className="form-inner-box full_width">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Client Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Parent Client*</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Contact Number
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Fax</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Acoount Manager</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Website</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<Industry/>
</div>
</div>

<p className="form_heading_lebel">Billing Address Information</p>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">USA</option>
<option value="2">England</option>
<option value="3">India</option>
</select>
<label>Country*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Alabama</option>
<option value="2">Hawaii</option>
<option value="3">Massachusetts</option>
<option value="4">Mississippi</option>
<option value="5">New Jersey</option>
<option value="5">South Carolina</option>
<option value="5">Texas</option>
</select>
<label>State/Province*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>City*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Zip/Postal Code*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Street</label>
</div>
</div>


<form action="">

<div className="form-submit-button mt-50">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>

</div>

</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Client_form;