import React, { useState } from 'react';
import Update_candidate_status from './../Pop_up/Update_candidate_status';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const Associated_candidate = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle} className="fa fa-plus" title="Associated Candidate"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className} className="nested_model">
        <ModalHeader toggle={toggle}>Associated Candidate </ModalHeader>

          <ModalBody>
            
<div className="form-inner-box">
<div className="form_search_lits">					
<input type="text" className="jobPost_model"/>
<span className="jobpost_seh_icon">
<i className="fa fa-search"></i>
</span>
</div>
</div>

<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>

<th>Candidate Name</th>
<th>City</th>
<th>Candidate Status</th>
<th>Rating</th>
<th>Source</th>

</tr>
</thead>
<tbody>
<tr>
<td><input type="radio"/>David Smith</td>
<td>Gulfport</td>
<td className="updatestatus">Waiting for Approval <span><Update_candidate_status/></span></td>
<td>
<div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div>
</td>
<td>Added by user</td>
</tr>
</tbody>

</table>
</div>
            
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggle}>Close</Button>{' '}
            </ModalFooter>
          </Modal>
    </>
  );
}

export default Associated_candidate;