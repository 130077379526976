import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Candidate_filter from './../Pop_up/Candidate_filter'
import Edit_interview from './../Pop_up/Edit_interview'


const $ = window.$;

class Interview_listing extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}

render() {
  return (
    <>
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing ">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="listing_section_block_section interview_form  container">
<div className="job_list_section_block">

<div className="top_section_menu">
<div className="title_name_of_page">
    <h1>Interview Listing</h1>
</div>
<div className="add_new_to_page">
    <span className="title_name_drop_down"><i className="fa fa-ellipsis-h"></i></span>
    <div className="list_show_drop_down">
<ul>
<li>xyz</li>
<li>abc</li>
</ul>
</div>
</div>
<div className="add_new_to_page">
    <span><Link to="/Interview_form"><i className="fa fa-plus"></i> Add new Interview</Link></span>
</div>
<div className="add_new_to_page">
    <span className="title_name_drop_down"><i className="fa fa-plus"></i> Import <i className="fa fa-chevron-down"></i></span>
    <div className="list_show_drop_down">
<ul>
<li>Import From Excel</li>
</ul>
</div>
</div>
</div>

<div className="top_page_filter_set">
<div className="search_filter_box">
<input type="text" name=""/><span><i className="fa fa-search"></i></span>
</div>
{/* <div className="posted_on_filter">
<span><i className="fa fa-folder"></i>
<select>
<option>All Interview</option>
<option>My Interview</option>
<option>Team Interview</option>
<option>Recent Interview</option>
</select>
</span>
</div> */}
    <div className="add_new_to_page mtd_filter_box">
        <span className="title_name_drop_down">
        <i className="fa fa-folder"></i> All Interview <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>My Interview</li>
                <li>Team Interview</li>
                <li>Recent Interview</li>
            </ul>
        </div>
    </div>

</div>

<div className="list_view_result_view">
<div className="left_side_result_view">
<span>2 results</span>
</div>
</div>

</div>
<div className="all_interview_listing_view" id="main_list_view">


<div className="interview_listing">

<div className="interview_box_sec">

<div class="view-schedule col-12">

        
        <div class="interule top-schedule">
          <div class="interf">
          <span class="interposit">Web Designing ( position#33 )</span>
          <span class="interposit"><strong>Client </strong>: Wipro</span>
         
        </div>
        <div class="inters">
          <span class="interstat ">
            <button type="">Scheduled</button>
          </span>
        </div>
          <div class="interl">
          
          <Edit_interview/>
        </div>
          <div class="intert"><span class="intersch">Scheduled for</span>
          <span class="interdate"> <strong>Apr. 15, 2020 12:00 AM</strong></span>
        </div>
        <div class="interstr">
          <a href="">
            <div class="my-rating" data-rating="">
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
                    </div>
          </a>
            <i class="h_m_ny_re_view fa">(0)</i> <a href="/rating_edit">Write Rewiew</a> 
        </div>

        </div>
  
  <div class="interule">
          <div class="intersn"> 
         <span><strong>Name</strong></span> 
         <span>Tarun Singh</span>  
         </div>
        <div class="interss"> 
         <span><strong>Email</strong></span>
         <span>tarunsingh7102@gmail.com</span>   
         </div>
        <div class="interst"> 
         <span><strong>Phone no.</strong></span> 
         <span>9958362370</span>  
         </div>
               
  <button type="button" class="colptitle top_show_more" data-toggle="collapse" data-target="#demo56">Show More <span class="fa fa-sort-down"></span></button>
  <div id="demo56" class="collapse colpdetail">
                <div class="interss"> 
         <span><strong>Interviewer Name</strong></span>
         <span>Azad Hussain</span>   
         </div>
            <div class="interss"> 
         <span><strong>Interviewer Email</strong></span>
         <span>Azad@techvilla.in</span>   
         </div>

        <div class="interst"> 
         <span><strong>Interviewer Phone no.</strong></span> 
         <span>+1-9090909090</span>  
         </div>
         <div class="interst"> 
         <span><strong>Interview mode</strong></span> 
         <span>F2F</span>  
         </div>
                 <div class="interst"> 
         <span><strong>Interviewer Designation</strong></span> 
         <span>Sr. Hr Manager</span>  
         </div>
          <div class="interst full_width"> 
         <span><strong>Venue of F2F Round</strong></span> 
         <span>We are looking for Candidate like Represent the firm and share point of view around digital technologies (blockchain, intelligent automation, artificial intelligence, internet of things, cloud, etc.) in external conferences, boardroom presentations, industry events, and public videos</span>  
         </div>

 <button type="button" class="colptitle top_less_more hidden" data-toggle="collapse" data-target="#demo56">Show Less <span class="fa fa-sort-up"></span></button>

  </div>
  </div>
<div className="interview_progress_step">
    <div className="all_pro_listing">
        <ul className="top_date_sec">
            <li className="active">Apr. 15, 2020 09:39 PM</li>
            <li class="active">Apr. 15, 2020 09:39 PM</li>
            <li class="int_cancel">Apr. 15, 2020 09:39 PM</li>
            <li class="int_cancel">Apr. 15, 2020 09:39 PM</li>
        </ul>
    <ul class="progressbar">
        <li class="active">Interview Scheduled</li>
        <li class="active">Selected for next Round</li>
        <li class="int_cancel">Canceled</li>
        <li class="int_cancel">No Show</li>
      </ul>
    </div>
</div>

  </div>

</div>

</div>



<div className="interview_listing">

<div className="interview_box_sec">

<div class="view-schedule col-12">

        
        <div class="interule top-schedule">
          <div class="interf">
          <span class="interposit">Web Designing ( position#33 )</span>
          <span class="interposit"><strong>Client </strong>: Wipro</span>
         
        </div>
        <div class="inters">
          <span class="interstat ">
            <button type="">Scheduled</button>
          </span>
        </div>
          <div class="interl">
          <Edit_interview/>
        </div>
          <div class="intert"><span class="intersch">Scheduled for</span>
          <span class="interdate"> <strong>Apr. 15, 2020 12:00 AM</strong></span>
        </div>
        <div class="interstr">
          <a href="">
            <div class="my-rating" data-rating="">
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
               <i className="far fa-star"></i>
                    </div>
          </a>
            <i class="h_m_ny_re_view fa">(0)</i> <a href="/rating_edit">Write Rewiew</a> 
        </div>

        </div>
  
  <div class="interule">
          <div class="intersn"> 
         <span><strong>Name</strong></span> 
         <span>Tarun Singh</span>  
         </div>
        <div class="interss"> 
         <span><strong>Email</strong></span>
         <span>tarunsingh7102@gmail.com</span>   
         </div>
        <div class="interst"> 
         <span><strong>Phone no.</strong></span> 
         <span>9958362370</span>  
         </div>
               
  <button type="button" class="colptitle top_show_more" data-toggle="collapse" data-target="#demo56">Show More <span class="fa fa-sort-down"></span></button>
  <div id="demo56" class="collapse colpdetail">
                <div class="interss"> 
         <span><strong>Interviewer Name</strong></span>
         <span>Azad Hussain</span>   
         </div>
            <div class="interss"> 
         <span><strong>Interviewer Email</strong></span>
         <span>Azad@techvilla.in</span>   
         </div>

        <div class="interst"> 
         <span><strong>Interviewer Phone no.</strong></span> 
         <span>+1-9090909090</span>  
         </div>
         <div class="interst"> 
         <span><strong>Interview mode</strong></span> 
         <span>F2F</span>  
         </div>
                 <div class="interst"> 
         <span><strong>Interviewer Designation</strong></span> 
         <span>Sr. Hr Manager</span>  
         </div>
          <div class="interst full_width"> 
         <span><strong>Venue of F2F Round</strong></span> 
         <span>We are looking for Candidate like Represent the firm and share point of view around digital technologies (blockchain, intelligent automation, artificial intelligence, internet of things, cloud, etc.) in external conferences, boardroom presentations, industry events, and public videos</span>  
         </div>

 <button type="button" class="colptitle top_less_more hidden" data-toggle="collapse" data-target="#demo56">Show Less <span class="fa fa-sort-up"></span></button>

  </div>
  </div>
<div className="interview_progress_step">
    <div className="all_pro_listing">
        <ul className="top_date_sec">
            <li className="active">Apr. 15, 2020 09:39 PM</li>
            <li class="active">Apr. 15, 2020 09:39 PM</li>
            <li class="int_cancel">Apr. 15, 2020 09:39 PM</li>
            <li class="int_cancel">Apr. 15, 2020 09:39 PM</li>
        </ul>
    <ul class="progressbar">
        <li class="active">Interview Scheduled</li>
        <li class="active">Selected for next Round</li>
        <li class="int_cancel">Canceled</li>
        <li class="int_cancel">No Show</li>
      </ul>
    </div>
</div>

  </div>

</div>

</div>




</div>
<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul>
</nav>
</div>

</div>

</div>

<Footer/>
 
 </>
  );
}

}
export default Interview_listing;