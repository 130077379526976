import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Tags from './../.././Dropdown/Tags'

const $ = window.$;

class LQ_component extends React.Component {


  constructor(props) {
    super(props);
    this.state = {}
  }
  componentWillMount() {

  }
render() {
      return (
    <>
    <div id="long" className="assess long">
    <div className="form-inner-box full_with">					
    <div className="floating-label">      
    <textarea className="floating-input floating-textarea" placeholder=" "></textarea>
    <span>Not More than 500 words</span>
    <label>What would you like to ask?</label>
    </div>
    </div>

  <div className="form-inner-box choose_category">					
    <div className="floating-label">      
        <select name="question_type" className="floating-select" onChange={(e) => { this.onChange(e)} }  id="colorselector">
            <option value=""></option>
            <option value="MCQ">Easy</option>
            <option value="SCQ">Medium</option>
            <option value="LQ">Difficult</option>
        </select>
            <label>Level</label>
    </div>
  </div>
        <div className="form-inner-box option_section  full_with">			
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>marks</label>
        </div>
        </div>
<Tags/>
    <form action="">

    <div className="form-submit-button">
    <button className="cancel_btn" type="submit"> Cancel </button>
    <button className="save_btn" type="submit"> Save </button>
    </div>



    </form>

    </div>

    </>
  );
}

}
export default LQ_component;