import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';

import { get } from './../../services/Apicall';

const $ = window.$;

class Footer extends React.Component {


render() {
  return (
    <>


<footer>
	<div className="outer-footer container-fuild">
		<div className="footer-company">
			<p>© 2020, VeriiPro Technology, LLC <a href=""></a></p>
		</div>
	</div>
</footer>


</>
);
}

}


const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default Footer;
