import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';
const $ = window.$;
 
class Master_emailer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [
  {
    "id": 1,
    "name": "${Client.Logo}",
    },

  {
    "id": 2,
    "name": "${Clients.Primary Contact}",
  },
  {
    "id": 3,
    "name": "${Candidate.Name}",
  },
  {
    "id": 4,
    "name": "${Candidate.Name}",
  }
],
      value: ''
    };
  }
  componentWillMount() {
    // fetch('https://jsonplaceholder.typicode.com/users')
    //   .then(response => response.json())
    //   .then(json => this.setState({ data: json }));
  }
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

	componentDidMount(){

}




render() {
    const { data, value } = this.state;
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  
  <div className="homepage-outer">

  <div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>


<div className="homepage-inner container">

<div className="master_email_form">
    <div className="master_email">
        <h1 className="main_title">
            Email Template
        </h1>
<div className="main_email_left">
            <div className="form-inner-box full_with">					
            <div className="floating-label">      
            <input className="floating-input" type="text" placeholder=" "/>
            <label>Template Name</label>
             <span>Max 150 Character</span>
            </div>
            </div>

            <div className="form-inner-box full_with">					
            <div className="floating-label">      
            <select className="floating-select" onclick="this.setAttribute('value', this.value);" value="">
            <option value=""></option>
            <option value="1">Job Post</option>
            <option value="2">Job Sent</option>
            <option value="3">Job Edit</option>
            </select>

            <label>Purpose</label>
            </div>
            </div>


                <div className="form-inner-box full_with">					
                <div className="floating-label">      
                <input className="floating-input" type="text" placeholder=" "/>

                <label>Subject line  </label>
                <span>Max 150 Character</span>
                </div>
                </div>
<div className="full_view">
<span>Full Screen mode</span>
</div>

<div className="master_email_editor">

<textarea className="floating-input floating-textarea" placeholder=" "></textarea>
</div>
</div>

<div className="email_right_side append_attri">
<h3>Append Attributes</h3>
<div className="append_attri">
<input type="text" value={value} onChange={this.handleChange} />
        <SearchResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                <div>
                 <span>{el.name}</span>
                </div>
              ))}
            </div>
          )}
        />
</div>
</div>

                <div className="master_filled_table">

                    <h2 className="table_heading_master">List of Email Templates</h2>

<table  className="table-bordered table">
    <tr>
        <th>Template Name </th>
        <th>Purpose</th>
        <th>Subject Line</th>
        <th>Action</th>
    </tr>
<tr>
    <td>Template Name 1</td>
    <td>New Job Create</td>
    <td>New Job Create</td>
    <td><Link to="">EDIT </Link>| <Link to="">PREVIEW</Link></td>
</tr>
<tr>
    <td>Template Name 1</td>
    <td>Job Created</td>
    <td>Job Created</td>
    <td><Link to="">EDIT </Link>| <Link to="">PREVIEW</Link></td>
</tr>
<tr>
    <td>Template Name 1</td>
    <td>Job Posted</td>
    <td>Job Posted</td>
    <td><Link to="">EDIT </Link>| <Link to="">PREVIEW</Link></td>
</tr>
<tr>
    <td>Template Name 1</td>
    <td>Job Edited</td>
    <td>Job Edited</td>
    <td><Link to="">EDIT </Link>| <Link to="">PREVIEW</Link></td>
</tr>
<tr>
    <td>Template Name 1</td>
    <td>Job Send</td>
    <td>Job Send</td>
    <td><Link to="">EDIT </Link>| <Link to="">PREVIEW</Link></td>
</tr>

</table>
</div>


    </div>
</div>

</div>
</div>
        <Footer/>
  </div>
    </>
  );
}

}
export default Master_emailer;