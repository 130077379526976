export const _SITE_CONTACT_PHONE = '+1-732-318-5666';
export const _SITE_CONTACT_EMAIL = 'info@veriipro.com';
export const _SITE_CONTACT_ADDRESS_LINE1 = '30941 Agoura Road';
export const _SITE_CONTACT_ADDRESS_LINE2 = 'Suite 128';
export const _SITE_CONTACT_ADDRESS_CITY = 'Westlake Village';
export const _SITE_CONTACT_ADDRESS_STATE = 'CA';
export const _SITE_CONTACT_ADDRESS_ZIP = '91361';
export const _SITE_CONTACT_ADDRESS_COUNTRY = 'US';
export const _SITE_CONTACT_ADDRESS_FULL = '30941 Agoura Road, Suite 128 Westlake Village, CA 91361';
export const _SITE_COMPANY_NAME = 'Veriipro Technologies, LLC.';
export const _SITE_SOCIAL_FACEBOOK = 'http://fb.me/veriipro';
export const _SITE_SOCIAL_LINKEDIN = 'https://www.linkedin.com/company/veriipro';
export const _SITE_SOCIAL_TWITTER = 'https://twitter.com/veriipro';
export const _SITE_SOCIAL_INSTAGRAM = 'https://www.instagram.com/veriipro/';
export const _SITE_JOB_URL = 'https://www.veriipro.com/';
export const _SITE_JOB_EMPLOYER_URL = 'https://www.veriipro.com/employer/';

export const _SITE_APP_STORE_URL = 'https://apps.apple.com/us/app/veriipro-job-search/id1479504997';
export const _SITE_PLAY_STORE_URL = 'https://www.veriipro.com/employer/';

export const _SITE_RECAPTCHA_SITE_KEY = '6LfSwJUUAAAAADcsebwzTBURqwA8tcHL7GuuPkId';
export const _SITE_LEARNING_URL = 'https://learning.veriipro.com/';


export const _MASTER_EDUCATION_LEVEL = ['Secondary Education or High School','Vocational Qualification','Undergraduate','Certificate / Diploma','Bachelor\'s Degree','Master\'s Degree','Doctorate or Professional Degree'];
export const _MASTER_COURSE_TYPE = ['Full Time','Part Time','Online', 'Distance or Correspondence','Executive Program','Certification'];