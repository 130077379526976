/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const Mapping = makeStyles((theme) => ({
  root: {
    width: "100%",
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function LimitTags() {
  const classes = Mapping();

  return (
      <div className="form-inner-box full_with">					
<div className="floating-label hsmd_mapp"> 
    <div className={classes.root}>
<Autocomplete
  id="combo-box-demo"
  options={map_option}
  getOptionLabel={(option) => option.title}
  style={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Select" variant="outlined" />}
/>
    </div>
    </div>
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const map_option = [
  
  { title: 'Open'},
  { title: 'Covered'},
  { title: 'Hold'},
  { title: 'Closed'},
  { title: 'Inactive'},
  ];
