import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Note_type = (props) => {
   const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }


  return (


      
    <>
<span onClick={toggle}  className="add_mew_note">Add New Note Type</span>
      <Modal isOpen={modal} toggle={toggle} className={className}>
       <ModalHeader toggle={toggle}>Add New Note Type </ModalHeader>
        <ModalBody>
        <div className="row">
<div className="box_attachment note_type_pop_up">

<div className="form-container">

<div className="inner-form-container">
<div className="all-form-width interview">

<div className="signup-form-homepage">

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<p>Add New Call Type</p>
</div>
</div>

<div className="note_checkbox">
<input type="checkbox" />
<span>enable</span>

</div>

</div>
</div>
</div>
</div>


	</div>
</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Note_type;