/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

class Industry extends React.Component {
constructor(){
super();
 this.state = {
      plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
      objectArray: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" },
        { key: "Option 3", cat: "Group 1" },
        { key: "Option 4", cat: "Group 2" },
        { key: "Option 5", cat: "Group 2" },
        { key: "Option 6", cat: "Group 2" },
        { key: "Option 7", cat: "Group 2" }
      ],
      selectedValues: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" }
      ]
    };
    this.style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px"
      },
      multiselectContainer: {
        color: "red"
      }
    };
    this.addItem = this.addItem.bind(this);
  }
  addItem() {
    this.selectedValues.push({ key: "Option 3", cat: "Group 1" });
  }

  render() {
    const { plainArray, objectArray, selectedValues } = this.state;
return (
<>
    <Autocomplete
      id="combo-box-demo"
      options={Indusy}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Industry" variant="outlined" />}
    />
</>
);
}

}

export default Industry;
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const Indusy = [
  
  { title: 'HTML'},
  { title: 'CSS'},
  { title: 'Bootstrap'},
  { title: 'UI'},
  { title: 'UX'},
  { title: 'JavaScript'},
  { title: 'AnjularJS'},
  { title: 'ReactJS'},
  { title: 'VueJ'},
  { title: 'Java'},
  { title: 'PHP'},
  { title: 'NodeJS'},
  { title: 'ExpressJS'},
  { title: 'Python'},
  { title: 'R'},
  { title: 'Golang'},
  { title: 'Django'},
  { title: 'Spring'},
  { title: 'Hibernate'},
  { title: 'Ruby on Rails'},
  { title: '.NE'},
  { title: 'System Administration'},
  { title: 'System Support'},
  { title: 'Troubleshooting'},
  { title: 'Network Security'},
  { title: 'Cyber Security'},
  { title: 'Information Security'},
  { title: 'Firewall'},
  { title: 'Infrastructure Management'},
  { title: 'Android'},
  { title: 'iOS'},
  { title: 'React Native'},
  { title: 'OpenGL'},
  { title: 'Xamarin'},
  { title: 'Ionic'},
  { title: 'Phonega'},
  { title: 'Search Engine Optimization'},
  { title: 'Search Engine Marketing'},
  { title: 'Social Media Marketing'},
  { title: 'Brand Promotion'},
  { title: 'Affiliate Marketing'},
  { title: 'Digital Marketing'},
  { title: 'Marketing Strategy'},
  { title: 'Product Marketing'},
  { title: 'Marketing Research and Analysis'},
  { title: 'Growth Hacking'},
  { title: 'Content Writing'},
  { title: 'Content Marketin'},
  { title: 'AWS'},
  { title: 'Azure'},
  { title: 'Google Cloud'},
  { title: 'Cloud Architecture'},
  { title: 'Jenkins'},
  { title: 'Kubernetes'},
  { title: 'BitBucket'},
  { title: 'GitHub'},
  { title: 'Docker'},
  { title: 'Puppet'},
  { title: 'Chef'},
  { title: 'Sentry'},
  { title: 'Ansible'},
  { title: 'Vagrant'},
  { title: 'Nagios'},
  { title: 'Phantom'},
  { title: 'Slac'},
  { title: 'Artificial Intelligence'},
  { title: 'Machine Learning'},
  { title: 'Big Data'},
  { title: 'Data Analytics'},
  { title: 'NLP'},
  { title: 'Data Modeling'},
  { title: 'Data Mining'},
  { title: 'Data Visualizatio'},
  { title: 'Photoshop'},
  { title: 'Illustrator'},
  { title: 'Adobe After Effects'},
  { title: 'Mockplus'},
  { title: 'Wireframe'},
  { title: 'Prototype'},
  { title: 'Design Sense'},
  { title: 'UX'},
  { title: 'UI'},
  { title: 'Responsive Design'},
  { title: 'Adobe Animate'},
  { title: 'HTML'},
  { title: 'CSS'},
  { title: 'Bootstrap'},
  { title: 'JavaScript'},
  { title: 'Photoshop'},
  { title: 'Illustrator'},
  { title: 'InDesign'},
  { title: 'UI'},
  { title: 'UX'},
  { title: 'HTML'},
  { title: 'Color Theory'},
  { title: 'Typography'},
  { title: 'Composition'},
  { title: 'User Modelin'},
  { title: 'Data Science'},
  { title: 'IoT'},
  { title: 'Blockchain'},
  { title: 'Virtual Reality'},
  { title: 'Augmented Reality'},
  { title: 'Cryptocurrency'},
  { title: 'Analytical Reasoning'},
  { title: 'Digital Marketing'},
  { title: 'Scientific Computin'},
  { title: 'SAP Functional Consulting'},
  { title: 'Oracle Functional Consulting'},
  { title: 'Oracle Apps'},
  { title: 'SAP ABAP'},
  { title: 'SAP FICO'},
  { title: 'Informatica'},
  { title: 'Salesforce'},
  { title: 'Data Analytics'},
  { title: 'Data Visualisation'},
  { title: 'Product Consultant'},
  { title: 'UI/UX Consultan'},
  { title: 'Project Management'},
  { title: 'Program Management'},
  { title: 'Business Analysis'},
  { title: 'PMP'},
  { title: 'Scrum'},
  { title: 'Agile'},
  { title: 'PRINCE'},
  { title: 'Product Strategy'},
  { title: 'Product Roadmap'},
  { title: 'Wireframe'},
  { title: 'User Story'},
  { title: 'Storyboard'},
  { title: 'UX Strategy'},
  { title: 'Workflow'},
  { title: 'Jira'},
  { title: 'Version Controlling'},
  { title: 'Release Managemen'},
  { title: 'UI'},
  { title: 'UX'},
  { title: 'Product Design'},
  { title: 'Interaction Design'},
  { title: 'Wireframe'},
  { title: 'Sketch'},
  { title: 'InDesign'},
  { title: 'Photoshop'},
  { title: 'CorelDraw'},
  { title: 'Illustrator'},
  { title: 'Infographic'},
  { title: 'Semiconductor'},
  { title: 'VLSI'},
  { title: 'VHDL'},
  { title: 'MATLAB'},
  { title: 'Board Design'},
  { title: 'System Verilog'},
  { title: 'Physical Design'},
  { title: 'RTL'},
  { title: 'ASI'},
  { title: 'Application Testing'},
  { title: 'Mobile Testing'},
  { title: 'Functional Testing'},
  { title: 'Performance Testing'},
  { title: 'Security Testing'},
  { title: 'Automation Testing'},
  { title: 'Selenium'},
  { title: 'Load Testing'},
  { title: 'White Box Testing'},
  { title: 'JUnit'},
  { title: 'NUnit'},
  { title: 'ACCELQ'},
  { title: 'API Testing'},
  { title: 'Web Testing'},
  { title: 'Ranorex Studio'},
  { title: 'TestArchitect'},
  { title: 'TestCraft'},
  { title: 'HeadSpin'},
  { title: 'Subject7'},
  { title: 'ZeuZ'},
  { title: 'LambdaTest'},
  { title: 'Parasoft Selenic'},
  { title: 'Squish'},
  { title: 'GUI Testing'},
  { title: 'Regression Testing'},
  { title: 'Zephyr'},
  { title: 'TestCafe Studio'},
  { title: 'mabl'},
  { title: 'HP UFT'},
  { title: 'Unified Functional Testing'},
  { title: 'Watir'},
  { title: 'IBM Rational Functional Tester'},
  { title: 'Telerik Test Studio'},
  { title: 'Silk Test'},
  { title: 'Cucumber'},
  { title: 'WorkSoft'},
  { title: 'Katalon Studio'},
  { title: 'TestComplete'},
  { title: 'Jira'},

];
