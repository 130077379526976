import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Switch from "react-switch";
import ReactTags from 'react-tag-autocomplete'


const $ = window.$;

class Manage_user extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {file: '',imagePreviewUrl: ''};
     this.state = {
      tags: [
        { id: 1, name: "Offer" },
        { id: 2, name: "Active" }
      ],
      suggestions: [
        { id: 3, name: "Inactive" },
        { id: 4, name: "Interview" },
        { id: 5, name: "Assessment" },
        { id: 6, name: "Email" }
      ]
    }
  }
  handleDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }
 
  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
  }

_handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleChange(checked) {
    this.setState({ checked });
  }




	componentDidMount(){

}

render() {

 let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} />);
    } else {
      $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }


  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing myprofile_form">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">
<h1 className="main_title">
           User Profile
        </h1>
<p className="form_heading_lebel">User Details</p>



<div className="form-inner-box full_width">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>First Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Last Name*</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Registered Email*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" " disabled />

<label>Phone*</label>
</div>
</div>	



<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Communications</option>
<option value="2">Technology</option>
<option value="3">Government/Military</option>
<option value="4">Manufacturing</option>
<option value="5">Financial Services</option>
</select>

<label>Title*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="Date" placeholder=" "/>

<label>DOB*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

<label>Joining Date*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">EST</option>
<option value="2">PST</option>
<option value="3">CST</option>
</select>

<label>Report To*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

<label>Date Of Leaving*</label>
</div>
</div>


<p className="form_heading_lebel">Configurations </p>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Email Forwarding To </label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Alabama</option>
<option value="2">Hawaii</option>
<option value="3">Massachusetts</option>
<option value="4">Mississippi</option>
<option value="5">New Jersey</option>
<option value="5">South Carolina</option>
<option value="5">Texas</option>
</select>
<label>User Type*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Alabama</option>
<option value="2">Hawaii</option>
<option value="3">Massachusetts</option>
<option value="4">Mississippi</option>
<option value="5">New Jersey</option>
<option value="5">South Carolina</option>
<option value="5">Texas</option>
</select>
<label>Security Group*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Verification Code*</label>
</div>
</div>

<div className="form-inner-box full_with">	

      <div className="floating-label">  

        <textarea className="floating-input floating-textarea" placeholder=" "></textarea>

        <label>Email Signature*</label>

      </div>

    </div>


<p className="form_heading_lebel">Configurations </p>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">User Profile Active</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#ccc"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch1"
    id="material-switch10"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Deny User Login</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch1"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">One Time Password is Mandagory for Login </div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch2"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Can Send email to Client</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch3"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Can Send email to Candidate </div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch4"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Can Send email to Account Manager </div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch5"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Can Send email to Vendor </div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch6"
  />
</div>

</div>
</div>

<div className="manage_user_radio">
<p>Choose Data Access Privileges</p>
<div className="user_radio">
<input type="radio" className="mange_user_radio_btn"/><span>My Data  </span>
<input type="radio" className="mange_user_radio_btn"/><span>My Team Data  </span>
<input type="radio" className="mange_user_radio_btn"/><span>All Data  </span>
</div>
</div>

<div className="manage_user_radio">
<p>Choose Data Access Privileges</p>
<div className="chcklist">
<input type="checkbox" className="mange_user_radio_btn"/><span>Dashboard</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Jobs</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Candidates</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Interview</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Client</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Contact</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Vendor</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Admin</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Reports</span>
<input type="checkbox" className="mange_user_radio_btn"/><span>Assessment</span>
</div>
</div>

<div className="mange_check">
<div className="check_title">Choose Client Accessibility</div>
<div className="chcklist">
<input type="checkbox" className="mange_user_radio_btn"/><span>Provide Access to All Clients</span>
</div>
</div>


<div className="manage_multiselect">

  <div className="user_acc_search">
      <input type="text" className="user_acc_search_input" placeholder="Search By name"/>
      <span> <i className="fa fa-search"></i> </span>
  </div>

<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Azad</span>
</div>
 <div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Rakesh</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Sanjeev</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Suman</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Avinash</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Nida</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>User</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Manager</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Onwer</span>
</div>
<div className="single_manager_select">
<input type="checkbox" className="mange_user_radio_btn"/><span>Client</span>
</div>

</div>

<form action="">

<div className="form-submit-button mt-50">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>

</div>

</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Manage_user;