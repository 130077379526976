import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Tag_input from './../Pop_up/Tag_input'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";


const Share_candidate = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }


  return (

    <>
    <button onClick={toggle}  className="save_ass_btn" type="button"> Save & Share </button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Share Chandidate </ModalHeader>
<ModalBody>
    <div className="row">

        <div className="share_job">
            <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                <TabList className="filter_type_menu">
                    <Tab tabFor="vertical-tab-one"> 
                        <div className="job_upper_menu">
                            <span>Recuiters (0/0)</span>
                        </div>
                    </Tab>
                    <Tab tabFor="vertical-tab-two">
                        <div className="job_upper_menu">
                            <span>Sources (0/0)</span>
                        </div>

                    </Tab>
                    
                </TabList>


                <TabPanel tabId="vertical-tab-one">
                <div className="share_job_pop">
                    <div className="share_pop">
                            {/* <h3>User</h3> */}
                       <div className="form-inner-box full_with">					
                            <div className="floating-label">      
                                <input className="floating-input" type="text" placeholder=" "/>
                                <label>Search Recuiters</label>
                            </div>
                        </div>
                        <div className="share_check">
                            <input type="checkbox"/>
                            <span className="checkbox_select"> Select All </span>
                        </div>
                        <table>

                            <tr>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Azad Hussain </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Nida Praveen </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Rakesh yadav </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Nida Praveen </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Rakesh yadav </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Nida Praveen </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Azad Hussain </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Nida Praveen </span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    {/* <div className="share_pop">
                        <h3>Document</h3>
                        <div className="share_check">
                            <input type="checkbox"/>
                            <span className="checkbox_select"> Select All </span>
                        </div>
                        <table>

                            <tr>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Document 1 </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Document 2 </span>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div> */}

                </div>

                </TabPanel>
                <TabPanel tabId="vertical-tab-two">
                <div className="share_job_pop">
                    <div className="share_pop">
                            {/* <h3>User</h3> */}
                        <div className="form-inner-box full_with">					
                            <div className="floating-label">      
                                <input className="floating-input" type="text" placeholder=" "/>

                                <label>Search Sources</label>
                            </div>
                        </div>
                        <div className="share_check">
                            <input type="checkbox"/>
                            <span className="checkbox_select"> Select All </span>
                        </div>
                        <table>

                            <tr>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Rakesh yadav </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Nida Praveen </span>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>

                    {/* <div className="share_pop">
                        <h3>Document</h3>
                        <div className="share_check">
                            <input type="checkbox"/>
                            <span className="checkbox_select"> Select All </span>
                        </div>
                        <table>

                            <tr>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Document 1 </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="share_check">
                                        <input type="checkbox"/>
                                        <span className="checkbox_select"> Document 2 </span>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div> */}

                </div>

                </TabPanel>
            </Tabs>
        </div>

    </div>

</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Share</Button>{' '}
        </ModalFooter>
      </Modal>

        </>
  );
}

export default Share_candidate;