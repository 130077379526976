import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import Header_signup from './../ATS_common/Header_signup'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Signin extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_signup/>



  <div className="form-container">
	
	<div className="inner-form-container container">
		<div className="all-form-width">
		<div className="signup-form-homepage mt-50">
			<img src="./images/logo.png" alt=""/>
			<p>Sign In</p>
			<form action="/home">

				<div className="form-inner-box">
<div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>Email
					</label>
					</div>
				</div>
					
				<div className="form-inner-box">
<div className="floating-label">      
					<input className="floating-input" type="Password" placeholder=" "/>

					<label>Password
					</label>
					</div>
				</div>


				<div className="iamnotrobot">
				<div className="capatcha">
					 <ReCAPTCHA ref={e => (this.captcha = e)} sitekey={Constant._SITE_RECAPTCHA_SITE_KEY} onChange={(value) => { this.setState({ 'g-recaptcha-response': value }); }} />
				</div>
				</div>


				<div className="form-submit-button">
					<button type="submit"> Login </button>
				</div>
                                <div className="col-lg-12 login-btm forgot_pass_word">
                                    <Link to="/Forgot" className="">Forgot Password ?</Link>
                                </div>  
                                <div className="col-lg-12 ntvp-btm sign_up_link">New to VeriiPro ATS ?
                                    <Link to="/" className=""  > Sign up now!</Link>
                                </div>

				
			</form>

		</div>

	</div>
	
</div>

</div>


  <Footer/>
  </div>
    </>
  );
}

}
export default Signin;