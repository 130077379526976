import React, { Component } from 'react';
import {Route } from "react-router-dom";



import Signin from './../pages/ATS_login/Signin';
import Homepage from './../pages/ATS/Homepage';

export class PrivateRoute extends Component {

    render() {
      if (localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        // return (<Redirect to={{ pathname: '/login', state: { from: props.location } }}  />);
        return (<Route exact path={this.props.path} component={Signin} />);
    }
  }
  
 export  class LoginRoute extends Component {
  
    render() {
      if (!localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        return (<Route exact path={this.props.path} component={Homepage} />);
    }
}