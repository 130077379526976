import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Skills from './.././Dropdown/Skills'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const $ = window.$;

class Candiadte_form extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}

render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
            <h1 className="main_title">
           Add New Candidate
        </h1>
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
                <button className="save_ass_btn" type="submit"> Save and Associate </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-20">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">

<p className="form_heading_lebel">Basic Details</p>


<span className="alert_msg">Note: Email and Mobile Should be unique and if found duplicate, you will be prompted to open the existing profile of the candidate.</span>
<div className="form-inner-box full_width hidden">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Candidate ID*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Email*</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Mobile*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" " disabled />

<label>Profile Owner*</label>
</div>
</div>	


<div className="form-inner-box with_extra_field">	

<div className="floating-label one_fourth _first_left">      
<select className="floating-select">
<option value="0">Mr</option>
<option value="1">Miss</option>
<option value="2">Mrs</option>
</select>
</div>

<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>

<label>First Name*</label>
</div>

</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Last Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Secondary Email
</label>
</div>
</div>			<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Phone*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Public</option>
<option value="2">Private</option>
</select>

<label>Profile Type*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Dice</option>
<option value="2">Indeed</option>
<option value="3">Monster</option>
</select>

<label>Profile Source*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Skype*
</label>
</div>
</div>			<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>LinkedIn*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Facebook*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Twitter*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
  <optgroup label="Screening">
    <option value="volvo">new</option>
    <option value="saab">Screening passed</option>
    <option value="saab">Screening failed</option>
    <option value="saab">Not interested</option>
    <option value="saab">Not Contacted</option>
    <option value="saab">Internal Submission</option>
    <option value="saab">Internal Screen Rejected</option>
  </optgroup>
  <optgroup label="Client Submission">
    <option value="mercedes">Submiited to client</option>
    <option value="audi">Client Screen Rejected</option>
    <option value="audi">Client Screen Approved</option>
  </optgroup>
    <optgroup label="Client Interview">
    <option value="mercedes">Schedule Interview</option>
    <option value="mercedes">Interview Scheduled </option>
    <option value="audi">Interview in Progress</option>
    <option value="audi">on Hold</option>
    <option value="audi">Interview Selected</option>
    <option value="audi">Interview Rejected</option>
  </optgroup>
    <optgroup label="Offer">
    <option value="mercedes">Ready to Offer</option>
    <option value="mercedes">Offered </option>
    <option value="audi">Offer Accepted</option>
    <option value="audi">Offer Declined</option>
    <option value="audi">Offer Withdrawn</option>
     </optgroup>
    <optgroup label="Hired">
    <option value="mercedes">Hired</option>
    <option value="mercedes">Joined </option>
    <option value="audi">No Show</option>
  </optgroup>
    <optgroup label="Client Interview">
    <option value="mercedes">Other</option>
  </optgroup>
  </select>

<label>Candidate Status*
</label>
</div>
</div>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Email Opt Out</label>
<input type="checkbox" name=""/>
</div>
</div>


<p className="form_heading_lebel">Professional Details</p>




<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
<option value="5">5+</option>
</select>

<label>Relevant Experience (Years)*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
<option value="5">5+</option>
</select>

<label>Total Experience (Years)*</label>
</div>
</div>

<div className="form-inner-box">	
<div className="floating-label"> 				
    <Autocomplete
      id="combo-box-demo"
      options={job_title}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Current Job Title" variant="outlined" />}
    />
    </div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Current Employer*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Notice Period(Days)*</label>
</div>
</div>

<div className="form-inner-box with_extra_field">					
<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Current Salary*</label>
</div>

<div className="floating-label one_fourth">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Per</label>
</div>

</div>

<div className="form-inner-box with_extra_field">					
<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Expected Salary*</label>
</div>

<div className="floating-label one_fourth">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Per</label>
</div>

</div>



<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">1099</option>
<option value="2">C2C</option>
<option value="3">W2</option>
<option value="4">Payroll</option>
<option value="5">Permanent Hire</option>
<option value="5">Subcon Company</option>
</select>

<label>Contract Type*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">E3</option>
<option value="2">EAD GC</option>
<option value="3">Green Card</option>
<option value="4">H1B</option>
<option value="5">L2 EAD</option>
<option value="5">OPT EAD</option>
<option value="5">TN</option>
<option value="5">US Citizen</option>
</select>

<label>Work Auth/Visa*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Any</option>
<option value="2">Contract</option>
<option value="3">Freelance</option>
<option value="4">Full time</option>
<option value="5">Part time</option>
<option value="5">Permanent</option>
<option value="5">Seasonal</option>
<option value="5">Temporary</option>
<option value="5">Training</option>
<option value="5">Volunteer</option>
</select>

<label>Prefered Job Type*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">MBA/PGDM</option>
<option value="2">CA</option>
<option value="3">ICAI</option>
<option value="4">LLB/LLM</option>
<option value="5">B Tech / BE</option>
<option value="5">BArch</option>
<option value="5">MBBS</option>
<option value="5">BSc</option>
<option value="5">BA</option>
<option value="5">BCom</option>
<option value="5">BBA</option>
<option value="5">BCA</option>
<option value="5">PhD</option>
</select>

<label>Highesh Qualification*</label>
</div>
</div>

<Skills/>


<p className="form_heading_lebel">Address Details</p>

<p className="form_heading_lebel_sec">Current location</p>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Country*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>State/Province*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>City*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Zip/Postal Code*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Street*</label>
</div>
</div>




<p className="form_heading_lebel_sec">Prefered Location</p>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>State/Province*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>City*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Zip/Postal Code*</label>
</div>
</div>

<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Ready to Relocate</label>
<input type="checkbox" name=""/>
</div>
</div>





<p className="form_heading_lebel">Educational Details <span className="add_new_item" id="add_item">
	<i className="fa fa-plus"></i>Add Education Detail</span></p>

  <div className="section_hideshow hidden" id="educational">

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Secondary Education or High School</option>
<option value="2">Vocational Qualification</option>
<option value="3">Undergraduate</option>
<option value="4">Certificate / Diploma</option>
<option value="5">Bachelor's Degree</option>
<option value="5">Master's Degree</option>
<option value="5">Doctorate or Professional Degree</option>
</select>

<label>Qualification Level*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={Degree}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Degree" variant="outlined" />}
    />
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Full Time</option>
<option value="2">Part Time</option>
<option value="3">Online</option>
<option value="4">Distance or Correspondence</option>
<option value="5">Executive Program</option>
<option value="5">Certification</option>
</select>

<label>Course Type*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Indian Institute of Technology</option>
<option value="2">Delhi Institute of Technology</option>
<option value="3">Mumbai Institute of Technology</option>
</select>

<label>Institude/School*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Batch Start*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Batch End*</label>
</div>
</div>

<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label> Currently Pursuring </label>
<input type="checkbox" name=""/>
</div>
</div>
<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>



</form>
</div>
<div className=" show content show_certificate">

<table className="table-bordered table">
  <tr>
    <th>Qualification Level</th>
    <th>Degree</th>
    <th>Course Type</th>
    <th>	Batch (Period)</th>
  </tr>
  <tr>
    <td>Post Graduation<br/> <span>Indian Institute of Technology</span></td>
    <td>M.Tech</td>
    <td><span>Full Time</span></td>
    <td><span>mm-yyyy to mm-yyyy (Pursuing)</span></td>
  </tr>
  <tr>
    <td>Graduate<br/><span>Delhi College of Engineering</span></td>
    <td>B.Tech</td>
    <td><span>Full Time</span></td>
    <td><span>mm-yyyy to mm-yyyy</span></td>
  </tr>
</table>

</div>


<p className="form_heading_lebel">Certification <span className="add_new_item" id="add_item_1">
	<i className="fa fa-plus"></i>Add Certificate</span></p>



<div className="section_hideshow hidden" id="certification">
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Certification Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Issuing Body*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Licence Number*
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" placeholder=" "/>

<label>Completion Period</label>
</div>
</div>	

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Valid Till</label>
</div>
</div>	

<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>No Validity</label>
<input type="checkbox" name=""/>
</div>
</div>

<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>



</form>

</div>

<div className="show_content show_certificate">

<table className="table-bordered table">
  <tr>
    <th>    Certification Name    </th>
    <th>   Licence Number    </th>
    <th>    Completion Period   </th>
    <th>   Valid Till Month   </th>
  </tr>
  <tr>
    <td>Solution Architect<br/> <span>Microsoft Certified</span></td>
    <td>MS01234562345</td>
    <td><span>june,2018</span></td>
    <td><span>apr.,2030</span></td>
  </tr>
  <tr>
    <td>Digital Marketing Professional<br/><span>Google Certified</span></td>
    <td>MSND234562345</td>
    <td><span>Feb,2109</span></td>
    <td><span>No Validity</span></td>
  </tr>
</table>

</div>



<p className="form_heading_lebel">Work Experience <span className="add_new_item" id="add_item_2">
	<i className="fa fa-plus"></i>Add Experience</span></p>
<div className="section_hideshow hidden" id="experirnce">
<div className="form-inner-box">					
<div className="floating-label"> 				
    <Autocomplete
      id="combo-box-demo"
      options={job_title}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label=" Job Title" variant="outlined" />}
    />
    </div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Comapny*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">No</option>
<option value="2">Yes</option>
</select>

<label>Is Current Employer*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Work Start*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Work End*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Any</option>
<option value="2">Contract</option>
<option value="3">Freelance</option>
<option value="4">Full time</option>
<option value="5">Part time</option>
<option value="5">Permanent</option>
<option value="5">Seasonal</option>
<option value="5">Temporary</option>
<option value="5">Training</option>
<option value="5">Volunteer</option>
</select>

<label>Job Type*</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Roles and Responsibilities*</label>
</div>
</div>
<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>



</form>
</div>
<div className="show_content show_experience">
<table className="table-bordered table">
  <tr>
    <td>
    <div className="form-inner-box">
  <label>Job Title</label>
  <div className="form_inner_conten">Developer</div>
</div>
    </td>
    <td>
    <div className="form-inner-box">
  <label>Company</label>
  <div className="form_inner_conten">VeriiPro</div>

</div>
    </td>
    <td>
    <div className="form-inner-box">
  <label>Is Current Employer</label>
  <div className="form_inner_conten">NO</div>

</div>
    </td>
  </tr>
<tr>
  <td>
    <div className="form-inner-box">
  <label>Work Start</label>
  <div className="form_inner_conten">Sep, 2019</div>

</div>
</td>
  <td>
  <div className="form-inner-box">
  <label>Work End</label>
  <div className="form_inner_conten">Sep, 2019</div>

</div>
  </td>
  <td>
    
<div className="form-inner-box">
  <label>Job Type</label>
  <div className="form_inner_conten">Permanent</div>

</div>
  </td>
</tr>
<tr>
  <td colSpan="3">
  <div className="form-inner-box full_width">
  <label>Roles and Responsibilities</label>
  <div className="form_inner_conten full_width">
    Design and create new Website.design Emailer.
  </div>
</div>
  </td>
</tr>



</table>
</div>




<p className="form_heading_lebel">Project Details <span className="add_new_item" id="add_item_3">
	<i className="fa fa-plus"></i>Add Project</span></p>

<div className="section_hideshow hidden" id="project">
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Project Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Client*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Project Start*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <input className="floating-input" type="date" onClick={this.type='date'}/>

<label>Project End*</label>
</div>
</div>

<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label> Currently on-going </label>
<input type="checkbox" name=""/>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Your Role*</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Tools & Technologies Used <span>(Max 1000 Char)</span>*</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Your Responsibilities <span>(Max 2000 Char)</span>*</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Project Detail <span>(Max 2000 Char)</span>*</label>
</div>
</div>
<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>



</form>
</div>


<div className="show_content show_experience">
<table className="table-bordered table">
  <tr>
    <td>
    <div className="form-inner-box">
  <label>Project Name</label>
  <div className="form_inner_conten">Blog Website</div>
</div>
    </td>
    <td>
    <div className="form-inner-box">
  <label>Client</label>
  <div className="form_inner_conten">VeriiPro</div>

</div>
    </td>
    <td>
    <div className="form-inner-box">
  <label>Project Start</label>
  <div className="form_inner_conten">June,2020</div>

</div>
    </td>
  </tr>
<tr>
  <td>
    <div className="form-inner-box">
  <label>Project End</label>
  <div className="form_inner_conten">July, 2020</div>

</div>
</td>
  <td>
  <div className="form-inner-box">
  <label> Currently on-going </label>
  <div className="form_inner_conten">No</div>

</div>
  </td>
  <td>
    
<div className="form-inner-box">
  <label>Your Role</label>
  <div className="form_inner_conten">Designer</div>

</div>
  </td>
</tr>
<tr>
  <td colSpan="3">
  <div className="form-inner-box full_width">
  <label>Tools and Technology Used </label>
  <div className="form_inner_conten full_width">
    Design and create new Website.design Emailer.
  </div>
</div>
  </td>
</tr>
<tr>
  <td colSpan="3">
  <div className="form-inner-box full_width">
  <label>Your Responsibilty </label>
  <div className="form_inner_conten full_width">
    Design and create new Website.design Emailer.
  </div>
</div>
  </td>
</tr>
<tr>
  <td colSpan="3">
  <div className="form-inner-box full_width">
  <label>Project Detail </label>
  <div className="form_inner_conten full_width">
    Design and create new Website.design Emailer.
  </div>
</div>
  </td>
</tr>



</table>
</div>

<p className="form_heading_lebel">Reference <span className="add_new_item" id="add_item_4">
	<i className="fa fa-plus"></i>Add Reference</span></p>
<div className="section_hideshow hidden" id="Reference">
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Reference Name*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Relation*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>


<label>Job Title*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>


<label>Company*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">
	<input className="floating-input" type="text" placeholder=" "/>
<label> Email </label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Mobile Number*</label>
</div>
</div>
<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>

</form>
</div>


<table className="table-bordered table">
  <tr>
    <th>Reference Name</th>
    <th>Relation </th>
    <th>Job Title</th>
    <th>Comapny</th>
  </tr>
  <tr>
    <td>Azad Hussain<br/> <span>azad@techvilla.in</span><span>+1-6909098909</span></td>
    <td>Friend</td>
    <td><span>Sr. Web Developer</span></td>
    <td><span>Techvilla Solutions</span></td>
  </tr>
  <tr>
    <td>Sanjeev<br/><span>sanjeev@techvilla.in</span><span>+1-6909098909</span></td>
    <td>colleague</td>
    <td><span>Poduct Manager</span></td>
    <td><span>VeriiPro Technologies</span></td>
  </tr>
</table>



<p className="form_heading_lebel">Attachments <span className="add_new_item" id="add_item_5">
	<i className="fa fa-plus"></i>Add Documments</span></p>

<div className="section_hideshow hidden" id="Documments">
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Resume</option>
<option value="2">Cover Letter</option>
<option value="3">Offer</option>
<option value="4">Contract</option>
<option value="5">Others</option>
</select>

<label>Document Type*</label>
</div>
</div>

<div className="form-inner-box upload_section">


<div className="upload_section">   
<input id="uploadFile" placeholder="Upload..."  className="f-input" />
<div className="fileUpload btn btn--browse">
    <span>Browse</span>
    <input id="uploadBtn" type="file"  className="upload" />
</div>
</div>

</div>
<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Add </button>

</div>
</form>
</div>

<table className="table-bordered table">
  <tr>

    <th>Document Type</th>
    <th>Document Name</th>
    <th>Uploaded On</th>
    <th>Uploaded By</th>
    <th>Action</th>
  </tr>
  <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download | Archive</span></td>
  </tr>
  <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download | Archive</span></td>
  </tr>

    <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download | Archive</span></td>
  </tr>

    <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download | Archive</span></td>
  </tr>
</table>




<form action="">

<div className="form-submit-button mt-50">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>
<button className="save_ass_btn" type="submit"> Save and Associate </button>


</div>



</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Candiadte_form;
const Degree = [
  { title: 'MBA/PGDM'},
  { title: 'CA'},
  { title: 'CS'},
  { title: 'ICAI'},
  { title: 'ICWA'},
  { title: 'LLB/LLM'},
  { title: 'B Tech / BE'},
  { title: 'BArch'},
  { title: 'MBBS'},
  { title: 'BSc'},
  { title: 'BA'},
  { title: 'BCom'},
  { title: 'BBA'},
  { title: 'BCA'},
  { title: 'PhD'},

];
const job_title = [
  { title: 'Operations Head' },
  { title: 'Account Manager' },
  { title: 'Team Lead' },
  { title: 'Sales Lead' },
  { title: 'Recruiter' },
  { title: "Sourcer" },
  { title: 'Data Analyst' },
  { title: 'Resource Executive' },

];