import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notes from './../Component/Section/Notes.js'

const Notes_pop = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }


  return (


      
    <>

   <i onClick={toggle} className="fa fa-sticky-note" title="Notes"></i>
      <Modal isOpen={modal} toggle={toggle} className={className}>
       
        <ModalBody>
        <div className="row">
<div className="box_attachment">

<div className="box_attach_type">

</div>

<Notes/>


	</div>
</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Notes_pop;