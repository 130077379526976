
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
  import Choose_job from './../Pop_up/Choose_job'
  import Choose_candidate from './../Pop_up/Choose_candidate'
  import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const $ = window.$;
class Client_submit extends React.Component {

  constructor(){
    super();
    this.state={
showCc: false,
showBcc: false,
addTo: false,
      data: [
  {
    "id": 1,
    "name": "Azad Hussain",
    },

  {
    "id": 2,
    "name": "Rakesh Yadav",
  },
  {
    "id": 3,
    "name": "Nida",
  },
  {
    "id": 4,
    "name": "Ankit",
  }
],
      value: ''

     };

    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
  }

  addClass(e,id) {
    this.setState({
      [id]:!this.state[id]
    });
  }

  removeClass(id) {
    this.setState({
      [id]:false
    });
  }
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

	componentDidMount(){
}

render() {
      const { data, value } = this.state;
  return (
    <>

  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing candidate_page mt-50">
<div className="container-fluid">

<div className="job_filter_name_heading">
<div className="job_heding_name">
<span>
<Link to="">
<i className="fa fa-long-arrow-left"></i>
</Link>
</span>
<span>
Submit to Client
</span>

</div>
</div>
<div className="client_submission_outer mt-110">

  <div className="email_right_side append_attri">
<h3>Select Candidate</h3>
<div className="append_attri">
<input type="text" value={value} onChange={this.handleChange} />
        <SearchResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                <div>
                 <span>{el.name}</span>
                </div>
              ))}
            </div>
          )}
        />
</div>
</div> 
<div className="client_sub">
<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 right_fix_side side_content_full client_submit">

 
    <div className="client_sub_mission_form">
    
      <div className="client_single_field">

  <div className="client_to">
      <div>
          <span  className="strng"> Candidate Selected </span>
      </div>
      <div className="client_to_detail">
      <div className="starRating">
         <div className="selected_can_di">
            <span>Samul</span><i className="fa fa-paperclip"></i><i className="fa fa-times only_hover"></i>
         </div>
          <div className="selected_can_di">
            <span>Chirst</span><i className="fa fa-paperclip"></i><i className="fa fa-times only_hover"></i>
         </div>
         </div>
      </div>
  </div>

      </div>

<div className="client_single_field">
    <div className="client_to">
                  <div>
                    <span className="strng">Against a Job </span>
                </div>
        <div className="client_to_detail">
                <div className="starRating">
                      <div className="chose_job_here">         
                          <div className="selected_can_di">
                              <span>Front-End Developer</span>
                          </div> 
                      </div>
                          <div className="pick_jobs_here"><Choose_job/>
                          </div>
                </div>
          </div>
    </div>
</div>




<div className="client_single_field">
  <div className="client_to">
      <div>
        <span className="strng">Client Name </span>
    </div>
    <div className="client_to_detail">
        <div className="starRating">
          <div className="chose_job_here">         
            <div className="selected_can_di">
                <span>Samul</span>
            </div> 
          </div>
          <div className="pick_jobs_here"><Choose_candidate/>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> From </span>
      </div>
      <div className="client_to_detail">
        <input type="text" className="originalinput"/>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> To </span>
      </div>
       <div className="client_to_detail">
          <span>Sanjeev.gem</span>
          <div className="cc_bcc">
          <span onClick={(e)=>this.addClass(e,'showCc')} id="mail_cc">Add cc</span>
          <span onClick={(e)=>this.addClass(e,'showBcc')} id="mail_bcc">Add Bcc</span>
          <span onClick={(e)=>this.addClass(e,'addTo')} id="mail_reply_to">Add Reply to</span>
          </div>
          {/* <div className="select_contact_radio">
          <span><input type="radio"/><em> Related Contact </em> </span>
          <span><input type="radio"/> <em> Primary Contact </em> </span>
          <span><input type="radio"/> <em> All Contact </em> </span>
          </div> */}
      </div>
  </div>

</div>

               {
          this.state.showCc==true
            ? (
<div className="client_single_field">

 <div className="client_to">
      <div>
          <span className="strng"> cc </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          <div className="email_drop_down_list">
          <div className="cc_bcc_list">
          <ul className="child-menu">
          <li>
          <span>Rakesh Yadav <i>( Web designer )</i> </span>
          <span>rakesh.yadav@techvilla <i>( Vendor )</i></span>          
          </li>
          <li>
          <span>Azad Hussain <i>( Web Developer )</i> </span>
          <span>azad@techvilla <i>( Client )</i></span>          
          </li>
          <li>
          <span>Nida <i>( Tester )</i> </span>
          <span>nida@techvilla <i>( Contact )</i></span>          
          </li>
          </ul>
          </div>
          </div>
          </div>
      </div>
  </div>

</div>
       )
            : (
              null
            )
        }
               {
          this.state.showBcc==true
            ? (
<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Bcc </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          <div className="email_drop_down_list">
          <div className="cc_bcc_list">
          <ul className="child-menu">
          <li>
          <span>Rakesh Yadav <i>( Web designer )</i> </span>
          <span>rakesh.yadav@techvilla <i>( Vendor )</i></span>          
          </li>
          <li>
          <span>Azad Hussain <i>( Web Developer )</i> </span>
          <span>azad@techvilla <i>( Vendor )</i></span>          
          </li>
          <li>
          <span>Nida <i>( Tester )</i> </span>
          <span>nida@techvilla <i>( Vendor )</i></span>          
          </li>
          </ul>
          </div>
          </div>
          </div>
      </div>
  </div>

</div>
       )
            : (
              null
            )
        }
                       {
          this.state.addTo==true
            ? (
<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Reply to </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          </div>
      </div>
  </div>

</div>
       )
            : (
              null
            )
        }
<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Choose Email Template </span>
      </div>
      <div className="client_to_detail">
        <div className="form-inner-box">					
          <div className="floating-label">      
            <select className="floating-select" onClick="this.setAttribute('value', this.value);">
            <option value="">None</option>
            <option value="1">Resume</option>
            <option value="2">Client</option>
            </select>

           <label>Email Template</label>
          </div>
        </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Attachment </span>
      </div>
      <div className="client_to_detail">
        <div className="notes_upload">
          <div className="upload_section">   
            <div className="fileUpload btn btn--browse">
                <span><i className="fa fa-paperclip"></i> browse</span>
                <input id="uploadBtn" type="file"  className="upload" />
            </div>
          </div>
        </div>
      </div>
  </div>

</div>



<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Subject Line </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          </div>
      </div>
  </div>

</div>


<div className="client_single_field">

  <div className="client_to">
 
    <div className="form-inner-box full_with">	

      <div className="floating-label">  

        <textarea className="floating-input floating-textarea" placeholder=" "></textarea>

      </div>

    </div>

  </div>

</div>


<div className="client_single_field">

  <div className="client_to">
    <div className="send_to_all">
     <input type="checkbox"/>
     <span>Add Signature.</span>
    </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Send </button>
</div>
  </div>

</div>
</div>

</div>
    </div>
    </div>

  </div>

          <Footer/>
  </div>
    </>
  );
}

}
export default Client_submit;