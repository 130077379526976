
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import Choose_job from './../Pop_up/Choose_job'
const Update_candidate_status = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle}  class="fa fa-pencil update_ststuss"></i>
<Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className}>
<ModalHeader className="filter_header" toggle={toggle}>  
<div className="filter_header_model">
    <div className="filter_modelheading">
        <span className="filter_tbs">Update Status</span>
    </div>
</div>  
</ModalHeader>
    <ModalBody>
    <div className="row">
        <div className="rating_section_block">
        <div className="section">
        <div className="col-lg-6">
        <p>Select Candidate Status</p>
        </div>
        <div className="col-lg-6">
        <div className="form-inner-box">					
        <div className="floating-label">      
        <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
        <option value=""></option>
        <optgroup label="Screening">
            <option value="volvo">new</option>
            <option value="saab">Screening passed</option>
            <option value="saab">Screening failed</option>
            <option value="saab">Not interested</option>
            <option value="saab">Not Contacted</option>
            <option value="saab">Internal Submission</option>
            <option value="saab">Internal Screen Rejected</option>
        </optgroup>
        <optgroup label="Client Submission">
            <option value="mercedes">Submiited to client</option>
            <option value="audi">Client Screen Rejected</option>
            <option value="audi">Client Screen Approved</option>
        </optgroup>
            <optgroup label="Client Interview">
            <option value="mercedes">Schedule Interview</option>
            <option value="mercedes">Interview Scheduled </option>
            <option value="audi">Interview in Progress</option>
            <option value="audi">on Hold</option>
            <option value="audi">Interview Selected</option>
            <option value="audi">Interview Rejected</option>
        </optgroup>
            <optgroup label="Offer">
            <option value="mercedes">Ready to Offer</option>
            <option value="mercedes">Offered </option>
            <option value="audi">Offer Accepted</option>
            <option value="audi">Offer Declined</option>
            <option value="audi">Offer Withdrawn</option>
            </optgroup>
            <optgroup label="Hired">
            <option value="mercedes">Hired</option>
            <option value="mercedes">Joined </option>
            <option value="audi">No Show</option>
        </optgroup>
            <optgroup label="Client Interview">
            <option value="mercedes">Other</option>
        </optgroup>
        </select>
        </div>
        </div>
        </div>
        </div>
        </div>

    </div>

    </ModalBody>
    <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>          
        <Button color="primary" onClick={toggle}>Save</Button>{' '}
    </ModalFooter>
</Modal>
    </>
  );
}

export default Update_candidate_status;