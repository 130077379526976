import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Switch from "react-switch";

const $ = window.$;

class System_config extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {file: '',imagePreviewUrl: ''};
  }
 

_handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleChange(checked) {
    this.setState({ checked });
  }




	componentDidMount(){

}

render() {



  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing myprofile_form">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">
<h1 className="main_title">
           System Configurations
        </h1>

<p className="form_heading_lebel">Configuration Setting </p>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Active</option>
<option value="2">Filled</option>
<option value="3">On Hold</option>
<option value="4">Cancelled</option>
<option value="5">Inactive</option>
</select>

<label>Defult Job Status</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Screening</option>
<option value="2">Client Submission</option>
<option value="3">Client Interview</option>
<option value="4">Offer</option>
<option value="5">Hired</option>
<option value="5">Others</option>
</select>

<label>Defult Candidate Status</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Resume Parsing Email</label>
</div>
</div>



<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Can Skip Candidate Status</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#ccc"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch1"
    id="material-switch10"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">During login always ask for OTP</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch1"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">Deny login if user don't login continuously for </div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch2"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">During login ask for OTP after every</div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch3"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with">					
<div className="form_switch_box">

<div className="form_switch_lebal">Choose Candidate Duplication Criteria
<p>
<span><input type="radio" className="confi_radio"/> <em>Email</em></span>
<span><input type="radio" className="confi_radio"/> <em> (Email And DOB) Or (Email And (Phone OR Alternate Phone))</em></span>
</p>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch4"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">After Internal Submission if Candidate got rejected
 the Candidate's Authority still remain with user who has done submission for maximum</div>
 <div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
<span>Post Submission</span>

</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch5"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">Candidate ownership is set for</div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
<span>After Creation Date</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch6"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">If Candidate On-Boarding is due notify account manager</div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
<span>Before Joining Date</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch7"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">Notify and Consider Candidate as Prospect Release</div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
<span>Before Release Date</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch8"
  />
</div>

</div>
</div>

<div className="form-inner-box full_with extra_smal">					
<div className="form_switch_box">

<div className="form_switch_lebal">On Candidate Release Date, Notify and Consider Candidate On Bench if No active Job is associated with Candidate with in</div>
<div className="confi_input">
<input type="text" className="confi_input"/><span className="confi_day">Days</span>
<span>Before Release Date</span>
</div>
<div className="form_switch_button">
  <Switch
    checked={this.state.checked}
    onChange={this.handleChange}
    onColor="#4c799e"
    onHandleColor="#2693e6"
    handleDiameter={30}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={20}
    width={48}
    className="react-switch"
    id="material-switch9"
  />
</div>

</div>
</div>


<form action="">

<div className="form-submit-button mt-50">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>

</div>

</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default System_config;