import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import Choose_job from './../Pop_up/Choose_job'

const Edit_interview = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <span  onClick={toggle} class="interedit" data-iid="56" data-email="tarunsingh7102@gmail.com" data-phone="9958362370" data-name="Tarun Singh" data-pos="Web Designing ( position#33 )"> Edit </span>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className} className="nested_model edit_inter">
        <ModalHeader toggle={toggle}>Update Interview Status </ModalHeader>

          <ModalBody>


<div className="form-container">

<div className="inner-form-container">
<div className="all-form-width interview">

<div className="signup-form-homepage">
<div className="edit_inter_single table-responsive">
<table align="left" className="table .table-bordered .table-hover">
<tbody>
<tr>
<td>Name : </td>
<td>Rakesh Yadav  </td>
</tr>
<tr>
<td>Email : </td>
<td>rakesh.yadav@techvilla.in </td>
</tr>
<tr>
<td>Phone No. : </td>
<td>+1- 9878987898 </td>
</tr>
<tr>
<td>Position : </td>
<td>Web Designer : </td>
</tr>
</tbody>
</table>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Canceled</option>
<option value="2">Selected</option>
<option value="3">Not Selected</option>
<option value="4">Selected for Next Round</option>
<option value="4">No Show</option>
</select>

<label>Interview Status*</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Cancel Reason/ Select/ Not Select (Remark)*</label>
</div>
</div>



</div>

</div>

</div>

</div>


            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    
              <Button color="primary" onClick={toggle}>Update</Button>{' '}
            </ModalFooter>
          </Modal>
    </>
  );
}

export default Edit_interview;