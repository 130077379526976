import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import classNames from 'classnames'
import Review from './../Pop_up/Review'
import Attachment from './../Pop_up/Attachment'
import Notes_pop from './../Pop_up/Notes_pop'
import Interview_pop from './../Pop_up/Interview_pop'
import Associated_tag from './../Pop_up/Associated_tag'
import Email_pop from './../Pop_up/Email_pop'
import Submit_client_pop from './../Pop_up/Submit_client_pop'
import Associated_candidate from './../Pop_up/Associated_candidate'
import Share_job from './../Pop_up/Share_job'
import Update_job_status from './../Pop_up/Update_job_status'
import Notes from './../Component/Section/Notes.js'
const $ = window.$;

class Job_Detail extends React.Component {

  constructor(){
    super();
     this.state = {
      showMenu: false,
      showBlock: false
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.showMenu = this.showMenu.bind(this);
    // this.showBlock = this.showBlock.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    // this.closeBlock = this.closeBlock.bind(this);
  }

  addClass() {
    this.setState({
      showBlock:true
    });
  }

  removeClass() {
    this.setState({
      showBlock:false
    });
  }

showMenu(event,id,hideOnExternalClick=true) {
    event.preventDefault();
    
    this.setState({ showMenu: id }, () => {
      if(hideOnExternalClick)
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

    closeBlock(id) {
    this.setState({ showMenu: id }, () => {
      document.removeEventListener('click', this.closeBlock);
    });
  }

	componentDidMount(){
}

render() {
  return (
    <>
  
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing candidate_page mt-50">
<div className="container-fluid">
<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left_fix_side side_menu">
<div className="main_side_menu">
<ul className="sidemenu_list">
<li className="active_side_menu"><em className="timeline">Timeline</em> <span className="last_update">Last Updated: 5 Days ago</span></li>
<li className="active_side_menu">Associated Candidate <em className="circle"> 1 </em> <span><Associated_candidate/></span></li>
<li><strong>RELATED INFORMATION</strong></li>
{/* <li className="active_side_menu">Notes <em className="circle"> 2 </em> <span><i className="fa fa-plus" onClick={this.addClass}></i></span></li> */}
<li className="active_side_menu"> <a href="#Notes"> Notes </a> <em className="circle"> 2 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Review">  Ratings and Reviews  </a><em className="circle"> 3 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Attachment">  Attachment  </a><em className="circle"> 4 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Interview">  Interviews  </a><em className="circle"> 1 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Email"> Emails   </a>   <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Client_Sub">  Client Submission   </a>  <span><i className="fa fa-plus"></i></span></li>
{/* <li className="active_side_menu"> <a href="#Assessment">  Answered Assessment  </a>  <span><i className="fa fa-plus"></i></span></li> */}

<li><strong>TO-DOs</strong></li>

<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Tasks   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Events   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Call   </li>

<li>  <a href="#ASSOCIATED_TAG">  <strong>ASSOCIATED TAGS </strong>  </a>    <span><i className="fa fa-plus"></i></span> </li>


</ul>
</div>
</div>
<div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 right_fix_side side_content_full">
<div className="main_fix_section">
<div className="cnadidate_detail_section">
<div className="candidate_info_sec">

<div className="candidate_pro_pic">
<img src="/../images/defult_logo.png" alt=""/>
{/* <div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div> */}
</div>

<div className="candidate_infoomation">
<div className="cand_info"><div className="candiadte_name">
<Link to="" className="h1_name job_ti_tle">Front End Developer (Job ID/Reference Code/4-6 Yrs)</Link>
</div></div>
<div className="all_icon_list_row all_sup_icon candid_icon">
<i className="fa fa-file-archive" title="Archieve"></i>
<i className="fa fa-refresh" aria-hidden="true" title="Refresh"></i>
<Email_pop/>
<Share_job/>
<i className="fa fa-download" aria-hidden="true" title="JD Preview"></i>
<Submit_client_pop/>
<Interview_pop/>
<i onClick={(e)=>this.showMenu(e,10)} aria-hidden="true" title="Other Actions" className="fa fa-reorder">

       {
          this.state.showMenu==10
            ? (
<div className="other_action_drop ">
<ul>
<li><Link to="">Find Matching Candidates</Link></li>
<li><Link to="">Share with Vendor</Link></li>
<li><Link to="">Clone Job</Link></li>
</ul>
</div>
       )
            : (
              null
            )
        }

</i>

</div>


<div className="candidate_converted">
<span> Active </span>
</div>
<Update_job_status/>

</div>

<div className="Company_del_sec">
<span className="company_na_me">Infosys Public Services </span>,<span className="company_add">Rockville, Maryland</span>
</div>

</div>
</div>
{/* fixed */}

<div className="profile_section_start">

<div className="job_detail_page_menu">
  <div className="job_page_inner">
   
    <button onClick={(e)=>this.showMenu(e,1)} className={(this.state.showMenu==1)?"single_node_inner darkblue active":"single_node_inner darkblue"}>
      <div className="job_upper_menu">
        <span>1</span>
        <span>Screening</span>
      </div>

       {
          this.state.showMenu==1
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter"> New </Link>  </span>
        <span><Link to="/Job/Job_list_filter">  Screening passed </Link></span>
        <span><Link to="/Job/Job_list_filter">  Screening failed </Link></span>
        <span><Link to="/Job/Job_list_filter">  Not interested </Link></span>
        <span><Link to="/Job/Job_list_filter">  Not Contacted </Link></span>
        <span><Link to="/Job/Job_list_filter">  Internal Submission </Link></span>
        <span><Link to="/Job/Job_list_filter">  Internal Screen Rejected </Link></span>
      </div>
       )
            : (
              null
            )
        }

    </button>


    <button onClick={(e)=>this.showMenu(e,2)} className={(this.state.showMenu==2)?"single_node_inner darkblue active":"single_node_inner violet"}>
      <div className="job_upper_menu">
        <span>1</span>
        <span>Client Submission</span>
      </div>

             {
          this.state.showMenu==2
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter">  Submitted to client</Link></span>
        <span><Link to="/Job/Job_list_filter">  Client Screen Rejected </Link></span>
        <span><Link to="/Job/Job_list_filter">  Client Screen Approved </Link></span>
      </div>
       )
            : (
              null
            )
        }
    </button>



    <button onClick={(e)=>this.showMenu(e,3)} className={(this.state.showMenu==3)?"single_node_inner darkblue active":"single_node_inner  chocolate"}>
      <div className="job_upper_menu">
        <span>1</span>
        <span>Client Interview</span>
      </div>

       {
          this.state.showMenu==3
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter">  Schedule Interview </Link></span>
        <span><Link to="/Job/Job_list_filter">  Interview Scheduled </Link></span>
        <span><Link to="/Job/Job_list_filter">  Interview in progress </Link></span>
        <span><Link to="/Job/Job_list_filter">  On Hold </Link></span>
        <span><Link to="/Job/Job_list_filter">  Interviewed Selected </Link></span>
        <span><Link to="/Job/Job_list_filter">  Interview Rejected </Link></span>
      </div>
       )
            : (
              null
            )
        }

    </button>


    <button onClick={(e)=>this.showMenu(e,4)} className={(this.state.showMenu==4)?"single_node_inner darkblue active":"single_node_inner  yellow"}>
      <div className="job_upper_menu">
        <span>1</span>
        <span>Offered</span>
      </div>
             {
          this.state.showMenu==4
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter">  Ready to Offer </Link></span>
        <span><Link to="/Job/Job_list_filter">  Offered </Link></span>
        <span><Link to="/Job/Job_list_filter">  Offer Accepted </Link></span>
        <span><Link to="/Job/Job_list_filter">  Offer Declined </Link></span>
        <span><Link to="/Job/Job_list_filter">  Offer Withdraw </Link></span>
      </div>
       )
            : (
              null
            )
        }
    </button>



    <button onClick={(e)=>this.showMenu(e,5)} className={(this.state.showMenu==5)?"single_node_inner darkblue active":"single_node_inner  lightgreen"}>
      <div className="job_upper_menu">
        <span>1</span>
        <span>Hired</span>
      </div>
             {
          this.state.showMenu==5
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter">  Hired </Link></span>
        <span><Link to="/Job/Job_list_filter">  Joined </Link></span>
        <span><Link to="/Job/Job_list_filter">  No Show </Link></span>
      </div>
       )
            : (
              null
            )
        }
    </button>


    <button onClick={(e)=>this.showMenu(e,6)} className={(this.state.showMenu==6)?"single_node_inner darkblue active":"single_node_inner  gray"}>
      <div className="job_upper_menu">
        <span>0</span>
        <span>Other</span>
      </div>
             {
          this.state.showMenu==6
            ? (
      <div className="job_lower_menu_list menu">
        <span><Link to="/Job/Job_list_filter">  Other </Link></span>
      </div>
       )
            : (
              null
            )
        }
    </button>



       

  </div>
</div>  

<div className="profile_medotry">

<div className="main_line_edit">
<div className="main_title_sec">Work Auth</div>
             {
          this.state.showMenu!=7
            ? (
              <>
<div className="main_title_sec">H1B</div>
<div onClick={(e)=>this.showMenu(e,'7',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==7
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'7')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Fill Type</div>
             {
          this.state.showMenu!=8
            ? (
              <>
<div className="main_title_sec">Intermediate</div>
<div onClick={(e)=>this.showMenu(e,'8',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==8
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'8')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Contract Type</div>
             {
          this.state.showMenu!=9
            ? (
              <>
<div className="main_title_sec">1099</div>
<div onClick={(e)=>this.showMenu(e,'9',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==9
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'9')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Client Manger</div>
             {
          this.state.showMenu!=92
            ? (
              <>
<div className="main_title_sec">Mr Dev Brown</div>
<div onClick={(e)=>this.showMenu(e,'92',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==92
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'92')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Account Manger</div>
             {
          this.state.showMenu!=50
            ? (
              <>
<div className="main_title_sec">Mr Dev Prakash</div>
<div onClick={(e)=>this.showMenu(e,'50',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==50
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'50')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Priority</div>
             {
          this.state.showMenu!=12
            ? (
              <>
<div className="main_title_sec">High</div>
<div onClick={(e)=>this.showMenu(e,'12',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==12
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'12')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Open Date</div>
             {
          this.state.showMenu!=13
            ? (
              <>
<div className="main_title_sec">Mar 20 , 2020</div>
<div onClick={(e)=>this.showMenu(e,'13',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==13
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'13')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Target Date</div>
             {
          this.state.showMenu!=14
            ? (
              <>
<div className="main_title_sec">Apr 10 , 2020</div>
<div onClick={(e)=>this.showMenu(e,'14',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==14
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'14')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="hide_block"><span id="hide_sh_element">Hide Detail <i className="fa fa-chevron-up"></i></span></div>

</div>
</div>


<div id="hide_id" className="">
<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> General Information </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">System Job Id</div>
<div className="main_title_sec">12345</div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Title</div>
             {
          this.state.showMenu!=15
            ? (
              <>
<div className="main_title_sec">Front End Developer</div>
<div onClick={(e)=>this.showMenu(e,'15',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==15
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'15')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Type</div>
             {
          this.state.showMenu!=16
            ? (
              <>
<div className="main_title_sec">Contract</div>
<div onClick={(e)=>this.showMenu(e,'16',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==16
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'16')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Client</div>
             {
          this.state.showMenu!=17
            ? (
              <>
<div className="main_title_sec">Techvilla Solutions</div>
<div onClick={(e)=>this.showMenu(e,'17',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==17
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'17')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Status</div>
             {
          this.state.showMenu!=18
            ? (
              <>
<div className="main_title_sec">Active</div>
<div onClick={(e)=>this.showMenu(e,'18',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==18
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'18')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Fill Type</div>
             {
          this.state.showMenu!=19
            ? (
              <>
<div className="main_title_sec">Hard</div>
<div onClick={(e)=>this.showMenu(e,'19',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==19
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'19')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Category</div>
             {
          this.state.showMenu!=20
            ? (
              <>
<div className="main_title_sec">US Citizen</div>
<div onClick={(e)=>this.showMenu(e,'20',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==20
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'20')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Relevent Experience (Years)</div>
             {
          this.state.showMenu!=21
            ? (
              <>
<div className="main_title_sec">4-5</div>
<div onClick={(e)=>this.showMenu(e,'21',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==21
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'21')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Open Date</div>
             {
          this.state.showMenu!=22
            ? (
              <>
<div className="main_title_sec">mar 20 , 2020</div>
<div onClick={(e)=>this.showMenu(e,'22',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==22
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'22')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Account Manager</div>
             {
          this.state.showMenu!=23
            ? (
              <>
<div className="main_title_sec">Avinash</div>
<div onClick={(e)=>this.showMenu(e,'23',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==23
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'23')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Client Job Reference Code</div>
             {
          this.state.showMenu!=24
            ? (
              <>
<div className="main_title_sec">2324</div>
<div onClick={(e)=>this.showMenu(e,'24',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==24
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'24')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Location</div>
             {
          this.state.showMenu!=25
            ? (
              <>
<div className="main_title_sec">Little Rock , USA</div>
<div onClick={(e)=>this.showMenu(e,'25',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==25
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'25')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Work Auth</div>
             {
          this.state.showMenu!=26
            ? (
              <>
<div className="main_title_sec">H1B</div>
<div onClick={(e)=>this.showMenu(e,'26',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==26
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'26')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Client Manager</div>
             {
          this.state.showMenu!=27
            ? (
              <>
<div className="main_title_sec">Mr Dev Brown</div>
<div onClick={(e)=>this.showMenu(e,'27',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==27
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'27')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Contract Type</div>
             {
          this.state.showMenu!=28
            ? (
              <>
<div className="main_title_sec">1099</div>
<div onClick={(e)=>this.showMenu(e,'28',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==28
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'28')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Job Priority</div>
             {
          this.state.showMenu!=29
            ? (
              <>
<div className="main_title_sec">High</div>
<div onClick={(e)=>this.showMenu(e,'29',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==7
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'29')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>


<div className="main_line_edit">
<div className="main_title_sec">Opening</div>
             {
          this.state.showMenu!=30
            ? (
              <>
<div className="main_title_sec">3</div>
<div onClick={(e)=>this.showMenu(e,'30',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==30
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'30')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Total Experience(Years)</div>
             {
          this.state.showMenu!=31
            ? (
              <>
<div className="main_title_sec">5</div>
<div onClick={(e)=>this.showMenu(e,'31',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==31
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'31')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Closurs Date</div>
             {
          this.state.showMenu!=32
            ? (
              <>
<div className="main_title_sec">Apr 10 , 2020</div>
<div onClick={(e)=>this.showMenu(e,'32',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==32
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'32')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Req Type</div>
             {
          this.state.showMenu!=33
            ? (
              <>
<div className="main_title_sec">3</div>
<div onClick={(e)=>this.showMenu(e,'33',false)} className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==33
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'33')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        }
</div>



</div>

{/* row end */}

</div>
</div>







{/* basic detail */}

<div className="profile_section_start nextroww" id="Attachment">
<div className="profile_medotry">

<p className="block_heading"> Attach Document </p>

<div className="interview_section">
<table className="table-bordered table">

<thead>
  <tr>

    <th>Document Type</th>
    <th>Document Name</th>
    <th>Uploaded On</th>
    <th>Uploaded By</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download</span> / <span>Delete</span></td>
  </tr>

</tbody>
</table>
<div className="edit_deltee">

<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div>
</div>
</div>

{/* Notes */}

<div className="profile_section_start nextroww pos_rel" id="Notes" >
<Notes/>
</div>


{/* end of notes */}


{/* <div className="profile_section_start nextroww pos_rel" id="Review" >
<div className="profile_medotry">

<p className="block_heading">Ratings and Reviews 
<Review/>
 <button className="rating_select" id="">Submit Review</button> 
<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Gernal</option> 
<option>Job Based</option> 
<option>Pre Screening</option> 
<option>Behaviour Screening</option> 
<option>Background Screening</option> 
<option>Background Interview</option> 
</select> 

</span>
 

 </p>



<div className="all_notes_section">
<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span> made a <span>Job Based Review</span> on Apr 10 , 2020 12:40 PM
<i className="fa fa-trash-o"></i><i className="fa fa-pencil"></i></div>
</div>

<div className="rating_review">

<div className="raing_section">
<div className="left_rev_rating">
<span>Overall Rating</span>
</div>
<div className="right_side_rev_rating">
<span>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star"></i>
</span> |

 <span>Excellent</span>
</div>
</div>

<div className="raing_section">
<div className="left_rev_rating">
<span>Overall Comment</span>
</div>
<div className="right_side_rev_rating">
 <span>Excellent fit for job</span>
</div>
</div>

<div className="raing_section">
<div className="left_rev_rating">
<span>Job Opening Name</span>
</div>
<div className="right_side_rev_rating">
 <span><a href="">Product Analyst</a></span>
</div>
</div>



</div>

</div>

</div>
</div>
</div> */}

 {/* review rating */}


<div className="profile_section_start nextroww pos_rel" id="Interview" >
<div className="profile_medotry">

<p className="block_heading">Interview 

<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Upcomimg</option> 
<option>Overdue</option> 
<option>concluded</option>
</select> 

</span>
 

 <button className="rating_select interv" id="interview_pop_up"><Link to="/Interview_form" target="_blank"><i className="fa fa-plus"></i> Schedule Interview</Link></button>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div>




 </div>
 </div>

{/* Interview */}



<div className="profile_section_start nextroww pos_rel" id="Email" >
<div className="profile_medotry">

<p className="block_heading">Email 


<div className="email_infosec">
<span>Sent By</span>
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value="">Sanjeev.gem</option>
</select>

<label>User</label>
</div>
</div>


<span>To</span>
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value="">chrish.grm@gmail.com</option>
</select>

<label>Candidate</label>
</div>
</div>


</div>


<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Offical Email</option> 
<option>Automated Email</option> 
<option>Bounced Email</option>
</select> 

</span>
 
<form action="/email">
 <button className="rating_select interv" id="interview_pop_up"><Link to="/email" target="_blank"><i className="fa fa-plus"></i> Send Email </Link> </button>
 </form>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th></th>
<th>To</th>
<th>Subject</th>
<th>Date</th>
<th>From</th>
<th>Status</th>
</tr>
</thead>
<tbody>
<tr>
<td><i className="fa fa-envelope-open-o" title="Sent"></i></td>
<td>C.devprakash.gmail.com</td>
<td>Interview Detail For Product Manager</td>
<td>Mar 20 , 2020</td>
<td>sanjeev.gem@gemail.com</td>
<td>Bounced</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-trash-o"></i>
</div>
</div>




 </div>
 </div>

{/* client submit */}

<div className="profile_section_start nextroww pos_rel" id="Client_Sub" >
<div className="profile_medotry">

<p className="block_heading">Client Submit 

 

 <button className="rating_select interv" id="interview_pop_up"> 
 <Link to="/client_submit" target="_blank"><i className="fa fa-plus"></i> Submit to Client</Link></button>
 </p>
 <div className="no_record">
  <em>No records found</em>
 </div>





 </div>
 </div>

{/* Interview */}



{/* assessment */}
</div>


{/* attachment */}

<div className="profile_section_start nextroww" id="ASSOCIATED TAGS" >
<div className="profile_medotry">

<p className="block_heading">Associated Tags

 

 <button className="rating_select interv" id="interview_pop_up">         
    <div className="notes_upload">
      <div className="upload_section">   
        <div className="fileUpload btn btn--browse">
           <Associated_tag/> 
        </div>
      </div>
    </div> 
  </button>
 </p>
 <div className="no_record">
  <em>No records found</em>
 </div>


 </div>
 </div>

{/* end of attachment */}
</div>
</div>
</div>


  </div>

          <Footer/>
  </div>
    </>
  );
}

}
export default Job_Detail;