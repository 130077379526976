import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Choose_candidate from './../Pop_up/Choose_candidate'
import Choose_client from './../Pop_up/Choose_client'
import Choose_client_manager from './../Pop_up/Choose_client_manager'
import Choose_account_manager from './../Pop_up/Choose_account_manager'
import Share_candidate from './../Pop_up/Share_candidate'
import Job_title from './../Dropdown/Job_title'
import { Multiselect } from 'multiselect-react-dropdown';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const $ = window.$;

class Job_post_form extends React.Component {

constructor(){
super();
 this.state = {
      plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
      objectArray: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" },
        { key: "Option 3", cat: "Group 1" },
        { key: "Option 4", cat: "Group 2" },
        { key: "Option 5", cat: "Group 2" },
        { key: "Option 6", cat: "Group 2" },
        { key: "Option 7", cat: "Group 2" }
      ],
      selectedValues: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" }
      ]
    };
    this.style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px"
      },
      multiselectContainer: {
        color: "red"
      }
    };
    this.addItem = this.addItem.bind(this);
  }
  addItem() {
    this.selectedValues.push({ key: "Option 3", cat: "Group 1" });
  }

  render() {
    const { plainArray, objectArray, selectedValues } = this.state;
return (
<>


<div className="main-body emp_body">
<Header_after_login/>

<div className="homepage-outer form-container-listing">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
        <h1 className="main_title">
           Add New Job
        </h1>
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
                <button className="save_ass_btn" type="submit"> Save as Draft </button>
                <Share_candidate/>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-20">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">

<p className="form_heading_lebel">General Information</p>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder="" readonly="readonly" />

<label>System Job ID</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Client Job Reference Code *</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={job_title}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Job Title" variant="outlined" />}
    />
</div>
</div>

<div className="form-inner-box full_with label_class">	
<label>Location *</label>				
<div className="floating-label location_controll">      
<Multiselect
  options={objectArray}
  selectionLimit="3"
  displayValue="key"
/>

</div>
</div>			
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Any</option>
<option value="2">Contract</option>
<option value="3">Freelance</option>
<option value="4">Full time</option>
<option value="5">Part time</option>
<option value="5">Permanent</option>
<option value="5">Seasonal</option>
<option value="5">Temporary</option>
<option value="5">Training</option>
<option value="5">Volunteer</option>
</select>

<label>Job Type *
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">E3</option>
<option value="2">EAD GC</option>
<option value="3">Green Card</option>
<option value="4">H1B</option>
<option value="5">L2 EAD</option>
<option value="5">OPT EAD</option>
<option value="5">TN</option>
<option value="5">US Citizen</option>
</select>

<label>Work Auth / Visa *
</label>
</div>
</div>			


<div className="form-inner-box with_extra_field pop_up_form">	
<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Client Name *</label>
</div>

<div className="floating-label one_fourth _first_right"> 
<div class="form_pop_up">     
<Choose_client/> 
</div>
</div>
</div>

<div className="form-inner-box with_extra_field pop_up_form">					
<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Client Manager *</label>
</div>

<div className="floating-label one_fourth _first_right"> 
<div class="form_pop_up">     
<Choose_client_manager/> 
</div>
</div>
</div>			
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Active</option>
<option value="2">Covered</option>
<option value="3">Hold</option>
<option value="4">Closed</option>
<option value="5">InActive</option>
</select>

<label>Job Status *
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">1099</option>
<option value="2">C2C</option>
<option value="3">W2</option>
<option value="4">Payroll</option>
<option value="5">Permanent Hire</option>
<option value="5">Subcon Company</option>
</select>


<label>Job Contract Type *
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Hard</option>
<option value="2">Intermediate</option>
<option value="3">Regular</option>
</select>


<label>Job Fill Type *
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">High</option>
<option value="2">Medium</option>
<option value="3">Low</option>
<option value="4">None</option>
</select>

<label>Job Priority *
</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={job_cat}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Job Category" variant="outlined" />}
    />
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Openings *
</label>
</div>
</div>			<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
<option value="5">5+</option>
</select>					

<label>Relevant Experience (Years) *</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">1</option>
<option value="2">2</option>
<option value="3">3</option>
<option value="4">4</option>
<option value="5">5+</option>
</select>

<label>Total Experience (Years)</label>
</div>
</div>			
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" onClick="(this.type='date')" placeholder=" "/>

<label>Open Date *
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="date" onClick="(this.type='date')" placeholder=" "/>

<label>Closure Date
</label>
</div>
</div>			
<div className="form-inner-box with_extra_field pop_up_form">					
<div className="floating-label tirth_fourth">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Account Manager *</label>
</div>

<div className="floating-label one_fourth _first_right"> 
<div class="form_pop_up">     
<Choose_account_manager/> 
</div>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Back Fill</option>
<option value="2">New Need</option>
</select>

<label>Job Req. Type *</label>
</div>
</div>

<p className="form_heading_lebel">Job Information</p>


<div className="form-inner-box full_with label_class extra_top">	
<label>Essential Skills *</label>					
<div className="floating-label">      
            <CKEditor
                    editor={ ClassicEditor }
                    data="<p></p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />


</div>
</div>

<div className="form-inner-box full_with label_class extra_top">	
<label>Good to Have Skills *</label>						
<div className="floating-label">      
            <CKEditor
                    editor={ ClassicEditor }
                    data="<p></p>"
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />

</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={QualificationLevel}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Qualification Level" variant="outlined" />}
    />

</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={Degree}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Degree" variant="outlined" />}
    />

</div>
</div>



<div className="form-inner-box">					
<div className="floating-label">      
    <Autocomplete
      id="combo-box-demo"
      options={CourseType}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Course Type" variant="outlined" />}
    />

</div>
</div>

{/* <!-- jd--> */}
<div className="form-inner-box full_with upload_section">

<div className="job_form_gernal_info dom_ref">

<label className="jo_bt_it_le"> Job Description </label>

<button className="Paste_jd_here" id="past__yor_jbd"> Paste JD </button>
<span> OR </span>
<button className="Paste_jd_here" id="uplod_yor_jbd"> Upload JD </button>

</div>

<div className="file_you_to_show_pld">
<label className="jo_bt_it_le"> Attach Resume    </label>    
<input id="uploadFile" placeholder="Attach File"  className="f-input" />
<div className="fileUpload btn btn--browse">
<span>Browse</span>
<input id="uploadBtn" type="file"  className="upload" />
</div>

</div>


<div className="job_form_title file_you_to_show ">
<div className="floating-label">      
<textarea className="floating-input floating-textarea jdtext" placeholder=" "></textarea>

<label> Job Description</label>


</div>

</div>
</div>
{/* <!-- jd--> */}

<p className="form_heading_lebel">Duration & Compensation</p>




<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Days</option>
<option value="2">Weeks</option>
<option value="3">Months</option>
<option value="4">years</option>
</select>

<label>Duration *</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Period *</label>
</div>
</div>


<div className="form-inner-box salary_rate_type">					

<span> Salary</span>
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value=""></option>
<option value="1">Annual</option>
<option value="2">Monthly</option>
<option value="3">Weekly</option>
<option value="4">Hourly</option>
</select>

<label>Rate Type</label>
</div>
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Min salary</label>
</div>
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label> Max salary</label>
</div>

</div>



<form action="">

<div className="form-submit-button">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>
<button className="save_ass_btn" type="submit"> Save as Draft </button>
<Share_candidate/>
</div>



</form>

</div>

</div>

</div>

</div>

</div>



<Footer/>
</div>
</>
);
}

}


export default Job_post_form;
const job_title = [
  { title: 'Operations Head' },
  { title: 'Account Manager' },
  { title: 'Team Lead' },
  { title: 'Sales Lead' },
  { title: 'Recruiter' },
  { title: "Sourcer" },
  { title: 'Data Analyst' },
  { title: 'Resource Executive' },

];
const job_cat = [
  { title: 'Frontend Developer' },
  { title: 'Backend Developer' },
  { title: 'IT Infrastructure' },
  { title: 'Mobile Applications' },
  { title: 'Digital Marketing' },
  { title: "DevOps" },
  { title: 'Data Science and Analytics' },
  { title: 'Web Designer' },
  { title: 'Graphic Designer' },
  { title: 'Emerging Technologies' },
  { title: 'Consulting/Advisory' },
  { title: 'Project Management' },
  { title: 'Product Management' },
  { title: 'UI/UX Design' },
  { title: 'Embedded Technology' },
  { title: 'Software Testing' },

];
const Client = [
  { title: 'Rakesh' },
  { title: 'Azad' },
  { title: 'Sanjeev' },
  { title: 'Suman' },

];
const Degree = [
  { title: 'MBA/PGDM'},
  { title: 'CA'},
  { title: 'CS'},
  { title: 'ICAI'},
  { title: 'ICWA'},
  { title: 'LLB/LLM'},
  { title: 'B Tech / BE'},
  { title: 'BArch'},
  { title: 'MBBS'},
  { title: 'BSc'},
  { title: 'BA'},
  { title: 'BCom'},
  { title: 'BBA'},
  { title: 'BCA'},
  { title: 'PhD'},

];

const QualificationLevel = [
  { title: 'Secondary Education or High School'},
  { title: 'Vocational Qualification'},
  { title: 'Undergraduate'},
  { title: 'Certificate / Diploma'},
  { title: 'Bachelors Degree'},
  { title: 'Masters Degree'},
  { title: 'Doctorate or Professional Degree'},

];

const CourseType = [
  { title: 'Secondary Education or High School'},
  { title: 'Vocational Qualification'},
  { title: 'Undergraduate'},
  { title: 'Certificate / Diploma'},
  { title: 'Bachelors Degree'},
  { title: 'Masters Degree'},
  { title: 'Doctorate or Professional Degree'},

];
