import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



const Associated_tag = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }


  return (


      
    <>

    <td className="action_pop" onClick={toggle}> Change</td>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Change Security </ModalHeader>
        <ModalBody>
        <div className="row">
<div className="box_attachment">

                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                            <option value=""></option>
                            <option value="1">Account Manger</option>
                            <option value="2">Owner</option>
                            <option value="3">Product Manger</option>
                            </select>
                        <label>Change Security</label>
                        </div>
                    </div>


	</div>
</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Associated_tag;