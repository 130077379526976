import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import Header from './../ATS_common/Header'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Change_password extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header/>



  <div className="form-container">
	
	<div className="inner-form-container container">
		<div className="all-form-width">
		<div className="signup-form-homepage mt-50">
			<img src="./images/logo.png" alt=""/>
			<p>Change password</p>
			<form action="/Signin">

				<div className="form-inner-box">
                    <div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>New Password	</label>
					</div>
				</div>

				<div className="form-inner-box">
                    <div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>Conform Password	</label>
					</div>
				</div>


				<div className="iamnotrobot">
				<div className="capatcha">
					 <ReCAPTCHA ref={e => (this.captcha = e)} sitekey={Constant._SITE_RECAPTCHA_SITE_KEY} onChange={(value) => { this.setState({ 'g-recaptcha-response': value }); }} />
				</div>
				</div>

				<div className="form-submit-button otp_btn">
					<button type="submit"> Cancel </button>
					<button type="submit"> Submit </button>
				</div>
				
			</form>

		</div>

	</div>
	
</div>

</div>


  <Footer/>
  </div>
    </>
  );
}

}
export default Change_password;