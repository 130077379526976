import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import FileUploadWithPreview from 'file-upload-with-preview'

const $ = window.$;

class Company_profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.state = {file: '',imagePreviewUrl: ''};
  }
 

_handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
    console.log('handle uploading-', this.state.file);
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  handleChange(checked) {
    this.setState({ checked });
  }




	componentDidMount(){

}

render() {

 let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} />);
    } else {
      $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
    }


  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing myprofile_form">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh yadav</p></div>
</div>

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
            <form action="">
              <div className="form-submit-button">
                <button className="cancel_btn" type="submit"> Cancel </button>
                <button className="save_btn" type="submit"> Save </button>
              </div>
            </form>
            </div>
        </div>

<div className="form-container mt-65">

<div className="inner-form-container container">
<div className="all-form-width">

<div className="signup-form-homepage">
<h1 className="main_title">
           Comapany Profile
        </h1>


<p className="form_heading_lebel">Compony Details</p>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Company Name*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Communications</option>
<option value="2">Technology</option>
<option value="3">Government/Military</option>
<option value="4">Manufacturing</option>
<option value="5">Financial Services</option>
</select>

<label>Organisation Type*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Phone*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Fax*</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">EST</option>
<option value="2">PST</option>
<option value="3">CST</option>
</select>

<label>TimeZone*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">USA</option>
<option value="2">England</option>
<option value="3">India</option>
</select>

<label>Country*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Website</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Comapny's Linkedin Profile</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Comapany's Twiiter Profile</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Company's Facebook Profile</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>About Comapny</label>
<span>(Max allowed: 350) </span>
</div>
</div>


<div className="form-inner-box upload_section">

<div className="upload_section">   
<input id="uploadFile" placeholder="Upload..."  className="f-input" />
<div className="fileUpload btn btn--browse">
    <span>Browse</span>
    <input id="uploadBtn" type="file"  className="upload" />
</div>
</div>

</div>


<div className="form-inner-box upload_section">


<div className="previewComponent">
        <div className="imgPreview">
          {$imagePreview}
        </div>
        <form onSubmit={(e)=>this._handleSubmit(e)}>
          <input className="fileInput" 
            type="file" 
            onChange={(e)=>this._handleImageChange(e)} />
          <button className="submitButton" 
            type="submit" 
            onClick={(e)=>this._handleSubmit(e)}>Upload Image</button>
        </form>

      </div>

</div>





<p className="form_heading_lebel">Billing Deatils </p>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">USA</option>
<option value="2">England</option>
<option value="3">India</option>
</select>
<label>Country*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">Alabama</option>
<option value="2">Hawaii</option>
<option value="3">Massachusetts</option>
<option value="4">Mississippi</option>
<option value="5">New Jersey</option>
<option value="5">South Carolina</option>
<option value="5">Texas</option>
</select>
<label>State/Province*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>City*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Zip/Postal Code*</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Billing Contact Person Name *</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Billing Contact Person Email *</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Billing Contact Person Phone *</label>
</div>
</div>




<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" >
<option value=""></option>
<option value="1">State Goods and Services Tax (SGST)</option>
<option value="2">Central Goods and Services Tax (CGST)</option>
<option value="3">Union Territory Goods and Services Tax (UTGST)</option>
<option value="4">Integrated Goods and Services Tax (IGST)</option>
</select>

<label>GST Registration Category</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>GST Number</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>GST ARN Number</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>GST Registered State</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>Address</label>
</div>
</div>


<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>
<label>PIN</label>
</div>
</div>

<form action="">

<div className="form-submit-button mt-50">
<button className="cancel_btn" type="submit"> Cancel </button>
<button className="save_btn" type="submit"> Save </button>

</div>

</form>

</div>

</div>

</div>

</div>

</div>

        <Footer/>
  </div>
    </>
  );
}

}
export default Company_profile;