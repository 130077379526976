import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import classNames from 'classnames'
import Review from './../Pop_up/Review'
import Attachment from './../Pop_up/Attachment'
// import Notes_pop from './../Pop_up/Notes_pop'
import Notepop from './../Pop_up/Notepop'
import Tag_pop from './../Pop_up/Tag_pop'
import Email_pop from './../Pop_up/Email_pop'
import Review_pop from './../Pop_up/Review_pop'
import Submit_client_pop from './../Pop_up/Submit_client_pop'
import Interview_pop from './../Pop_up/Interview_pop'
import Choose_job from './../Pop_up/Choose_job'
import Attachment_pop from './../Pop_up/Attachment_pop'
import Associated_tag from './../Pop_up/Associated_tag'
import Update_status from './../Pop_up/Update_status'
import Notes from './../Component/Section/Notes.js'

const $ = window.$;

class Candidate_detail_page extends React.Component {


  constructor(){
    super();
     this.state = {
      showMenu: false,
      showBlock: false
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.showMenu = this.showMenu.bind(this);
    // this.showBlock = this.showBlock.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    // this.closeBlock = this.closeBlock.bind(this);
  }

  addClass() {
    this.setState({
      showBlock:true
    });
  }

  removeClass() {
    this.setState({
      showBlock:false
    });
  }

showMenu(event,id,hideOnExternalClick=true) {
    event.preventDefault();
    
    this.setState({ showMenu: id }, () => {
      if(hideOnExternalClick)
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

    closeBlock(id) {
    this.setState({ showMenu: id }, () => {
      document.removeEventListener('click', this.closeBlock);
    });
  }

	componentDidMount(){
}

render() {
  return (
    <>
  
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing candidate_page mt-50">
<div className="container-fluid">
<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left_fix_side side_menu">
<div className="main_side_menu">
<ul className="sidemenu_list">
<li className="active_side_menu"><em className="timeline">Timeline</em> <span className="last_update">Last Updated: 5 Days ago</span></li>
<li className="active_side_menu">Associated Job Opening <em className="circle"> 1 </em> <span><i className="fa fa-plus"></i></span></li>
<li><strong>RELATED INFORMATION</strong></li>
{/* <li className="active_side_menu">Notes <em className="circle"> 2 </em> <span><i className="fa fa-plus" onClick={this.addClass}></i></span></li> */}
<li className="active_side_menu"> <a href="#Notes"> Notes </a> <em className="circle"> 2 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Review">  Ratings and Reviews  </a><em className="circle"> 3 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Attachment">  Attachment  </a><em className="circle"> 4 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Interview">  Interviews  </a><em className="circle"> 1 </em> <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Email"> Emails   </a>   <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Client_Sub">  Client Submission   </a>  <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu"> <a href="#Assessment">  Answered Assessment  </a>  <span><i className="fa fa-plus"></i></span></li>

<li><strong>TO-DOs</strong></li>

<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Tasks   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Events   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Call   </li>

<li>  <a href="#ASSOCIATED_TAG">  <strong>ASSOCIATED TAGS </strong>  </a>    <span><i className="fa fa-plus"></i></span> </li>


</ul>
</div>
</div>
<div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 right_fix_side side_content_full">
<div className="main_fix_section">
<div className="cnadidate_detail_section">
<div className="candidate_info_sec">

<div className="candidate_pro_pic">
<img src="/../images/profile_.png" alt=""/>
<div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div>
</div>

<div className="candidate_infoomation">
<div className="cand_info"><div className="candiadte_name">
<a href="" className="h1_name">Mr David Smith</a>
<span className="fa fa-file-pdf-o" title="Download Resume"></span></div></div>
<div className="all_icon_list_row all_sup_icon candid_icon">
<i className="fa fa-file-archive" title="Archieve"></i>
<i className="fa fa-refresh" aria-hidden="true" title="Refresh"></i>

<Notepop/>
<Attachment_pop/>
<Review_pop/>
{/* <i className="fa fa-comment" aria-hidden="true" title="Comment and Rating"></i> */}
{/* <i className="fa fa-envelope-o" aria-hidden="true" title="Email Candidate"></i> */}
<Email_pop/>
<Tag_pop/>
<Choose_job/>
<Submit_client_pop/>
<Interview_pop/>
{/* <i className="fa fa-tag" aria-hidden="true" title="Add Tag"></i> */}
{/* <i className="fa fa-briefcase" aria-hidden="true" title="Associate Job"></i> */}
{/* <i className="fa fa-upload" aria-hidden="true" title="JD Preview"></i>
<i className="fa fa-download" aria-hidden="true" title="JD Preview"></i> */}
{/* <i className="fa fa-user-plus" aria-hidden="true" title="Submit to Client"></i> */}
{/* <i className="fa fa-calendar-plus-o" aria-hidden="true" title="Schedule Interview"></i> */}
<i className="fa fa-reorder" aria-hidden="true" title="Other Actions"></i>

</div>

<div className="candidate_social_icon">
<i className="fa fa-linkedin-square"></i>
<i className="fa fa-twitter-square"></i>
<i className="fa fa-facebook-square"></i>
</div>

<div className="candidate_converted">
<span>Converted-Employee </span>
</div>
<Update_status/>

</div>

<div className="Company_del_sec">
<span className="company_na_me">Infosys Public Services </span>,<span className="company_add">Rockville, Maryland</span>
</div>

</div>
</div>
{/* fixed */}

<div className="profile_section_start">
<div className="profile_medotry">

<div className="main_line_edit">
<div className="main_title_sec">Email</div>
             {
          this.state.showMenu!=1
            ? (
              <>
<div className="main_title_sec">primaryemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'1',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>

</>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==1
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'1')} className="cancel_btn sup_btn" type="submit"> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Secondary Email</div>
             {
          this.state.showMenu!=2
            ? (
              <>
<div className="main_title_sec">secondaryemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'2',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==2
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'2')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Mobile</div>
             {
          this.state.showMenu!=3
            ? (
              <>
<div className="main_title_sec">+1-9098909890</div>
<div onClick={(e)=>this.showMenu(e,'3',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==3
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'3')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Skype</div>
             {
          this.state.showMenu!=4
            ? (
              <>
<div className="main_title_sec">skypeemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'4',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==4
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'4')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Experience in years</div>
             {
          this.state.showMenu!=5
            ? (
              <>
<div className="main_title_sec">6</div>
<div onClick={(e)=>this.showMenu(e,'5',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==5
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'5')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Current Employer</div>
             {
          this.state.showMenu!=6
            ? (
              <>
<div className="main_title_sec">Infosis prviate services</div>
<div onClick={(e)=>this.showMenu(e,'6',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==6
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'6')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Current job title</div>
             {
          this.state.showMenu!=7
            ? (
              <>
<div className="main_title_sec">Product owner</div>
<div onClick={(e)=>this.showMenu(e,'7',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==7
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'7')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="hide_block"><span id="hide_sh_element">Hide Detail <i className="fa fa-chevron-up"></i></span></div>

</div>
</div>


<div id="hide_id" className="">
<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Basic Detail </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Candidate Id</div>
<div className="main_title_sec">12345</div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Primary Email</div>
             {
          this.state.showMenu!=8
            ? (
              <>
<div className="main_title_sec">primaryemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'8',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==8
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'8')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Name</div>
             {
          this.state.showMenu!=9
            ? (
              <>
<div className="main_title_sec">David</div>
<div onClick={(e)=>this.showMenu(e,'9',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==9
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'9')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Secondary Email</div>
             {
          this.state.showMenu!=10
            ? (
              <>
<div className="main_title_sec">secondaryemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'10',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==10
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'10')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Profile Type</div>
             {
          this.state.showMenu!=11
            ? (
              <>
<div className="main_title_sec">Public</div>
<div onClick={(e)=>this.showMenu(e,'11',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==11
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'11')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Skype</div>
             {
          this.state.showMenu!=12
            ? (
              <>
<div className="main_title_sec">skypeemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'12',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==12
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'12')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Facebook</div>
             {
          this.state.showMenu!=13
            ? (
              <>
<div className="main_title_sec">facebookemail@gmail.com</div>
<div onClick={(e)=>this.showMenu(e,'13',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==13
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'13')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Email Opt Out</div>
<div className="main_title_sec">No</div>
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Profile Owner</div>
             {
          this.state.showMenu!=14
            ? (
              <>
<div className="main_title_sec">Mr. Samul Roy</div>
<div onClick={(e)=>this.showMenu(e,'14',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==14
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'14')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Mobile</div>
             {
          this.state.showMenu!=15
            ? (
              <>
<div className="main_title_sec">+1-909890987</div>
<div onClick={(e)=>this.showMenu(e,'15',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==15
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'15')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Last Namee</div>
             {
          this.state.showMenu!=16
            ? (
              <>
<div className="main_title_sec">Smith</div>
<div onClick={(e)=>this.showMenu(e,'16',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==16
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'16')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Phone</div>
             {
          this.state.showMenu!=17
            ? (
              <>
<div className="main_title_sec">+1-909890987</div>
<div onClick={(e)=>this.showMenu(e,'17',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==17
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'17')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Profile Source</div>
             {
          this.state.showMenu!=18
            ? (
              <>
<div className="main_title_sec">Public</div>
<div onClick={(e)=>this.showMenu(e,'18',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==18
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'18')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">linkedin</div>
             {
          this.state.showMenu!=19
            ? (
              <>
<div className="main_title_sec">www.linkedin.com/profile/name</div>
<div onClick={(e)=>this.showMenu(e,'19',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==19
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'19')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Twitter</div>
             {
          this.state.showMenu!=20
            ? (
              <>
<div className="main_title_sec">#dummyprofile</div>
<div onClick={(e)=>this.showMenu(e,'20',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==20
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'20')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>



</div>

{/* row end */}

</div>
</div>



<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Professional Detail </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Relevent Experience (years)</div>
             {
          this.state.showMenu!=21
            ? (
              <>
<div className="main_title_sec">4</div>
<div onClick={(e)=>this.showMenu(e,'21',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==21
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'21')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Current Job Title</div>
             {
          this.state.showMenu!=22
            ? (
              <>
<div className="main_title_sec">Product Manger</div>
<div onClick={(e)=>this.showMenu(e,'22',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==22
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'22')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Notice Period</div>
             {
          this.state.showMenu!=23
            ? (
              <>
<div className="main_title_sec">David</div>
<div onClick={(e)=>this.showMenu(e,'23',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==23
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'23')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Expected Salary($)</div>
             {
          this.state.showMenu!=24
            ? (
              <>
<div className="main_title_sec">80k</div>
<div onClick={(e)=>this.showMenu(e,'24',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==24
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'24')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Work Auth/Visa</div>
             {
          this.state.showMenu!=25
            ? (
              <>
<div className="main_title_sec">H1B</div>
<div onClick={(e)=>this.showMenu(e,'25',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==25
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'25')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Highest Qualification</div>
             {
          this.state.showMenu!=26
            ? (
              <>
<div className="main_title_sec">Master's Degree</div>
<div onClick={(e)=>this.showMenu(e,'26',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==26
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'26')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Total Experience (Years)</div>
             {
          this.state.showMenu!=27
            ? (
              <>
<div className="main_title_sec">4</div>
<div onClick={(e)=>this.showMenu(e,'27',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==27
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'27')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Current Employer</div>
             {
          this.state.showMenu!=28
            ? (
              <>
<div className="main_title_sec">Wipro</div>
<div onClick={(e)=>this.showMenu(e,'28',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==28
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'28')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Current Salary($)</div>
             {
          this.state.showMenu!=29
            ? (
              <>
<div className="main_title_sec">50k</div>
<div onClick={(e)=>this.showMenu(e,'29',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==29
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'29')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Contract Type</div>
             {
          this.state.showMenu!=30
            ? (
              <>
<div className="main_title_sec">1099</div>
<div onClick={(e)=>this.showMenu(e,'30',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==30
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'30')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Prefered Job Type</div>
             {
          this.state.showMenu!=31
            ? (
              <>
<div className="main_title_sec">Full Time</div>
<div onClick={(e)=>this.showMenu(e,'31',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==31
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'31')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>




</div>

{/* row end */}


<div className="main_line_edit full_width">
<div className="main_title_sec">Skills</div>
             {
          this.state.showMenu!=32
            ? (
              <>
<div className="main_title_sec">Communication and interpersonal skills, Presentation skills, Customer service skills, Decision making</div>
<div onClick={(e)=>this.showMenu(e,'32',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==32
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'32')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>
</div>





<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Address Detail </p>

<div className=" double_row">

<p className="inner_heading">Current Location</p>

<div className="main_line_edit">
<div className="main_title_sec">Country</div>
             {
          this.state.showMenu!=33
            ? (
              <>
<div className="main_title_sec">USA</div>
<div onClick={(e)=>this.showMenu(e,'33',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==33
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'33')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">State</div>
             {
          this.state.showMenu!=34
            ? (
              <>
<div className="main_title_sec">Mississippi</div>
<div onClick={(e)=>this.showMenu(e,'34',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==34
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'34')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">City</div>
             {
          this.state.showMenu!=35
            ? (
              <>
<div className="main_title_sec">Gulfport</div>
<div onClick={(e)=>this.showMenu(e,'35',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==35
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'35')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Zip</div>
             {
          this.state.showMenu!=36
            ? (
              <>
<div className="main_title_sec">39501</div>
<div onClick={(e)=>this.showMenu(e,'36',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==36
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'36')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Street</div>
             {
          this.state.showMenu!=37
            ? (
              <>
<div className="main_title_sec">822 Coventry Court</div>
<div onClick={(e)=>this.showMenu(e,'37',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==37
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'37')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}

<div className=" double_row">

<p className="inner_heading">Current Location</p>

<div className="main_line_edit">
<div className="main_title_sec">State</div>
             {
          this.state.showMenu!=38
            ? (
              <>
<div className="main_title_sec">Mississippi</div>
<div onClick={(e)=>this.showMenu(e,'38',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==38
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'38')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">City</div>
             {
          this.state.showMenu!=39
            ? (
              <>
<div className="main_title_sec">Gulfport</div>
<div onClick={(e)=>this.showMenu(e,'39',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==39
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'39')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Zip</div>
             {
          this.state.showMenu!=40
            ? (
              <>
<div className="main_title_sec">39501</div>
<div onClick={(e)=>this.showMenu(e,'40',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==40
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'40')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Ready to Replace</div>
             {
          this.state.showMenu!=41
            ? (
              <>
<div className="main_title_sec">1099</div>
<div onClick={(e)=>this.showMenu(e,'41',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==41
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'41')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

</div>
</div>




<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Educational Detail </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Qualification Level</div>
             {
          this.state.showMenu!=42
            ? (
              <>
<div className="main_title_sec">Masters Degree</div>
<div onClick={(e)=>this.showMenu(e,'42',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==42
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'42')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Course Type</div>
             {
          this.state.showMenu!=43
            ? (
              <>
<div className="main_title_sec">Full Time</div>
<div onClick={(e)=>this.showMenu(e,'43',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==43
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'43')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Batch Start</div>
             {
          this.state.showMenu!=44
            ? (
              <>
<div className="main_title_sec">feb 2020</div>
<div onClick={(e)=>this.showMenu(e,'44',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==44
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'44')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Degree</div>
             {
          this.state.showMenu!=45
            ? (
              <>
<div className="main_title_sec">MCA</div>
<div onClick={(e)=>this.showMenu(e,'45',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==45
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'45')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Institude/School</div>
             {
          this.state.showMenu!=46
            ? (
              <>
<div className="main_title_sec">Indian Institude of technology</div>
<div onClick={(e)=>this.showMenu(e,'46',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==46
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'46')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Batch End</div>
             {
          this.state.showMenu!=47
            ? (
              <>
<div className="main_title_sec">apr. 2020</div>
<div onClick={(e)=>this.showMenu(e,'47',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==47
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'47')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

</div>
</div>


<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Certification </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Certification Name</div>
             {
          this.state.showMenu!=48
            ? (
              <>
<div className="main_title_sec">Java Delevelopment Progrmaing</div>
<div onClick={(e)=>this.showMenu(e,'48',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==48
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'48')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Licence Number</div>
             {
          this.state.showMenu!=49
            ? (
              <>
<div className="main_title_sec">768598</div>
<div onClick={(e)=>this.showMenu(e,'49',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==49
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'49')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Valid Till</div>
             {
          this.state.showMenu!=50
            ? (
              <>
<div className="main_title_sec">feb 2023</div>
<div onClick={(e)=>this.showMenu(e,'50',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==50
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'50')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Issuing Body</div>
             {
          this.state.showMenu!=51
            ? (
              <>
<div className="main_title_sec">NASSCOM</div>
<div onClick={(e)=>this.showMenu(e,'51',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==51
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'51')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Completion Period</div>
             {
          this.state.showMenu!=52
            ? (
              <>
<div className="main_title_sec">Apr., 2020</div>
<div onClick={(e)=>this.showMenu(e,'52',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==52
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'52')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Validity</div>
             {
          this.state.showMenu!=53
            ? (
              <>
<div className="main_title_sec">Yes</div>
<div onClick={(e)=>this.showMenu(e,'53',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==53
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'53')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

</div>
</div>




<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Work Experience </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Job Title</div>
             {
          this.state.showMenu!=54
            ? (
              <>
<div className="main_title_sec">Software Developer</div>
<div onClick={(e)=>this.showMenu(e,'54',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==54
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'54')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">is Current Employer</div>
             {
          this.state.showMenu!=55
            ? (
              <>
<div className="main_title_sec">Yes</div>
<div onClick={(e)=>this.showMenu(e,'55',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==55
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'55')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Work End </div>
             {
          this.state.showMenu!=56
            ? (
              <>
<div className="main_title_sec">Apr. 2020</div>
<div onClick={(e)=>this.showMenu(e,'56',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==56
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'56')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Company</div>
             {
          this.state.showMenu!=57
            ? (
              <>
<div className="main_title_sec">Techvilla Solutions</div>
<div onClick={(e)=>this.showMenu(e,'57',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==57
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'57')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Work Start</div>
             {
          this.state.showMenu!=58
            ? (
              <>
<div className="main_title_sec">sep, 2019</div>
<div onClick={(e)=>this.showMenu(e,'58',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==58
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'58')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Current Job Type</div>
             {
          this.state.showMenu!=59
            ? (
              <>
<div className="main_title_sec">Parmanent</div>
<div onClick={(e)=>this.showMenu(e,'59',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==59
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'59')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}


<div className="main_line_edit full_width">
<div className="main_title_sec">Roles and Responsibilties</div>
             {
          this.state.showMenu!=60
            ? (
              <>
<div className="main_title_sec">Communication and interpersonal skills, Presentation skills, Customer service skills, Decision making</div>
<div onClick={(e)=>this.showMenu(e,'60',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==60
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'60')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>
</div>


<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Project Detail </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Priject Name</div>
             {
          this.state.showMenu!=61
            ? (
              <>
<div className="main_title_sec">Blog Website</div>
<div onClick={(e)=>this.showMenu(e,'61',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==61
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'61')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Project Start</div>
             {
          this.state.showMenu!=62
            ? (
              <>
<div className="main_title_sec">Apr. , 2019</div>
<div onClick={(e)=>this.showMenu(e,'62',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==62
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'62')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Currently on going </div>
             {
          this.state.showMenu!=63
            ? (
              <>
<div className="main_title_sec">No</div>
<div onClick={(e)=>this.showMenu(e,63,false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==63
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'63')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Client</div>
             {
          this.state.showMenu!=64
            ? (
              <>
<div className="main_title_sec">Techvilla Solutions</div>
<div onClick={(e)=>this.showMenu(e,'64',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==64
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'64')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Project End</div>
             {
          this.state.showMenu!=65
            ? (
              <>
<div className="main_title_sec">sep, 2019</div>
<div onClick={(e)=>this.showMenu(e,'65',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==65
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'65')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Your Role</div>
             {
          this.state.showMenu!=66
            ? (
              <>
<div className="main_title_sec">Web Designer</div>
<div onClick={(e)=>this.showMenu(e,'66',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==66
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'66')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>

{/* row end */}


<div className="main_line_edit full_width">
<div className="main_title_sec">Tools and Technology Used</div>
             {
          this.state.showMenu!=67
            ? (
              <>
<div className="main_title_sec">Communication and interpersonal skills, Presentation skills, Customer service skills, Decision making</div>
<div onClick={(e)=>this.showMenu(e,'67',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==67
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'67')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit full_width">
<div className="main_title_sec">Your Responsibilty</div>
             {
          this.state.showMenu!=68
            ? (
              <>
<div className="main_title_sec">Communication and interpersonal skills, Presentation skills, Customer service skills, Decision making</div>
<div onClick={(e)=>this.showMenu(e,'68',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==68
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'68')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit full_width">
<div className="main_title_sec">Project Detail</div>
             {
          this.state.showMenu!=69
            ? (
              <>
<div className="main_title_sec">Communication and interpersonal skills, Presentation skills, Customer service skills, Decision making</div>
<div onClick={(e)=>this.showMenu(e,'69',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==69
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'69')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

</div>
</div>


<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Reference </p>

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Reference Name</div>
             {
          this.state.showMenu!=70
            ? (
              <>
<div className="main_title_sec">Azad Hussain</div>
<div onClick={(e)=>this.showMenu(e,'70',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==70
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'70')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Title</div>
             {
          this.state.showMenu!=71
            ? (
              <>
<div className="main_title_sec">Web Developer</div>
<div onClick={(e)=>this.showMenu(e,'71',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==71
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'71')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>

<div className="main_line_edit">
<div className="main_title_sec">Email</div>
             {
          this.state.showMenu!=72
            ? (
              <>
<div className="main_title_sec">feb 2023</div>
<div onClick={(e)=>this.showMenu(e,'72',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==72
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'72')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">relation</div>
             {
          this.state.showMenu!=73
            ? (
              <>
<div className="main_title_sec">Friend</div>
<div onClick={(e)=>this.showMenu(e,'73',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==73
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'73')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Company</div>
             {
          this.state.showMenu!=74
            ? (
              <>
<div className="main_title_sec">Techvilla</div>
<div onClick={(e)=>this.showMenu(e,'74',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==74
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'74')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


<div className="main_line_edit">
<div className="main_title_sec">Mobile</div>
             {
          this.state.showMenu!=75
            ? (
              <>
<div className="main_title_sec">+1-898098909</div>
<div onClick={(e)=>this.showMenu(e,'75',false)}  className="main_title_sec" id=""><i className="fa fa-pencil"></i></div></>
     )
            : (
              null
            )
        } 


               {
          this.state.showMenu==75
            ? (
              <>
  <div className="main_title_sec">
    <input type="text"/>
  <div className="main_title_sec hide_item_section">
    <form action="">
      <div className="client_to hide_item">
        <div className="form-submit-button">
         <button className="save_btn sup_btn" type="submit"> Save</button>
          <button onClick={(e)=>this.removeClass(e,'75')} className="cancel_btn sup_btn" type=""> <i className="fa fa-times"></i> </button>
        </div>
      </div>
    </form>
  </div>
 </div>
  </>
       )
            : (
              null
            )
        } 
</div>


</div>

{/* row end */}

</div>
</div>


</div>

{/* basic detail */}

{/* <div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Attach Document </p>

<div className="interview_section">
<table className="table-bordered table">

<thead>
  <tr>

    <th>Document Type</th>
    <th>Document Name</th>
    <th>Uploaded On</th>
    <th>Uploaded By</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download</span> / <span>Delete</span></td>
  </tr>

</tbody>
</table>
</div>
</div>
</div> */}

{/* Notes */}

<div className="profile_section_start nextroww pos_rel" id="Notes" >
<Notes/>
</div>


{/* end of notes */}


<div className="profile_section_start nextroww pos_rel" id="Review" >
<div className="profile_medotry">

<p className="block_heading">Ratings and Reviews 
<Review/>
 {/* <button className="rating_select" id="">Submit Review</button> */}
<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Gernal</option> 
<option>Job Based</option> 
<option>Pre Screening</option> 
<option>Behaviour Screening</option> 
<option>Background Screening</option> 
<option>Background Interview</option> 
</select> 

</span>
 

 </p>



<div className="all_notes_section">
<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span> made a <span>Job Based Review</span> on Apr 10 , 2020 12:40 PM
<i className="fa fa-trash-o"></i><i className="fa fa-pencil"></i></div>
</div>

<div className="rating_review">

<div className="raing_section">
<div className="left_rev_rating">
<span>Overall Rating</span>
</div>
<div className="right_side_rev_rating">
<span>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star fa-active"></i>
<i className="fa fa-star"></i>
</span> |

 <span>Excellent</span>
</div>
</div>

<div className="raing_section">
<div className="left_rev_rating">
<span>Overall Comment</span>
</div>
<div className="right_side_rev_rating">
 <span>Excellent fit for job</span>
</div>
</div>

<div className="raing_section">
<div className="left_rev_rating">
<span>Job Opening Name</span>
</div>
<div className="right_side_rev_rating">
 <span><a href="">Product Analyst</a></span>
</div>
</div>



</div>

</div>

</div>
</div>
</div>

{/* review rating */}


<div className="profile_section_start nextroww pos_rel" id="Interview" >
<div className="profile_medotry">

<p className="block_heading">Interview 

<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Upcomimg</option> 
<option>Overdue</option> 
<option>concluded</option>
</select> 

</span>
 

 <button className="rating_select interv" id="interview_pop_up"><Link to="/Interview_form" target="_blank"><i className="fa fa-plus"></i> Schedule Interview</Link></button>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div>




 </div>
 </div>

{/* Interview */}



<div className="profile_section_start nextroww pos_rel" id="Email" >
<div className="profile_medotry">

<p className="block_heading">Email 


<div className="email_infosec">
<span>Sent By</span>
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value="">Sanjeev.gem</option>
</select>

<label>User</label>
</div>
</div>


<span>To</span>
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select" onClick="this.setAttribute('value', this.value);">
<option value="">chrish.grm@gmail.com</option>
</select>

<label>Candidate</label>
</div>
</div>


</div>


<span className="sort_ing rating_sort">

<select> 
<option>All</option> 
<option>Offical Email</option> 
<option>Automated Email</option> 
<option>Bounced Email</option>
</select> 

</span>
 
<form action="/email">
 <button className="rating_select interv" id="interview_pop_up"><Link to="/email" target="_blank"><i className="fa fa-plus"></i> Send Email </Link> </button>
 </form>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>

<th>To</th>
<th>Subject</th>
<th>Date</th>
<th>From</th>
<th>Status</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr>
<td>C.devprakash.gmail.com</td>
<td>Interview Detail For Product Manager</td>
<td>Mar 20 , 2020</td>
<td>sanjeev.gem@gemail.com</td>
<td>Bounced</td>
<td><div className="list_action"><Link to=""><i className="fa fa-envelope-open-o" title="Sent"></i></Link>|<Link to=""><i className="fa fa-trash-o"></i></Link></div></td>
</tr>
</tbody>

</table>
</div>




 </div>
 </div>

{/* client submit */}

<div className="profile_section_start nextroww pos_rel" id="Client_Sub" >
<div className="profile_medotry">

<p className="block_heading">Client Submit 
 <button className="rating_select interv" id="interview_pop_up"> 
 <Link to="/client_submit" target="_blank"><i className="fa fa-plus"></i> Submit to Client</Link></button>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>

<th>Candidate Selected</th>
<th>Against a Job</th>
<th>Date</th>
<th>Client Name</th>
<th>From</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr>
<td>C.devprakash.gmail.com</td>
<td>Java Developer</td>
<td>Mar 20 , 2020</td>
<td>Sanjeev</td>
<td>sanjeev.gem@gemail.com</td>
<td><div className="list_action"><Link to=""><i className="fa fa-envelope-open-o" title="Sent"></i></Link>|<Link to=""><i className="fa fa-trash-o"></i></Link></div></td>
</tr>
</tbody>

</table>
 </div>
{/* <div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div> */}




 </div>
 </div>

{/* Interview */}



{/* assessment */}

<div className="profile_section_start nextroww pos_rel" id="Assessment" >
<div className="profile_medotry">

<p className="block_heading">Answered Assessments

 

 <button className="rating_select interv" id="interview_pop_up"></button>
 </p>
<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>

<th>Test Name</th>
<th>Qusetion</th>
<th>Duration (Min)</th>
<th>Client Name</th>
<th>Invite</th>
<th>Status</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr>
<td>Screening Assessment</td>
<td>1</td>
<td>40</td>
<td>sanjeev</td>
<td><div className="assement"><Link to="">0 Sent</Link> | <Link to="">0 Complete</Link></div></td>
<td>Draft</td>
<td><div className="list_action"><Link to=""><i className="fa fa-envelope-open-o" title="Sent"></i></Link>|<Link to=""><i className="fa fa-trash-o"></i></Link></div></td>
</tr>
</tbody>

</table>
 </div>
{/* <div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div> */}




 </div>
 </div>

{/* end of assessment */}



{/* attachment */}

<div className="profile_section_start nextroww" id="Attachment" >
<div className="profile_medotry">

<p className="block_heading">Attachments

 

 <button className="rating_select interv" id="interview_pop_up">         
    <div className="notes_upload">
      <div className="upload_section">   
        <div className="fileUpload btn btn--browse">
           <Attachment/> 
        </div>
      </div>
    </div> 
  </button>
 </p>
<div className="interview_section">
<table className="table-bordered table">

<thead>
  <tr>

    <th>Document Type</th>
    <th>Document Name</th>
    <th>Uploaded On</th>
    <th>Uploaded By</th>
    <th>Action</th>
  </tr>
  </thead>
  <tbody>
  <tr>

    <td>Resume</td>
    <td><a href="">Dev Ray.docx</a></td>
    <td><span>apr 05,2020 10:30</span></td>
    <td><a href="">Recruiter@gmail.com</a></td>
    <td><span>Download</span> / <span>Delete</span></td>
  </tr>

</tbody>
</table>
</div>
{/* <div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div> */}




 </div>
 </div>

{/* end of attachment */}



{/* attachment */}

<div className="profile_section_start nextroww" id="ASSOCIATED TAGS" >
<div className="profile_medotry">

<p className="block_heading">Associated Tags

 

 <button className="rating_select interv" id="interview_pop_up">         
    <div className="notes_upload">
      <div className="upload_section">   
        <div className="fileUpload btn btn--browse">
           <Associated_tag/> 
        </div>
      </div>
    </div> 
  </button>
 </p>
<div className="interview_section">
  <div class="react-tags__selected" aria-live="polite" aria-relevant="additions removals">
    <button type="button" class="react-tags__selected-tag" title="Click to remove tag">
      <span class="react-tags__selected-tag-name">Offer <i className="fa fa-times"></i></span>
    </button>
    <button type="button" class="react-tags__selected-tag" title="Click to remove tag">
      <span class="react-tags__selected-tag-name">Active <i className="fa fa-times"></i> </span>
    </button>
  </div>
</div>
{/* <div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Interview Name</th>
<th>From</th>
<th>To</th>
<th>Candidate Name</th>
</tr>
</thead>
<tbody>
<tr>
<td>Gerenal Interview</td>
<td>Mar 20 , 2020</td>
<td>Mar 22 , 2020</td>
<td>Christ</td>
</tr>
</tbody>

</table>
<div className="edit_deltee">
<i className="fa fa-history"></i>
<i className="fa fa-trash-o"></i>
</div>
</div> */}




 </div>
 </div>

{/* end of attachment */}





</div>
</div>
</div>


  </div>

          <Footer/>
  </div>
    </>
  );
}

}
export default Candidate_detail_page;