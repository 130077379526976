import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Notes_pop from './../Pop_up/Notes_pop'
import Tag_pop from './../Pop_up/Tag_pop'
import Email_pop from './../Pop_up/Email_pop'
import Review_pop from './../Pop_up/Review_pop'
import classNames from 'classnames'
import Submit_client_pop from './../Pop_up/Submit_client_pop'
import Interview_pop from './../Pop_up/Interview_pop'

  import Choose_job from './../Pop_up/Choose_job'
import Attachment_pop from './../Pop_up/Attachment_pop'
import Candidate_filter from './../Pop_up/Candidate_filter'

const $ = window.$;

class Candidate_listing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        sortby: 'asc',
        addClass: false}



  }


	componentDidMount(){

}
toggleMobileFilter =()=>{
  var element = document.getElementById("app_pr_engment_panel");
  element.classList.toggle("active");
}
sort=()=>{
(this.state.sortby=='asc')? this.setState({sortby:'desc'}) : this.setState({sortby:'asc'});
}
render() {
    let boxClass = ["box"];
    if(this.state.addClass) {
      boxClass.push('green');
    }
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing ">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="listing_section_block_section  container">
<div className="job_list_section_block">

<div className="top_section_menu">
<div className="title_name_of_page">
    <h1>Candidate Listing</h1>
</div>
<div className="add_new_to_page">
    <span className="title_name_drop_down"><i className="fa fa-ellipsis-h"></i></span>
    <div className="list_show_drop_down">
<ul>
<li>xyz</li>
<li>abc</li>
</ul>
</div>
</div>
<div className="add_new_to_page">
    <span><Link to="/Candiadte_form"><i className="fa fa-plus"></i> Add New Candidate</Link></span>
</div>
    <div className="add_new_to_page">
        <span className="title_name_drop_down">
        <i className="fa fa-plus"></i> Import <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>Import From Excel</li>
            </ul>
        </div>
    </div>
</div>

<div className="top_page_filter_set">
<div className="search_filter_box">
<input type="text" name=""/><span><i className="fa fa-search"></i></span>
</div>
{/* <div className="mtd_filter_box">
<span> <i className="fa fa-calendar"></i><select><option>MTD</option><option>Yesterday</option><option>Last Week</option></select></span>
</div> */}
    <div className="add_new_to_page mtd_filter_box">
        <span className="title_name_drop_down">
        <i className="fa fa-calendar"></i> MTD <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>Yesterday</li>
                <li>Last Week</li>
            </ul>
        </div>
    </div>
{/* <div className="posted_on_filter">
<span><i className="fa fa-folder"></i><select><option>All Candidate</option>
<option>My Candidate</option><option>My Teams</option><option>Archieved</option></select></span>
</div> */}
    <div className="add_new_to_page mtd_filter_box">
        <span className="title_name_drop_down">
        <i className="fa fa-folder"></i> All Candidate <i className="fa fa-chevron-down"></i>
        </span>
        <div className="list_show_drop_down">
            <ul>
                <li>My Candidate</li>
                <li>My Teams</li>
                <li>Archieved</li>
            </ul>
        </div>
    </div>
<div className="main_filter_box">
<span><Candidate_filter/></span>
</div>
<div className="date_filter_box">
<span><i className="fa fa-sort-amount-desc"id="asc-desc" onClick={ ()=>this.sort() }>Sort asc</i> 
{
(this.state.sortby=='asc')? <div></div>: <div></div>
}
</span>

</div>

</div>

<div className="list_view_result_view">
<div className="left_side_result_view">
<span>Results</span><span className="list_count"> : 10</span>
</div>
<div className="filter_box">
<div className="filter_title">
<span>Applied Filter</span>
<div className="filter_result">
<div className="filter_single">
<span>Html</span><i className="fa fa-times"></i>
</div>
</div>
</div>
</div>

</div>
<div className="all_type_job_listing_hide">
<div className="all_job_listing_section-outer candiadate_listing_sec_box" id="main_grid_view">

<div className="all_job_listing_section-inner">
<div className="listing_top_row">
<div className="job_image_section">
<img src="./images/profile_.png" alt=""/>
</div>	
<div className="job_role top_left_side"><Link to="/candidate/candidate_detail">David Smith <span className="job_id_ref">(Front-End Developer/4-6 Yrs)</span></Link>
    <div className="social_icon"><i className="fa fa-facebook-square"></i></div>
    <div className="social_icon"><i className="fa fa-linkedin-square"></i></div>
    <div className="social_icon"><i className="fa fa-twitter-square"></i></div>
        <div className="company_address">
        <span className="company_name">Infosys Public Services,</span>
        <span className="company_location">  Rockville, Maryland</span>
    </div>
</div>
<div className="job_top top_right_side">
    <div className="job_status"><label for="status">Candidate </label> <span>Active</span></div>
    <div><Review_pop/>4.2(181 Reviews)</div>
    <div className="edit_job"><Link to=""><i className="fa fa-pencil"></i>Edit</Link></div>
</div>
</div>
<div className="job_mid_section">
<div className="job_main_detail">

    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Current Salary :
            </span>
            <span className="type_wor_auth_view">
                $30/hour
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Contact :
            </span>
            <span className="type_wor_auth_view">
                +1-909890989
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Profile-Owner :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr.samuri </a>
            </span>
        </div>
    </div>

    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Expected Salary :
            </span>
            <span className="type_wor_auth_view">
                $40/hour
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Work auth /Visa :
            </span>
            <span className="type_wor_auth_view">
                H1 B, L2 , EADHigh
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Contract Type :
            </span>
            <span className="type_wor_auth_view">
                W2
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Auth :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr. Dev Brown (3 Days Left)</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Prefered Job Type : 
            </span>
            <span className="type_wor_auth_view">
                Contract
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Status :
            </span>
            <span className="type_wor_auth_view">
                Public to all
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Source :
            </span>
            <span className="type_wor_auth_view">
                Dice
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Highest Qualification :
            </span>
            <span className="type_wor_auth_view">
                M.Tech
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Created On :
            </span>
            <span className="type_wor_auth_view">
            Feb 20, 2020
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
            Created by :
            </span>
            <span className="type_wor_auth_view">
            <a href="">Rakesh yadav</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Last Updated :
            </span>
            <span className="type_wor_auth_view">
            Apr. 1, 2020
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Updated by :
            </span>
            <span className="type_wor_auth_view">
            <a href="">Rakesh yadav</a>
            </span>
        </div>
    </div>


</div>

<div className="all_icon_list_row">

<div className="skill_section_listing">
<span className="type_wor_auth">Skills :</span>
<div className="skill_fill_section">
<span className="skill_set_listing">React Js</span>
<span className="skill_set_listing">Angular Js</span>
<span className="skill_set_listing">Node Js</span>
</div>
</div>

{/* <div className="post_last_update">
<span className="type_wor_auth">
Created On :
</span>
<span className="type_wor_auth_view">
Feb 20, 2020
</span>

<span className="type_wor_auth">
Created by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

<span className="type_wor_auth">
Last Updated :
</span>
<span className="type_wor_auth_view">
Apr. 1, 2020
</span>

<span className="type_wor_auth">
Updated by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

</div> */}

    <div className="post_last_update_con">
        {/* <div className="save_job"><i className="fa fa-calendar-o"></i>Conversation History</div> */}

        <div className="save_job"><i className="fa fa-tag"></i>View Tag</div>


    </div>
<div class="all_sup_icon">
<i className="fa fa-file" title="Archieve"></i>
<i className="fa fa-refresh" aria-hidden="true" title="Refresh"></i>
<Email_pop/>
<Notes_pop/>
<Tag_pop/>

<Choose_job/>
<Attachment_pop/>
<i className="fa fa-download" aria-hidden="true" title="Download"></i>
<Submit_client_pop/>
<Interview_pop/>
<i className="fa fa-reorder" aria-hidden="true" title="Other Actions"></i>
</div>
</div>

<div className="buttom_job_list_section ">
<div className="app_pr_engment">
<div className="app_pr_engment_flip">
    <span className="app_pr_eng_name">Progressive Engagement:</span>
    <span className="app_pr_number">2/8</span>
    <span className="app_pr_eng_name">Progressive Time:</span>
    <span className="app_pr_number">9 Days</span>

    <span className="app_pr_team"><strong>Team </strong>: 3</span>
        <span id="app_pr_engment_flip" className={boxClass.join(' ')}  onClick={()=>this.toggleMobileFilter ()}>
        <i className="fa fa-chevron-down"> </i>
    </span>

</div>
<div className="app_pr__follow_up top_most_uper">
<div className="follow_up_internal">
<div className="internal_list active_yes">
<span className="list_job_status">
Active
</span>
    <span>Capgemini</span>
    <span>Front-End Development(7432)</span>
    <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Ready to Offer</span>
    </div>
</div>
</div>
<div className="follow_up_client ">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
    <div className="follow_up_offered ">
<div className="offered_list active_no active_frezed">
<span className="list_job_status">
Freezed 
</span>
    <span>Infoysis</span>
    <span>Web Development(4543)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client Interview</span>
    </div>
</div>
</div>

</div>

<div id="app_pr_engment_panel">
    
<div className="app_pr__follow_up">
<div className="follow_up_offered">
<div className="offered_list active_no active_frezed">
<span className="list_job_status">
Freezed 
</span>
    <span>Infoysis</span>
    <span>Web Development(4543)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client Interview</span>
    </div>
</div>
</div>
<div className="follow_up_client">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="internal_list active_yes">
<span className="list_job_status">
Active
</span>
    <span>Capgemini</span>
    <span>Front-End Development(7432)</span>
    <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Ready to Offer</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
    <div className="follow_up_offered">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
<div className="follow_up_accpted">

</div>
</div>
</div>

</div>
</div>
</div>

</div>

</div>

{/* new list */}

<div className="all_job_listing_section-outer candiadate_listing_sec_box" id="main_grid_view">

<div className="all_job_listing_section-inner">
<div className="listing_top_row">
<div className="job_image_section">
<img src="./images/profile_.png" alt=""/>
</div>	
<div className="job_role top_left_side"><Link to="/candidate/Candidate_detail_page">David Smith <span className="job_id_ref">(Front-End Developer/4-6 Yrs)</span></Link>
    <div className="social_icon"><i className="fa fa-facebook-square"></i></div>
    <div className="social_icon"><i className="fa fa-linkedin-square"></i></div>
    <div className="social_icon"><i className="fa fa-twitter-square"></i></div>
        <div className="company_address">
        <span className="company_name">Infosys Public Services,</span>
        <span className="company_location">  Rockville, Maryland</span>
    </div>
</div>
<div className="job_top top_right_side">
    <div className="job_status"><label for="status">Candidate </label> <span>Active</span></div>
    <div><Review_pop/>4.2(181 Reviews)</div>
    <div className="edit_job"><Link to=""><i className="fa fa-pencil"></i>Edit</Link></div>
</div>
</div>
<div className="job_mid_section">
<div className="job_main_detail">

    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Current Salary :
            </span>
            <span className="type_wor_auth_view">
                $30/hour
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Contact :
            </span>
            <span className="type_wor_auth_view">
                +1-909890989
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Profile-Owner :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr.samuri </a>
            </span>
        </div>
    </div>

    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Expected Salary :
            </span>
            <span className="type_wor_auth_view">
                $40/hour
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Work auth /Visa :
            </span>
            <span className="type_wor_auth_view">
                H1 B, L2 , EADHigh
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Contract Type :
            </span>
            <span className="type_wor_auth_view">
                W2
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Auth :
            </span>
            <span className="type_wor_auth_view">
                <a href="">Mr. Dev Brown (3 Days Left)</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Prefered Job Type : 
            </span>
            <span className="type_wor_auth_view">
                Contract
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Status :
            </span>
            <span className="type_wor_auth_view">
                Public to all
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
                Profile Source :
            </span>
            <span className="type_wor_auth_view">
                Dice
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
                Highest Qualification :
            </span>
            <span className="type_wor_auth_view">
                M.Tech
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Created On :
            </span>
            <span className="type_wor_auth_view">
            Feb 20, 2020
            </span>
        </div>
    </div>
    <div  className="job_type_auth_contact">
        <div className="job_type">
            <span className="type_wor_auth">
            Created by :
            </span>
            <span className="type_wor_auth_view">
            <a href="">Rakesh yadav</a>
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Last Updated :
            </span>
            <span className="type_wor_auth_view">
            Apr. 1, 2020
            </span>
        </div>
        <div className="job_type">
            <span className="type_wor_auth">
            Updated by :
            </span>
            <span className="type_wor_auth_view">
            <a href="">Rakesh yadav</a>
            </span>
        </div>
    </div>


</div>

<div className="all_icon_list_row">

<div className="skill_section_listing">
<span className="type_wor_auth">Skills :</span>
<div className="skill_fill_section">
<span className="skill_set_listing">React Js</span>
<span className="skill_set_listing">Angular Js</span>
<span className="skill_set_listing">Node Js</span>
</div>
</div>

{/* <div className="post_last_update">
<span className="type_wor_auth">
Created On :
</span>
<span className="type_wor_auth_view">
Feb 20, 2020
</span>

<span className="type_wor_auth">
Created by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

<span className="type_wor_auth">
Last Updated :
</span>
<span className="type_wor_auth_view">
Apr. 1, 2020
</span>

<span className="type_wor_auth">
Updated by :
</span>
<span className="type_wor_auth_view">
<a href="">Rakesh yadav</a>
</span>

</div> */}

    <div className="post_last_update_con">
        {/* <div className="save_job"><i className="fa fa-calendar-o"></i>Conversation History</div> */}

        <div className="save_job"><i className="fa fa-tag"></i>View Tag</div>


    </div>
<div class="all_sup_icon">
<i className="fa fa-file" title="Archieve"></i>
<i className="fa fa-refresh" aria-hidden="true" title="Refresh"></i>
<Email_pop/>
<Notes_pop/>
<Tag_pop/>

<Choose_job/>
<Attachment_pop/>
<i className="fa fa-download" aria-hidden="true" title="Download"></i>
<Submit_client_pop/>
<Interview_pop/>
<i className="fa fa-reorder" aria-hidden="true" title="Other Actions"></i>
</div>
</div>

<div className="buttom_job_list_section ">
<div className="app_pr_engment">
<div className="app_pr_engment_flip">
    <span className="app_pr_eng_name">Progressive Engagement:</span>
    <span className="app_pr_number">2/8</span>
    <span className="app_pr_eng_name">Progressive Time:</span>
    <span className="app_pr_number">9 Days</span>

    <span className="app_pr_team"><strong>Team </strong>: 3</span>
        <span id="app_pr_engment_flip" className={boxClass.join(' ')} onClick={()=>this.toggleMobileFilter ()}>
        <i className="fa fa-chevron-down"> </i>
    </span>

</div>
<div className="app_pr__follow_up top_most_uper">
<div className="follow_up_internal">
<div className="internal_list active_yes">
<span className="list_job_status">
Active
</span>
    <span>Capgemini</span>
    <span>Front-End Development(7432)</span>
    <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Ready to Offer</span>
    </div>
</div>
</div>
<div className="follow_up_client ">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
    <div className="follow_up_offered ">
<div className="offered_list active_no active_frezed">
<span className="list_job_status">
Freezed 
</span>
    <span>Infoysis</span>
    <span>Web Development(4543)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client Interview</span>
    </div>
</div>
</div>

</div>

<div id="app_pr_engment_panel">
    
<div className="app_pr__follow_up">
<div className="follow_up_offered">
<div className="offered_list active_no active_frezed">
<span className="list_job_status">
Freezed 
</span>
    <span>Infoysis</span>
    <span>Web Development(4543)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client Interview</span>
    </div>
</div>
</div>
<div className="follow_up_client">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="internal_list active_yes">
<span className="list_job_status">
Active
</span>
    <span>Capgemini</span>
    <span>Front-End Development(7432)</span>
    <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Ready to Offer</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
    <div className="follow_up_offered">
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
<div className="client_list active_no">
<span className="list_job_status">
Inactive
</span>
    <span>Tcs</span>
    <span>Software Analyst(6756)</span>
        <div className="offer_box_with_smile">
    <i className="fa fa-smile-o"></i><span className="offer_ready ooffeerr">Client-Interview Rejected</span>
    </div>
</div>
</div>
<div className="follow_up_accpted">

</div>
</div>
</div>

</div>
</div>
</div>

</div>

</div>


{/* new list */}

<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul>
</nav>
</div>
</div>
<div className="all_job_listing_view" id="main_list_view">

<table className="table table-bordered">
<tr>
    <th>Job Ref. Code</th>
    <th>Job Title</th>
    <th>Assigned Recruiter(s)</th>
    <th>Target Date</th>
    <th>Job Status</th>
    <th>Location</th>
    <th>Actions</th>
</tr>
<tr>
    <td>2134</td>
    <td>Front End Developer</td>
    <td>Azad hussain</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
<tr>
    <td>2134</td>
    <td>Back End Developer</td>
    <td>Avinash</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
<tr>
    <td>2134</td>
    <td>Web Designer</td>
    <td>Rakesh Yadav</td>
    <td>Apr. 20,2020</td>
    <td>Active</td>
    <td>Rockville, Maryland</td>
    <td>Edit</td>
</tr>
</table>

</div>

</div>

</div>

</div>

<Footer/>
 
 </>
  );
}

}
export default Candidate_listing;