import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Mapping from './../ATS_common/Mapping'

const $ = window.$;

class Candidate_status_settings extends React.Component {

  constructor(){
    super();
    this.state={ }
  }
	componentDidMount(){
    }
    render() {
    return (
         <>
            <form>
              {/* <div className="deshboard_ouuter">
                  <h2>Candidate Status Settings</h2>
              </div> */}
              <div className="hsmd_status hsmd_category_page CSS">

                <div className="Candidate_ss">
                
                  <div className="Candidate_ss_1">
                    <div className="Candidate_ss_inner">
                    
                      <h3>Hiring Stages Configuration</h3>
                      <div className="Candidate_ss_inner_block">
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Ageing Starter</h3>
                          <p> Starting Stage from which the ageing of hiring time is calculated. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Ageing Stopper</h3>
                          <p> End Stage at which the ageing of hiring time is calculated. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Internal Submission</h3>
                          <p> Stage at which the Sourcing team do internal submission of a Candidate. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Submit to Client</h3>
                          <p> Stage at which the Account Manager do submission of a Candidate to a Client. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Interview Request</h3>
                          <p> Stage at which Client request for scheduling an interview of a Candidate. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Interview Scheduled</h3>
                          <p> Stage at which Candidates interview is scheduled with a Clien. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Ready to Offer</h3>
                          <p> Stage at which Client confirms an Offer to a Candidate. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Offered</h3>
                          <p> Stage at which a Candidate is offered by a Client. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Offer Accepted</h3>
                          <p> Stage at which a Candidate accepts the Offer given by a Client. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Declined</h3>
                          <p> Stage at which a Candidate is offered by a Client, but Candidate Declines the Offer. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>Joined</h3>
                          <p> Stage at which a Candidate is hired and Joined the Client. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <h3>No Show for Joining</h3>
                          <p> Stage at which a Candidate has not Joined on given date of Joining. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Candidate_ss_2">
                    <div className="Candidate_ss_inner">
                    
                      <h3>Additional Information need to be Captured</h3>
                      <div className="Candidate_ss_inner_block">
                        <div className="Candidate_ss_inner_block_single">
                          <p> </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p> Before Internal Submission, Sourcing Team update the latest Candidate information and Profile in System. </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p> Sales Rate/Hr , Notice Period </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p> Final Sales Rate/Hr , Expected DOJ, Offer Template (Optional) </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>
                        <div className="Candidate_ss_inner_block_single">
                          <p>  </p>
                        </div>                        
                      </div>
                    </div>
                  </div>
                  <div className="Candidate_ss_3">
                    <div className="Candidate_ss_inner">
                    
                      <h3>Candidate Status</h3>
                      <div className="Candidate_ss_inner_block">
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                              <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                              <option value=""></option>
                                <optgroup label="Screening">
                                  <option value="volvo">new</option>
                                  <option value="saab">Screening passed</option>
                                  <option value="saab">Screening failed</option>
                                  <option value="saab">Not interested</option>
                                  <option value="saab">Not Contacted</option>
                                  <option value="saab">Internal Submission</option>
                                  <option value="saab">Internal Screen Rejected</option>
                                </optgroup>
                                <optgroup label="Client Submission">
                                  <option value="mercedes">Submiited to client</option>
                                  <option value="audi">Client Screen Rejected</option>
                                  <option value="audi">Client Screen Approved</option>
                                </optgroup>
                                  <optgroup label="Client Interview">
                                  <option value="mercedes">Schedule Interview</option>
                                  <option value="mercedes">Interview Scheduled </option>
                                  <option value="audi">Interview in Progress</option>
                                  <option value="audi">on Hold</option>
                                  <option value="audi">Interview Selected</option>
                                  <option value="audi">Interview Rejected</option>
                                </optgroup>
                                  <optgroup label="Offer">
                                  <option value="mercedes">Ready to Offer</option>
                                  <option value="mercedes">Offered </option>
                                  <option value="audi">Offer Accepted</option>
                                  <option value="audi">Offer Declined</option>
                                  <option value="audi">Offer Withdrawn</option>
                                  </optgroup>
                                  <optgroup label="Hired">
                                  <option value="mercedes">Hired</option>
                                  <option value="mercedes">Joined </option>
                                  <option value="audi">No Show</option>
                                </optgroup>
                                  <optgroup label="Client Interview">
                                  <option value="mercedes">Other</option>
                                </optgroup>
                              </select>
                            </div>
                          </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
                       
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>

                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>

                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>

                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
 
                        <div className="Candidate_ss_inner_block_single">
                          <div className="form-inner-box">					
                            <div className="floating-label">      
                            <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                            <option value=""></option>
                              <optgroup label="Screening">
                                <option value="volvo">new</option>
                                <option value="saab">Screening passed</option>
                                <option value="saab">Screening failed</option>
                                <option value="saab">Not interested</option>
                                <option value="saab">Not Contacted</option>
                                <option value="saab">Internal Submission</option>
                                <option value="saab">Internal Screen Rejected</option>
                              </optgroup>
                              <optgroup label="Client Submission">
                                <option value="mercedes">Submiited to client</option>
                                <option value="audi">Client Screen Rejected</option>
                                <option value="audi">Client Screen Approved</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Schedule Interview</option>
                                <option value="mercedes">Interview Scheduled </option>
                                <option value="audi">Interview in Progress</option>
                                <option value="audi">on Hold</option>
                                <option value="audi">Interview Selected</option>
                                <option value="audi">Interview Rejected</option>
                              </optgroup>
                                <optgroup label="Offer">
                                <option value="mercedes">Ready to Offer</option>
                                <option value="mercedes">Offered </option>
                                <option value="audi">Offer Accepted</option>
                                <option value="audi">Offer Declined</option>
                                <option value="audi">Offer Withdrawn</option>
                                </optgroup>
                                <optgroup label="Hired">
                                <option value="mercedes">Hired</option>
                                <option value="mercedes">Joined </option>
                                <option value="audi">No Show</option>
                              </optgroup>
                                <optgroup label="Client Interview">
                                <option value="mercedes">Other</option>
                              </optgroup>
                            </select>
                            </div>
                            </div>
                        </div>
                        </div> 
                    </div>
                  </div>
                </div>  

              </div>

            </form>    
         </>
    );
    }

}
export default Candidate_status_settings;

