import React, { Component } from 'react';
import {Link} from 'react-router-dom'
  import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';

const $ = window.$;

class Select_can extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
         data: [
  {
    "id": 1,
    "name": "Azad Hussain",
    },

  {
    "id": 2,
    "name": "Rakesh Yadav",
  },
  {
    "id": 3,
    "name": "Nida",
  },
  {
    "id": 4,
    "name": "Ankit",
  }
],
      value: ''
    };
  }
  componentWillMount() {

  }
render() {
          const { data, value } = this.state;
      return (
    <>

  <div className="email_right_side append_attri">
<h3>Select Candidate</h3>
<div className="append_attri">
<input type="text" value={value} onChange={this.handleChange} />
        <SearchResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                <div>
                 <span>{el.name}</span>
                </div>
              ))}
            </div>
          )}
        />
</div>
</div> 
        </>
  );
}

}
export default Select_can;