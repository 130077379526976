import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Mapping from './../ATS_common/Mapping'
const $ = window.$;

class Reason_of_rejection extends React.Component {

  constructor(){
    super();
    this.state={ }
  }
	componentDidMount(){
    }
    render() {
    return (
        <>
                <form>
                    {/* <div className="deshboard_ouuter">
                        <h2>Reason Of Rejection</h2>
                    </div> */}
                    <div className="hsmd_status hsmd_category_page">
                        <div className="form-inner-box">					
                            <div className="floating-label">      
                                <select  id="status" className="floating-select" onClick="this.setAttribute('value', this.value);">
                                    <option value=""></option>
                                    <optgroup label="Screening">
                                        <option value="saab">Screening failed</option>
                                        <option value="saab">Not interested</option>
                                        <option value="saab">Not Contacted</option>
                                        <option value="saab">Internal Screen Rejected</option>
                                    </optgroup>
                                    <optgroup label="Client Submission">
                                        <option value="audi">Client Screen Rejected</option>
                                    </optgroup>
                                        <optgroup label="Client Interview">
                                        <option value="audi">Interview Rejected</option>
                                    </optgroup>
                                        <optgroup label="Offer">
                                        <option value="audi">Offer Declined</option>
                                        <option value="audi">Offer Withdrawn</option>
                                        </optgroup>
                                        <optgroup label="Hired">
                                        <option value="audi">No Show</option>
                                    </optgroup>
                                </select>
                                <label>Category</label>
                            </div>
                        </div>
                        <div className="form-inner-box text_full">					
                            <div className="floating-label">      
                            <input type="text" className="floating-input" placeholder=""/>
                            <label>Status Category</label>
                            </div>
                        </div>
                    </div>    
                    <div className="hsmd_btn hsc_btn ">
                        <button className="hsmd_btn_save hsmd_button">Save</button>
                        <button className="hsmd_btn_cancel hsmd_button">Cancel</button>
                    </div>

                    <div className="hsmd_table hsmd_categories">
                         <table>
                            <thead>
                                <tr>
                                    <th>Reason Of Rejection</th>
                                    <th>Candidate Status For Rejection</th>
                                    <th>Action</th>
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Technical Skills are not as per essential skills required for this role.</td>                                    
                                    <td>Client Screen Rejected</td>
                                    <td><Link to="">Edit</Link></td>
                                </tr>
                                <tr>
                                    <td>Technical Skills are not as per essential skills required for this role.</td>                                    
                                    <td>Client Screen Rejected</td>
                                    <td><Link to="">Edit</Link></td>
                                </tr>
                                <tr>
                                     <td>Technical Skills are not as per essential skills required for this role.</td>                                    
                                    <td>Client Screen Rejected</td>
                                    <td><Link to="">Edit</Link></td>
                                </tr>
                                <tr>
                                    <td>Technical Skills are not as per essential skills required for this role.</td>                                    
                                    <td>Client Screen Rejected</td>
                                    <td><Link to="">Edit</Link></td>
                                </tr>
                            </tbody> 
                        </table>          
                    </div>

                </form>    
         </>
    );
    }

}
export default Reason_of_rejection;