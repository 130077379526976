import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Mapping from './../ATS_common/Mapping'
const $ = window.$;

class Candidate_status extends React.Component {

  constructor(){
    super();
    this.state={ }
  }
	componentDidMount(){
    }
    render() {
    return (
        <>
          <form>
              {/* <div className="deshboard_ouuter">
                  <h2>Hiring Status</h2>
              </div> */}

              <div className="hsmd_status hsmd_category_page Candidate_sat">

                <h3>Define or Manage Hiring Stages</h3>

                <div className="Candidate_sat_table">
                  <div className="Candidate_sat_table_top">
                    <span className="Candidate_sat_table_heading">Screening Stages</span>
                    <span className="Candidate_sat_table_add"><i className="fa fa-plus"></i> Add</span>
                  </div>
                  <table className="table-bordered table">
                    <thead>
                        <tr>
                          <th>Sort</th>
                          <th></th>
                          <th>Candidate Status</th>
                          <th>Level</th>
                          <th>Rejection</th>
                          <th>Active</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>New</td>
                        <td>1</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Screening Passed</td>
                        <td>2</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Screening Failed</td>
                        <td>2</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Not Interested</td>
                        <td>2</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Not Contacted</td>
                        <td>2</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Internal Submission</td>
                        <td>3</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Internal Screen Rejected</td>
                        <td>3</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
{/* new */}
                <div className="Candidate_sat_table">
                  <div className="Candidate_sat_table_top">
                    <span className="Candidate_sat_table_heading">Client Submission</span>
                    <span className="Candidate_sat_table_add"><i className="fa fa-plus"></i> Add</span>
                  </div>
                  <table className="table-bordered table">
                    <thead>
                        <tr>
                          <th>Sort</th>
                          <th></th>
                          <th>Candidate Status</th>
                          <th>Level</th>
                          <th>Rejection</th>
                          <th>Active</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>8</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Submitted to Client</td>
                        <td>4</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Client Screen Approved</td>
                        <td>5</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Client Screen Rejected</td>
                        <td>5</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
{/* new */}
                <div className="Candidate_sat_table">
                  <div className="Candidate_sat_table_top">
                    <span className="Candidate_sat_table_heading">Client Interview</span>
                    <span className="Candidate_sat_table_add"><i className="fa fa-plus"></i> Add</span>
                  </div>
                  <table className="table-bordered table">
                    <thead>
                        <tr>
                          <th>Sort</th>
                          <th></th>
                          <th>Candidate Status</th>
                          <th>Level</th>
                          <th>Rejection</th>
                          <th>Active</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>11</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Schedule Interview</td>
                        <td>6</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Interview Scheduled</td>
                        <td>7</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Interview in Progress</td>
                        <td>8</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Awaiting Interview Feedback</td>
                        <td>9</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Interviewed Selected</td>
                        <td>10</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Interviewed Rejected</td>
                        <td>10</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
{/* new */}
                <div className="Candidate_sat_table">
                  <div className="Candidate_sat_table_top">
                    <span className="Candidate_sat_table_heading">Offer </span>
                    <span className="Candidate_sat_table_add"><i className="fa fa-plus"></i> Add</span>
                  </div>
                  <table className="table-bordered table">
                    <thead>
                        <tr>
                          <th>Sort</th>
                          <th></th>
                          <th>Candidate Status</th>
                          <th>Level</th>
                          <th>Rejection</th>
                          <th>Active</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>17</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Ready to Offer</td>
                        <td>11</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>18</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Offered</td>
                        <td>12</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>19</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Offer Accepted</td>
                        <td>13</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Offer Declined</td>
                        <td>13</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>21</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Offer Withdrawn </td>
                        <td>13</td>
                        <td>Y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
{/* new */}
                <div className="Candidate_sat_table">
                  <div className="Candidate_sat_table_top">
                    <span className="Candidate_sat_table_heading">Screening Stages</span>
                    <span className="Candidate_sat_table_add"><i className="fa fa-plus"></i> Add</span>
                  </div>
                  <table className="table-bordered table">
                    <thead>
                        <tr>
                          <th>Sort</th>
                          <th></th>
                          <th>Candidate Status</th>
                          <th>Level</th>
                          <th>Rejection</th>
                          <th>Active</th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>22</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>Joined</td>
                        <td>14</td>
                        <td>N</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>23</td>
                        <td><i className="fa fa-align-justify"></i></td>
                        <td>No Show</td>
                        <td>14</td>
                        <td>y</td>
                        <td>Y</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </form> 
    </>
    );
    }

}
export default Candidate_status;