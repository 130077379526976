import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Candidate_filter from './../Pop_up/Candidate_filter'

const $ = window.$;

class Manage_user_list extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}

render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing ">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="listing_section_block_section  container">
<div className="job_list_section_block">

<div className="top_section_menu">
<div className="title_name_of_page">
    <h1>List of User</h1>
</div>
<div className="add_new_to_page">
    <span><Link to="/manage_user"><i className="fa fa-plus"></i> Add User</Link></span>
</div>
</div>

<div className="top_page_filter_set">
<div className="search_filter_box">
<input type="text" name=""/><span><i className="fa fa-search"></i></span>
</div>

</div>


</div>
<div className="all_client_listing_view" id="main_list_view">

<table className="table table-bordered">
<thead>
<tr>

    <th>Name</th>
    <th>Email</th>
    <th>Title</th>
    <th>Reporting To</th>
    <th>Security Group</th>
</tr>
</thead>
<tbody>
<tr>
   
    <td>David</td>
    <td>pr.mail@gmail.com</td>
    <td>Sanjeev</td>
    <td>Avinash</td>
    <td>Product Owner</td>
</tr>
<tr>
    <td>David</td>
    <td>gm.email@gmail.com</td>
    <td>Sanjeev</td>
    <td>Avinash</td>
    <td>Account Manger</td>
</tr>
</tbody>
</table>

</div>
<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul>
</nav>
</div>

</div>

</div>

<Footer/>
 
 </>
  );
}

}
export default Manage_user_list;