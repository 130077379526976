import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';

const $ = window.$;

class Master_list_filter extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      data: [
  {
    "id": 1,
    "name": "Assessment Type",
    },
  {
    "id": 2,
    "name": "Candidate Category",
  },
  {
    "id": 3,
    "name": "Candidate Status",
  },
  {
    "id": 4,
    "name": "Candidate Status Category",
  },
    {
    "id": 5,
    "name": "Client Master",
    },
  {
    "id": 20,
    "name": "Course Type",
  },
  {
    "id": 6,
    "name": "Degree",
  },
  {
    "id": 7,
    "name": "Degree Description",
  },
    {
    "id": 8,
    "name": "Document Type",
    },
  {
    "id": 9,
    "name": "Duration",
  },
  {
    "id": 10,
    "name": "Industry",
  },
  {
    "id": 11,
    "name": "Job Contract Type",
  },
   {
    "id": 12,
    "name": "Job Fill Type",
    },
  {
    "id": 12,
    "name": "Job Priority",
  },
  {
    "id": 13,
    "name": "Job Requirement Type",
  },
  {
    "id": 14,
    "name": "Job Status",
  },
    {
    "id": 15,
    "name": "Job Type",
    },
  {
    "id": 16,
    "name": "Qualification Level",
  },
  {
    "id": 17,
    "name": "Rate Type",
  },
  {
    "id": 18,
    "name": "User Type",
  },
    {
    "id": 19,
    "name": "Work Auth",
    },
],
      value: ''
    };
  }
  componentWillMount() {
    // fetch('https://jsonplaceholder.typicode.com/users')
    //   .then(response => response.json())
    //   .then(json => this.setState({ data: json }));
  }
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

	componentDidMount(){

}




render() {
    const { data, value } = this.state;
  return (
    <>
  
    <table className="table-bordered table">
        <tr>
            <td>
            <div className="form-inner-box full_with">
                <div className="floating-label">  
<input type="text" className="floating-input" value={value} onChange={this.handleChange} />
     <label>Search Master Name</label>
                </div>
                </div>
        <SearchResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                <tr>
                 <td>{el.name}</td>
                </tr>
              ))}
            </div>
          )}
        />
                   
            </td>
        </tr>
</table>

    {/* <table className="table-bordered table">
        <tr>
            <td>
            <div className="form-inner-box full_with">
                <div className="floating-label">      
                <input className="floating-input" type="text" placeholder=" "/>

                <label>Search Master Name</label>
                </div>
                </div>
            </td>
        </tr>
        <tr><td>Assessment Type</td></tr>
        <tr><td>Candidate Category</td></tr>
        <tr><td>Candidate Status</td></tr>
        <tr><td>Candidate Status Category</td></tr>
        <tr><td>Client Master</td></tr>
        <tr><td>Course Type</td></tr>
        <tr><td>Degree</td></tr>
        <tr><td>Degree Description</td></tr>
        <tr><td>Document Type</td></tr>
        <tr><td>Duration</td></tr>
        <tr><td>Industry</td></tr>
        <tr><td>Job Contract Type</td></tr>
        <tr><td>Job Fill Type</td></tr>
        <tr><td>Job Priority</td></tr>
        <tr><td>Job Requirement Type</td></tr>
        <tr><td>Job Status</td></tr>
        <tr><td>Job Type</td></tr>
        <tr><td>Qualification Level</td></tr>
        <tr><td>Rate Type</td></tr>
        <tr><td>User Type</td></tr>
        <tr><td>Work Auth</td></tr>
    </table> */}

    </>
  );
}

}
export default Master_list_filter;
