import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Note_type from './Note_type.js'

const Notes = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }



  return (
    <>

    <div className="profile_medotry">

{/* <p className="block_heading"> Notes <span className="sort_ing"> <i className="fa fa-sort-amount-desc"></i> </span></p> */}
<p className="block_heading"> Notes  </p>

  {/* <div className={classNames("new_note_sec ", {"showBlockClass":this.state.showBlock})}> */}
  <div className="new_note_sec">
    <div className="new_note_type">
    <div className="note_show">
      <div className="form-inner-box">					
        <div className="floating-label">      
        <select className="floating-select">
        <option value=""></option>
        <option value="1">Call</option>
        <option value="2">Update</option>
        </select>

        <label>Note Type</label>
        </div>
      </div>
        <Note_type/>
</div>
        <div className="hidden">
          <div className="form-inner-box">					
            <div className="floating-label">      
              <input className="floating-input" type="text" placeholder=" "/>
              <label>Note Type</label>
            </div>
          </div>
        <span className="cancel_new_note">Cancel</span>
        </div>

<div className="notes_textae">
<textarea className="floating-input floating-textarea" placeholder="@mention to notify User "></textarea>
</div>
<div className="note_checkbox">
<input type="checkbox" />
<span>Add this note to the Job Opening record</span>

</div>

<div className="notes_upload">
<div className="upload_section">   
<div className="fileUpload btn btn--browse">
    <span><i className="fa fa-paperclip"></i> Attach file</span>
    <input id="uploadBtn" type="file"  className="upload" />
</div>
</div>
</div>

<div className="note_submit">
<div className="form-submit-button">
{/* <button  onClick={this.removeClass} className="cancel_btn" type="submit"> Cancel </button> */}
<button className="save_btn" type="submit"> Add </button>
<button className="cancel_btn" type="submit"> Cancel </button>

</div>
</div>


    </div>
  </div>

{/* <div className="notes_search">
<div className="notes_modify">
<input type="text" placeholder="@mention to notify User "/> 
</div>
</div> */}

<div className="all_notes_section">
<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span>
</div>
</div>

<div className="user_note_content">
<p>Need to set up a call between Candidate and Client</p>
<div className="user_edit_del">
<i className="fa fa-trash-o"></i>
<i className="fa fa-pencil"></i>
</div>
</div>

<div className="note_detail_his">
<div className="note_his_detal">
<i className="fa fa-clock-o"></i>
<span>Apr. , 10</span> by <span>Sanjeev </span>
</div>
</div>

</div>


<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span>
</div>
</div>

<div className="user_note_content">
<p>Need to set up a call between Candidate and Client</p>
<div className="user_edit_del">
<i className="fa fa-trash-o"></i>
<i className="fa fa-pencil"></i>
</div>
</div>

<div className="note_detail_his">
<div className="note_his_detal">
<i className="fa fa-clock-o"></i>
<span>Apr. , 10</span> by <span>Sanjeev </span>
</div>
</div>

</div>

<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span>
</div>
</div>

<div className="user_note_content">
<p>Need to set up a call between Candidate and Client</p>
<div className="user_edit_del">
<i className="fa fa-trash-o"></i>
<i className="fa fa-pencil"></i>
</div>
</div>

<div className="note_detail_his">
<div className="note_his_detal">
<i className="fa fa-clock-o"></i>
<span>Apr. , 10</span> by <span>Sanjeev </span>
</div>
</div>

</div>
<div className="hidden" id="hide_id_1">
<div className="single_note">
<div className="note_user_detail">

<div className="note_us_pic">
<img src="/../images/profile_.png" alt=""/>
</div>
<div className="note_user_title">
<span>Call</span>
</div>
</div>

<div className="user_note_content">
<p>Need to set up a call between Candidate and Client</p>
<div className="user_edit_del">
<i className="fa fa-trash-o"></i>
<i className="fa fa-pencil"></i>
</div>
</div>

<div className="note_detail_his">
<div className="note_his_detal">
<i className="fa fa-clock-o"></i>
<span>Apr. , 10</span> by <span>Sanjeev </span>
</div>
</div>

</div>

</div>

<div className="view_more">
<div className="view_more_link" id="show_hide_btn">View More Notes</div>
<div className="view_more_number">3/5</div>
</div>


</div>
</div>

    </>
  );
}

export default Notes;