import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

const $ = window.$;

class Candidate_hiring_stages extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

    }




    render() {
    return (
        <>
                <form>
                    {/* <div className="deshboard_ouuter">
                        <h2>Candidate Hiring Stages</h2>
                    </div>
                    <div className="hsmd_status hsmd_category_page">
                        <div className="form-inner-box">					
                            <div className="floating-label">      
                            <input className="floating-input" type="text" placeholder=" "/>
                            <label>Status Category</label>
                            </div>
                        </div>
                    </div>    
                    <div className="hsmd_btn hsc_btn">
                        <button className="hsmd_btn_save hsmd_button">Save</button>
                        <button className="hsmd_btn_cancel hsmd_button">Cancel</button>
                    </div> */}
                    {/* <div className="hsmd_status hsmd_seach_category">
                        <span className="search_label">Search Category</span>
                        <div className="hsmd_search_input">
                            <span className="hsmd_si">
                                <input type="text" placeholder="search"/>
                            </span>
                            <span className="hsmd_search_btn">
                                <button type="button">
                                    <i className="fa fa-search"></i>
                                    Search
                                </button>
                            </span>
                        </div>
                    </div> */}
                    
                    <div className="hsmd_table hsmd_categories">
                         <table>
                            <thead>
                                <tr>
                                    <th>Sort</th>
                                    <th></th>
                                    <th>Hiring Stage</th>
                                    <th>Description</th>
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td> <i className="fa fa-align-justify"></i></td>
                                    <td>Screening</td>
                                    <td>Internal Screening of Candidate by the Sourcing Team.</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td> <i className="fa fa-align-justify"></i></td>
                                    <td>Client Submission</td>
                                    <td>Submission of Candidate to the Client By Account Mgr.</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td> <i className="fa fa-align-justify"></i></td>
                                    <td>Client Interview</td>
                                    <td>Candidate is shortlisted by Client and Interview in Process.</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td> <i className="fa fa-align-justify"></i></td>
                                    <td>Offered</td>
                                    <td>Candidate is Offered</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td> <i className="fa fa-align-justify"></i></td>
                                    <td>Hired</td>
                                    <td>Candidate is Hired</td>
                                </tr>
                            </tbody> 
                        </table>          
                    </div>
                </form> 
    </>
    );
    }

}
export default Candidate_hiring_stages;