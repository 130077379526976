import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import Header from './../ATS_common/Header'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Index extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header/>


  <div className="homepage_outer_container">
<div className="overlay overlay-bg"></div>
	<div className="homepage_before_login container">
		
<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 banner-outer-box">
	<div className="banner-inner-box">
		<h1 className="banner-text">
			Applicant Tracking System
		</h1>
		<p>End-To-end hiring solutions for Staffing Agencies and Corporate HRs</p>
	</div>
</div>
<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 banner-signup-form">
	
	<div className="inner-signup-form">
		
		<div className="signup-form-homepage">
			<img src="./images/logo.png" alt=""/>
			<p>Get Started</p>
			<form action="/Signin">

				<div className="form-inner-box">
<div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>Full Name
					</label>
					</div>
				</div>

				<div className="form-inner-box">
					<div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>Email
					</label>
					</div>
				</div>
					
				<div className="form-inner-box">
					<div className="floating-label">      
					<input className="floating-input" type="text" placeholder=" "/>

					<label>Password
					</label>
					</div>
				</div>

				<div className="form-inner-box">
					<div className="form-radio">
						<p><input type="radio" name="radio"/> <span>Staffing Agency</span></p>
						<p><input type="radio" name="radio"/> <span>Corporate HR</span></p>
					</div>
				</div>
				<div className="iamnotrobot">
				<div className="capatcha">
					 <ReCAPTCHA ref={e => (this.captcha = e)} sitekey={Constant._SITE_RECAPTCHA_SITE_KEY} onChange={(value) => { this.setState({ 'g-recaptcha-response': value }); }} />
				</div>
				</div>
<div className="alreadytext">
	<p>By clicking on Register, you agree with Veriipro<a href=""> Terms of Service</a> and <a href="">Privacy Policy</a>, including Cookie use.</p>
</div>
				<div className="form-submit-button">
					<button type="submit"> Register now	</button>
				</div>


				
			</form>

		</div>

	</div>

</div>

	</div>
	


</div>


  <Footer/>
  </div>
    </>
  );
}

}
export default Index;