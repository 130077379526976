import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';
import Action_change from './../Pop_up/Action_change'
const $ = window.$;

class User_access extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [
  {
    "id": 1,
        "name": "Rakesh",
    "title": "Bret",
    "email": "Sincere@april.biz",
    },

  {
    "id": 2,
        "name": "Azad",
    "title": "Bret",
    "email": "Sincere@april.biz",
  },
  {
    "id": 3,
        "name": "Sanjeev",
    "title": "Bret",
    "email": "Sincere@april.biz",
  },
  {
    "id": 4,
        "name": "Nida",
    "title": "Bret",
    "email": "Sincere@april.biz",
  }
],
      value: ''
    };
  }


	componentDidMount(){

}
  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };
render() {
     const { data, value } = this.state;
  return (




    <>
  


  <div className="main-body emp_body">
  <Header_after_login/>
  

<div className="homepage-outer form-container-listing candidate_page mt-50">
    <div className="container-fluid">

        <div className="job_filter_name_heading">
            <div className="job_heding_name">
                <span>
                    <Link to="">
                    <i className="fa fa-long-arrow-left"></i>
                    </Link>
                </span>
<div id="material-tabs">
						<a id="tab1-tab" href="#tab1" class="active">Group Access</a>
						<a id="tab2-tab" href="#tab2">Group Members</a>
						<a id="tab3-tab" href="#tab3">User Access</a>
						<span class="yellow-bar"></span>
				</div>
            </div>
        </div>

    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 right_fix_side side_content_full user_access mt-65">
<div className="col-lg-9 user_result_show">


{/* <div id="material-tabs">
						<a id="tab1-tab" href="#tab1" class="active">Group Access</a>
						<a id="tab2-tab" href="#tab2">Group Members</a>
						<a id="tab3-tab" href="#tab3">User Access</a>
						<span class="yellow-bar"></span>
				</div> */}
	

<div class="tab-content">


    <div id="tab1">


        <div className="user_access_block">

            <p>Define Module Wise Feature Access for each Security Group</p>

                <div className="user_aces_top_module">
                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                                <option value=""></option>
                                <option value="1">Admin</option>
                                <option value="2">Account Manager</option>
                                <option value="3">Recruiter</option>
                                <option value="4">Sales Recruiter</option>
                                <option value="5">Team Lead</option>
                                <option value="5">Sourcer</option>
                                <option value="5">Back Office</option>
                                <option value="5">Hiring Manager</option>
                            </select>
                            <label>Choose Security Group</label>
                        </div>
                    </div>


                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                            <option value=""></option>
                            <option value="1">Jobs</option>
                            <option value="2">Candidate</option>
                            <option value="3">Interview</option>
                            <option value="4">Client</option>
                            <option value="5">Contact</option>
                            <option value="5">Vendor</option>
                            <option value="5">Admin</option>
                            <option value="5">Assessment</option>
                            <option value="5">Dashboard</option>
                            <option value="5">Reports</option>
                            </select>
                        <label>Choose Module</label>
                        </div>
                    </div>

                </div>

            <div className="user_aces_top_module">

                <div className="user_acc_search">
                    <input type="text" className="user_acc_search_input"/>
                    <span> <i className="fa fa-search"></i> </span>
                </div>


                    <div className="form-inner-box user_filter_all_gr_dden">					
                        <div className="floating-label">      
                            <select className="floating-select"  id="colorselector" >
                            <option value=""></option>
                            <option value="All_">All</option>
                            <option value="Grant_">Grant</option>
                            <option value="Deny_">Deny</option>
                            </select>
                            <label>Choose Features</label>
                        </div>
                    </div>

            </div>

                <div className="user_aces_top_apply_"> 
                <span> Apply Filter </span> 
                </div>

        </div>

        <div className="assessment_question_and_answer"> 
            <div id="All_" className="assess All_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Add New Job</td>
                            <td><input type="checkbox" checked className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Edit a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>View Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Change Job Status</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Archieve a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Email Job Details</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Team</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Vendors</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                        
                </table>

            </div>

            <div id="Grant_" className="assess Grant_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Can Archieve a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Email Job Details</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Team</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Vendors</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="Deny_" className="assess Deny_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Add New Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Edit a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>View Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Change Job Status</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>



    <div id="tab2">
    
        <div className="user_access_block">

            <p>Define Users within a Security Group</p>

                <div className="user_aces_top_module">
                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                                <option value=""></option>
                                <option value="1">Admin</option>
                                <option value="2">Account Manager</option>
                                <option value="3">Recruiter</option>
                                <option value="4">Sales Recruiter</option>
                                <option value="5">Team Lead</option>
                                <option value="5">Sourcer</option>
                                <option value="5">Back Office</option>
                                <option value="5">Hiring Manager</option>
                            </select>
                            <label>Choose Security Group</label>
                        </div>
                    </div>



                </div>

            <div className="user_aces_top_module">

                <div className="user_acc_search">
                    <input type="text" className="user_acc_search_input"/>
                    <span> <i className="fa fa-search"></i> </span>
                </div>


                    <div className="form-inner-box user_filter_all_gr_dden">					
                        <div className="floating-label">      
                            <select className="floating-select"  id="colorselector1" >
                            <option value=""></option>
                            <option value="All_1">All</option>
                            <option value="Grant_1">Grant</option>
                            <option value="Deny_1">Deny</option>
                            </select>
                            <label>Reporting Manager</label>
                        </div>
                    </div>

            </div>

                <div className="user_aces_top_apply_">                  
                <span className="reset_filter"> Reset Filter </span> 
                <span> Apply Filter </span>
                </div>

        </div>

        <div className="assessment_question_and_answer"> 
            <div id="All_1" className="assess All_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Reporting Manager</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>User Name </td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 1</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 2</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 3</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 4</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 5</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 6</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 7</td>
                            <td>Email1@dummyemail.com</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                    </tbody>
                        
                </table>

            </div>

            <div id="Grant_1" className="assess Grant_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Reporting Manager</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Add New Job</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 1</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 2</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 3</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="Deny_1" className="assess Deny_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Reporting Manager</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>User Name 4</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 5</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 6</td>
                            <td>Email1@dummyemail.com	</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                        <tr>
                            <td>User Name 7</td>
                            <td>Email1@dummyemail.com</td>
                            <td>Girish Giri</td>
                            <Action_change/>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>


    <div id="tab3">
    
        <div className="user_access_block">

            <p>Define Module Wise Feature Access for each User</p>

                <div className="user_aces_top_module">
                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                                <option value=""></option>
                                <option value="1">Admin</option>
                                <option value="2">Account Manager</option>
                                <option value="3">Recruiter</option>
                                <option value="4">Sales Recruiter</option>
                                <option value="5">Team Lead</option>
                                <option value="5">Sourcer</option>
                                <option value="5">Back Office</option>
                                <option value="5">Hiring Manager</option>
                            </select>
                            <label>Choose Security Group</label>
                        </div>
                    </div>


                    <div className="form-inner-box">					
                        <div className="floating-label">      
                            <select className="floating-select" >
                            <option value=""></option>
                            <option value="1">Jobs</option>
                            <option value="2">Candidate</option>
                            <option value="3">Interview</option>
                            <option value="4">Client</option>
                            <option value="5">Contact</option>
                            <option value="5">Vendor</option>
                            <option value="5">Admin</option>
                            <option value="5">Assessment</option>
                            <option value="5">Dashboard</option>
                            <option value="5">Reports</option>
                            </select>
                        <label>Choose Module</label>
                        </div>
                    </div>

                </div>

            <div className="user_aces_top_module">

                <div className="user_acc_search">
                    <input type="text" className="user_acc_search_input"/>
                    <span> <i className="fa fa-search"></i> </span>
                </div>


                    <div className="form-inner-box user_filter_all_gr_dden">					
                        <div className="floating-label">      
                            <select className="floating-select"  id="colorselector2" >
                            <option value=""></option>
                            <option value="All_2">All</option>
                            <option value="Grant_2">Grant</option>
                            <option value="Deny_2">Deny</option>
                            </select>
                            <label>Choose Features</label>
                        </div>
                    </div>

            </div>

                <div className="user_aces_top_apply_"> 
                <span> Apply Filter </span> 
                </div>

        </div>

        <div className="assessment_question_and_answer"> 
            <div id="All_2" className="assess All_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Add New Job</td>
                            <td><input type="checkbox" checked className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Edit a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>View Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Change Job Status</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Archieve a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Email Job Details</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Team</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Vendors</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                        
                </table>

            </div>

            <div id="Grant_2" className="assess Grant_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Can Archieve a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Email Job Details</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Team</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Share a Job with Vendors</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="Deny_2" className="assess Deny_">
                <table className="table table-bordered user_acc_table">
                    <thead>
                        <tr>
                            <th>Feature / Activity</th>
                            <th>Grant</th>
                            <th>Deny</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Add New Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Edit a Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>View Job</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                        <tr>
                            <td>Can Change Job Status</td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                            <td><input type="checkbox" className="user_checbox_aces"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</div>


    </div>

    <div class="col-lg-3 user_filter_drop_box">
    
<div className="user_aces_chose_user">
<h3>Choose User</h3>
<div className="append_attri">
<input type="text" value={value} onChange={this.handleChange}  placeholder="Search by User Name, Email"/>
        <SearchResults
          value={value}
          data={data}
          renderResults={results => (
            <div>
              {results.map(el => (
                <div className="user_detail_sec_useracc">
                 <span>{el.name}</span>
                 <span>{el.email}</span>
                 <span>{el.title}</span>
                </div>
              ))}
            </div>
          )}
        />
</div>
</div>

    </div>

   </div>

  </div>
</div>

          <Footer/>
  </div>
    </>
  );
}

}
export default User_access;