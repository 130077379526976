import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import * as Constant from './../../services/Constant'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'

const $ = window.$;

class Deshboard extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}




render() {
  return (
    <>
  
 
  <div className="main-body emp_body">
  <Header_after_login/>


    <div className="homepage-outer deshboard">
        <div className="top_user_detail_block">
            <div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
        </div>

        <div className="listing_section_block_section  container">
            <div className="deshboard_ouuter">

            <h1>Dashboard</h1>

                <div className="activity_wall">

                    <h3>To Do Activities</h3>
                
                    <div className="activity_outer">
                    
                        <table>
                            <tbody>
                                <tr>
                                    <td>Pending new Candidate Submission Agianst a Job</td>
                                    <td>5 Jobs</td>
                                    <td>Avg. Delay 4 Days</td>
                                </tr>
                                <tr>
                                    <td>Pending Candidate Screening</td>
                                    <td>3 Candidates</td>
                                    <td>Avg. Delay 4 Days</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div> 
                <div className="job_summery">
                    <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                        <div className="top_job_summery">
                            <div className="job_summery_title">
                                <span> Job Summary </span>
                            </div>
                            <div className="job_summery_filter">
                                <div className="share_job">
                                    
                                        <TabList className="filter_type_menu">
                                            <Tab tabFor="vertical-tab-one"> 
                                                <div className="job_upper_menu">
                                                    <span>WTD</span>
                                                </div>
                                            </Tab>
                                            <Tab tabFor="vertical-tab-two">
                                                <div className="job_upper_menu">
                                                    <span>Last 15 Days</span>
                                                </div>

                                            </Tab>
                                            <Tab tabFor="vertical-tab-three">
                                                <div className="job_upper_menu">
                                                    <span>MTD</span>
                                                </div>

                                            </Tab>

                                            
                                        </TabList>
                                </div>    
                            </div>
                        </div>
                        <div className="job_sumery_deatil">
                            <TabPanel tabId="vertical-tab-one">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New Jobs</th>
                                            <th>In-Process</th>
                                            <th>In-Active</th>
                                            <th>Covered</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11-Aug-2020</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>2</td>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-two">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New Jobs</th>
                                            <th>In-Process</th>
                                            <th>In-Active</th>
                                            <th>Covered</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11-Aug-2020</td>
                                            <td>7</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>3</td>
                                            <td>5</td>
                                            <td>4</td>
                                            <td>4</td>
                                            <td>2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-three">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New Jobs</th>
                                            <th>In-Process</th>
                                            <th>In-Active</th>
                                            <th>Covered</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>10-Aug-2020</td>
                                            <td>15</td>
                                            <td>12</td>
                                            <td>11</td>
                                            <td>12</td>
                                            <td>13</td>
                                            <td>14</td>
                                            <td>13</td>
                                            <td>6</td>
                                            <td>4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>                    
                        </div>
                    </Tabs>    
                </div>
                <div className="job_summery">
                    <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs">
                        <div className="top_job_summery">
                            <div className="job_summery_title">
                                <span> Candidate Summary </span>
                            </div>
                            <div className="job_summery_filter">
                                <div className="share_job">
                                    
                                        <TabList className="filter_type_menu">
                                            <Tab tabFor="vertical-tab-one"> 
                                                <div className="job_upper_menu">
                                                    <span>WTD</span>
                                                </div>
                                            </Tab>
                                            <Tab tabFor="vertical-tab-two">
                                                <div className="job_upper_menu">
                                                    <span>Last 15 Days</span>
                                                </div>

                                            </Tab>
                                            <Tab tabFor="vertical-tab-three">
                                                <div className="job_upper_menu">
                                                    <span>MTD</span>
                                                </div>

                                            </Tab>

                                            
                                        </TabList>
                                </div>    
                            </div>
                        </div>
                        <div className="job_sumery_deatil">
                            <TabPanel tabId="vertical-tab-one">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11-Aug-2020</td>
                                            <td>9</td>
                                            <td>7</td>
                                            <td>5</td>
                                            <td>3</td>
                                            <td>2</td>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-two">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11-Aug-2020</td>
                                            <td>5</td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>3</td>
                                            <td>2</td>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel tabId="vertical-tab-three">
                                  <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>New</th>
                                            <th>Screening</th>
                                            <th>Client Submission</th>
                                            <th>Client Interview</th>
                                            <th>Offered</th>
                                            <th>Hired</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>17-Aug-2020</td>
                                            <td>15</td>
                                            <td>13</td>
                                            <td>14</td>
                                            <td>13</td>
                                            <td>12</td>
                                            <td>11</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>                    
                        </div>
                    </Tabs>    
                </div>           
            </div>
        </div>    

    </div>


  <Footer/>
</div>
    </>
  );
}

}
export default Deshboard;