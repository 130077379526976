import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import Choose_job from './../Pop_up/Choose_job'
  import Choose_candidate from './../Pop_up/Choose_candidate'
import Select_can from './../Component/Section/Select_can'
  import FilterResults from 'react-filter-search';
import SearchResults from 'react-filter-search';
const Email_pop = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle} className="fa fa-envelope" aria-hidden="true" title="Email"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className} className="nested_model">
        <ModalHeader toggle={toggle}>Send Email </ModalHeader>

          <ModalBody>

  <Select_can/> 
<div className="client_sub">
<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 right_fix_side side_content_full client_submit">

<div className="client_submission_outer">

    
    <div className="client_sub_mission_form">

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> From </span>
      </div>
      <div className="client_to_detail">
        <span>avinash.tech</span>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> To </span>
      </div>
      <div className="client_to_detail">
          <span>Sanjeev.gem</span>
          <div className="cc_bcc">
          <span id="mail_cc">Add cc</span>
          <span id="mail_bcc">Add Bcc</span>
          <span id="mail_reply_to">Add Reply to</span>
          </div>
          {/* <div className="select_contact_radio">
          <span><input type="radio"/><em> Related Contact </em> </span>
          <span><input type="radio"/> <em> Primary Contact </em> </span>
          <span><input type="radio"/> <em> All Contact </em> </span>
          </div> */}
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> cc </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          <div className="email_drop_down_list">
          <div className="cc_bcc_list">
          <ul className="child-menu">
          <li>
          <span>Rakesh Yadav <i>( Web designer )</i> </span>
          <span>rakesh.yadav@techvilla <i>( Vendor )</i></span>          
          </li>
          <li>
          <span>Azad Hussain <i>( Web Developer )</i> </span>
          <span>azad@techvilla <i>( Client )</i></span>          
          </li>
          <li>
          <span>Nida <i>( Tester )</i> </span>
          <span>nida@techvilla <i>( Contact )</i></span>          
          </li>
          </ul>
          </div>
          </div>
          </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Bcc </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          <div className="email_drop_down_list">
          <div className="cc_bcc_list">
          <ul className="child-menu">
          <li>
          <span>Rakesh Yadav <i>( Web designer )</i> </span>
          <span>rakesh.yadav@techvilla <i>( Vendor )</i></span>          
          </li>
          <li>
          <span>Azad Hussain <i>( Web Developer )</i> </span>
          <span>azad@techvilla <i>( Client )</i></span>          
          </li>
          <li>
          <span>Nida <i>( Tester )</i> </span>
          <span>nida@techvilla <i>( Contact )</i></span>          
          </li>
          </ul>
          </div>
          </div>
          </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Reply to </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Choose Email Template </span>
      </div>
      <div className="client_to_detail">
        <div className="form-inner-box">					
          <div className="floating-label">      
            <select className="floating-select" onClick="this.setAttribute('value', this.value);">
            <option value="">None</option>
            <option value="1">Resume</option>
            <option value="2">Client</option>
            </select>

           <label>Email Template</label>
          </div>
        </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Attachment </span>
      </div>
      <div className="client_to_detail">
        <div className="notes_upload">
          <div className="upload_section">   
            <div className="fileUpload btn btn--browse">
                <span><i className="fa fa-paperclip"></i> browse</span>
                <input id="uploadBtn" type="file"  className="upload" />
            </div>
          </div>
        </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
      <div>
          <span className="strng"> Subject Line </span>
      </div>
      <div className="client_to_detail">
          <div className="contct_input">
          <input type="text" className="originalinput"/>
          </div>
      </div>
  </div>

</div>

<div className="client_single_field">

  <div className="client_to">
 
    <div className="form-inner-box full_with">	

      <div className="floating-label">  

        <textarea className="floating-input floating-textarea" placeholder=" "></textarea>

      </div>

    </div>

  </div>

</div>


<div className="client_single_field">

  <div className="client_to">
    <div className="send_to_all">
     <input type="checkbox"/>
     <span>Add Signature.</span>
    </div>
  </div>

</div>

</div>
</div>
</div>
 </div>


            </ModalBody>
            <ModalFooter>
              <Button  color="outline-primary" onClick={toggle}>Cancel</Button>{' '}
                    
              <Button color="primary" onClick={toggle}>Send</Button>{' '}
            </ModalFooter>
          </Modal>
    </>
  );
}

export default Email_pop;