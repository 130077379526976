import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import classNames from 'classnames'
import Review from './../Pop_up/Review'
import Update_status from './../Pop_up/Update_status'
const $ = window.$;

class Contactdetail extends React.Component {

  constructor(){
    super();
    this.state={
showBlock: false

     };

    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
  }

  addClass() {
    this.setState({
      showBlock:true
    });
  }

  removeClass() {
    this.setState({
      showBlock:false
    });
  }


	componentDidMount(){
}

render() {
  return (
    <>
  
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing candidate_page mt-50">
<div className="container-fluid">
<div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 left_fix_side side_menu">
<div className="main_side_menu">
<ul className="sidemenu_list">
<li className="active_side_menu"><span className="timeline">Timeline</span> <span className="last_update">Last Updated: 5 Days ago</span></li>

<li><strong>RELATED INFORMATION</strong></li>
<li className="active_side_menu">Job Opening  1  <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu">Notes  2  <span><i className="fa fa-plus" onClick={this.addClass}></i></span></li>


<li className="active_side_menu">Interviews  1  <span><i className="fa fa-plus"></i></span></li>
<li className="active_side_menu">Emails    <span><i className="fa fa-plus"></i></span></li>

<li><strong>TO-DOs</strong></li>

<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Tasks   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Events   </li>
<li className="active_side_menu"><span><i className="fa fa-plus"></i></span> Call   </li>



</ul>
</div>
</div>
<div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 right_fix_side side_content_full">
<div className="main_fix_section">
<div className="cnadidate_detail_section">
<div className="candidate_info_sec">

<div className="candidate_pro_pic">
<img src="/../images/profile.png" alt=""/>

</div>

<div className="candidate_infoomation">
<div className="cand_info"><div className="candiadte_name">
<a href="" className="h1_name">Mr. Ahuja Reyansh </a>
</div>
</div>
<div className="all_icon_list_row all_sup_icon  candid_icon">
<i className="far fa-edit" aria-hidden="true" title="Edit"></i>
<i className="fas fa-mail-bulk" aria-hidden="true" title="Send Email"></i>
<i className="fa fa-reorder" aria-hidden="true" title="Other Actions"></i>
</div>

<div className="candidate_social_icon">
<i className="fa fa-linkedin-square"></i>
<i className="fa fa-twitter-square"></i>
<i className="fa fa-facebook-square"></i>
</div>

</div>


</div>
</div>
{/* fixed */}

<div className="profile_section_start">
<div className="profile_medotry">

<div className="main_line_edit">
<div className="main_title_sec">Origin</div>
<div className="main_title_sec">Sourced</div>
</div>


<div className="main_line_edit">
<div className="main_title_sec">Contact Owner</div>
<div className="main_title_sec">sanjeev.grm@gmail.com</div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Primary Email</div>
<div className="main_title_sec">primaryemail@gmail.com</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Secondary Email</div>
<div className="main_title_sec">secondaryemail@gmail.com</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Work phone</div>
<div className="main_title_sec">+1-5500550055</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Mobile</div>
<div className="main_title_sec">+1-9098909890</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Department</div>
<div className="main_title_sec">Management</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>


<div className="hide_block"><span id="hide_sh_element">Hide Detail <i className="fa fa-chevron-up"></i></span></div>

</div>
</div>


<div id="hide_id" className="">
<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Contact Information </p>

<div className=" double_row">
<div className="main_line_edit">
<div className="main_title_sec">First Name</div>
<div className="main_title_sec">David</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Job Title</div>
<div className="main_title_sec">Product owner</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>


<div className="main_line_edit">
<div className="main_title_sec">Work Phone</div>
<div className="main_title_sec">+1-55-555-55-555</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Primary Email</div>
<div className="main_title_sec">primaryemail@gmail.com</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Fax</div>
<div className="main_title_sec">+1-8909890989</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

</div>


{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">Last Namee</div>
<div className="main_title_sec">Smith</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Department</div>
<div className="main_title_sec">Management</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>


<div className="main_line_edit">
<div className="main_title_sec">Mobile</div>
<div className="main_title_sec">+1-909890987</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Secondary Email</div>
<div className="main_title_sec">secondaryemail@gmail.com</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Skype</div>
<div className="main_title_sec">skypeemail@gmail.com</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>
</div>


</div>

{/* row end */}


<div className="profile_section_start nextroww">
<div className="profile_medotry">

<p className="block_heading"> Address Detail </p>

<div className=" double_row">
<div className="main_line_edit">
<div className="main_title_sec">Country</div>
<div className="main_title_sec">USA</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>



<div className="main_line_edit">
<div className="main_title_sec">City</div>
<div className="main_title_sec">Gulfport</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Street</div>
<div className="main_title_sec">822 Coventry Court</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>


<div className="main_line_edit">
<div className="main_title_sec">Email Opt Out</div>
<div className="main_title_sec">No</div>
</div>

</div>

{/* row end */}

<div className=" double_row">

<div className="main_line_edit">
<div className="main_title_sec">State</div>
<div className="main_title_sec">Mississippi</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>

<div className="main_line_edit">
<div className="main_title_sec">Zip</div>
<div className="main_title_sec">39501</div>
<div className="main_title_sec" id=""><i className="fa fa-pencil"></i></div>
</div>


<div className="main_line_edit">
<div className="main_title_sec">Is Primary Contact</div>
<div className="main_title_sec">No</div>
</div>

</div>

{/* row end */}

</div>
</div>





</div>
</div>

</div>
</div>

</div>


</div>

          <Footer/>
  </div>
    </>
  );
}

}
export default Contactdetail;