import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import Client_filter from './../Pop_up/Client_filter'

const $ = window.$;

class Client_Listing extends React.Component {

  constructor(){
    super();
    this.state={ }
  }

	componentDidMount(){

}

render() {
  return (
    <>
 
  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing ">


<div className="top_user_detail_block">
<div className="top_user_detail_block-inner"><p>Welcome Rakesh</p></div>
</div>

<div className="listing_section_block_section  container">
<div className="job_list_section_block">

<div className="top_section_menu">
<div className="title_name_of_page">
    <h1>Client Listing</h1>
</div>
<div className="add_new_to_page">
    <span className="title_name_drop_down"><i className="fa fa-ellipsis-h"></i></span>
    <div className="list_show_drop_down">
<ul>
<li>xyz</li>
<li>abc</li>
</ul>
</div>
</div>
<div className="add_new_to_page">
    <span><Link to="/client_form"><i className="fa fa-plus"></i> Add new Client</Link></span>
</div>
<div className="add_new_to_page">
    <span className="title_name_drop_down"><i className="fa fa-plus"></i> Import <i className="fa fa-chevron-down"></i></span>
    <div className="list_show_drop_down">
<ul>
<li>Import From Excel</li>
</ul>
</div>
</div>
</div>

<div className="top_page_filter_set">
<div className="search_filter_box">
<input type="text" name=""/><span><i className="fa fa-search"></i></span>
</div>
<div className="posted_on_filter">
<span><i className="fa fa-folder"></i>
<select>
<option>All Clients</option>
<option>My Clients</option>
<option>Recent Clients</option>
</select>
</span>
</div>
<div className="main_filter_box">
<span><Client_filter/></span>
</div>
<div className="date_filter_box">
<span><i className="fa fa-sort-amount-desc"id="asc-desc"></i> Open Date</span>

</div>

</div>

<div className="list_view_result_view">
<div className="left_side_result_view">
<span>2 results</span>
</div>
</div>

</div>
<div className="all_client_listing_view" id="main_list_view">

<table className="table table-bordered">
<thead>
<tr>
    <th></th>
    <th>Client Name</th>
    <th>Contact Number</th>
    <th>Account Manager</th>
    <th>Fax</th>
    <th>Created By</th>
    <th>Industry</th>
</tr>
</thead>
<tbody>
<tr>
    <td><input type="checkbox" /></td>
    <td><Link to="/clientdetail">Samul</Link></td>
    <td>+1-3454389098</td>
    <td>Sanjeev</td>
    <td>+1-55-55-555-555</td>
    <td>Avinash</td>
    <td>IT-software</td>
</tr>
<tr>
    <td><input type="checkbox" /></td>
    <td><Link to="/clientdetail">Samul</Link></td>
    <td>+1-3454389098</td>
    <td>Sanjeev</td>
    <td>+1-55-55-555-555</td>
    <td>Avinash</td>
    <td>IT-software</td>
</tr>
</tbody>
</table>

</div>
<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">Next</a></li>
  </ul>
</nav>
</div>

</div>

</div>

<Footer/>
 
 </>
  );
}

}
export default Client_Listing;