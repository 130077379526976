
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const Choose_account_manager = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle} className="fa fa-user" title="Account Manager"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className} className="nested_model">
        <ModalHeader toggle={toggle}>Choose Account Manager </ModalHeader>

          <ModalBody>
            
<div className="form-inner-box">
<div className="form_search_lits">					
<input type="text" className="jobPost_model"/>
<span className="jobpost_seh_icon">
<i className="fa fa-search"></i>
</span>
</div>
</div>

<div className="interview_section">
<table className="table-bordered table">
<thead>
<tr>
<th>Account Manager</th>
<th>City</th>
<th>Client</th>
<th>Source</th>
</tr>
</thead>
<tbody>
<tr>
<td>David Smith</td>
<td>Gulfport</td>
<td>3</td>
<td>Added by user</td>
</tr>
</tbody>

</table>
</div>
            
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggle}>Cancel</Button>{' '}
                    
              <Button color="primary" onClick={toggle}>Done</Button>{' '}
            </ModalFooter>
          </Modal>
    </>
  );
}

export default Choose_account_manager;