import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import Choose_job from './../Pop_up/Choose_job'

const Interview_pop = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  return (
    <>
    <i onClick={toggle} className="fa fa-calendar-plus" aria-hidden="true" title="Schedule Interview"></i>
      <Modal id="filter_model_cont" isOpen={modal} toggle={toggle} className={className} className="nested_model">
        <ModalHeader toggle={toggle}>Schedule Interview </ModalHeader>

          <ModalBody>


<div className="form-container">

<div className="inner-form-container">
<div className="all-form-width interview">

<div className="signup-form-homepage">

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">Internal Interview</option>
<option value="2">Gernal interview</option>
<option value="3">Online Interview</option>
<option value="4">Phone Interview</option>
</select>

<label>Interview Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value="">Rakesh yadav</option>
<option value="1">Azad</option>
<option value="2">Nida</option>
</select>

<label>Candidate Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value="">Long Finch</option>
<option value="1">Techvilla</option>
</select>

<label>Client Name
</label>
</div>
</div>

<div className="form-inner-box box-choose">
<span class="choose_jobs_">Job_title</span>				
<Choose_job/>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Interviewer Name
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Interview Email
</label>
</div>
</div>



<div className="form-inner-box inter_timedte full_with">					

<div class="interview_time_dte">
<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
  <option value="1">Apr 10, 2020</option>
  <option value="2">Apr 11, 2020</option>
  <option value="3">Apr 12, 2020</option>
  <option value="4">Apr 13, 2020</option>
  <option value="5">Apr 14, 2020</option>
  <option value="6">Apr 15, 2020</option>
</select>

<label>Interview Date
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">09:00 P.M</option>
<option value="2">09:30 P.M</option>
<option value="3">10:00 P.M</option>
<option value="4">10:30 P.M</option>
<option value="4">11:00 P.M</option>
<option value="4">11:30 P.M</option>
<option value="4">12:00 P.M</option>
<option value="4">12:30 P.M</option>
<option value="4">01:00 P.M</option>
</select>

<label>Interview Time
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">PST</option>
<option value="2">EST</option>
<option value="3">CST</option>
</select>

<label>Time Zone
</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">F2F</option>
<option value="2">Skype</option>
<option value="3">Telephonic</option>
<option value="4">Other</option>
</select>

<label>Interview Mode
</label>
</div>
</div>

</div>

</div>

<div className="form-inner-box notify full_with">
<div className="form-inner-box">
<p>Notify Interviewer By</p>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Email</label>
<input type="checkbox" name=""/>
</div>
</div>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Sms</label>
<input type="checkbox" name=""/>
</div>
</div>
</div>


<div className="form-inner-box">
<p>Notify Interviewer By</p>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Email</label>
<input type="checkbox" name=""/>
</div>
</div>
<div className="form-inner-box chck_box_type">					
<div className="floating-label">
<label>Sms</label>
<input type="checkbox" name=""/>
</div>
</div>
</div>
</div>


<div className="form-inner-box full_with">
<div className="form-inner-box left-margin">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">30 min before</option>
<option value="2">60 min before</option>
<option value="3">90 min before</option>
<option value="4">120 min before</option>
</select>

<label>Send reminder to Interviewer
</label>
</div>
</div>

<div className="form-inner-box right-margin">					
<div className="floating-label">      
<select className="floating-select">
<option value=""></option>
<option value="1">30 min before</option>
<option value="2">60 min before</option>
<option value="3">90 min before</option>
<option value="4">120 min before</option>
</select>

<label>Send reminder to Candidate
</label>
</div>
</div>
</div>


<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Invite Venue</label>
</div>
</div>

<div className="form-inner-box full_with">					
<div className="floating-label">      
<textarea className="floating-input floating-textarea" placeholder=" "></textarea>

<label>Invite Message</label>
</div>
</div>


</div>

</div>

</div>

</div>


            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    
              <Button color="primary" onClick={toggle}>Schedule Interview</Button>{' '}
            </ModalFooter>
          </Modal>
    </>
  );
}

export default Interview_pop;