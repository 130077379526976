
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { notify } from 'react-notify-toast';
import Change_password from './../Pop_up/Change_password'
import { get } from './../../services/Apicall';

const $ = window.$;

class Header extends React.Component {





render() {
  return (
    <>



<header className="main_after_login">
<div className="main-container ats_contaiber">

<div class="navbar navbar-default navbar-fixed-top" role="navigation">
    <div class="container-fulid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
          <Link className="navbar-brand" to="/home">
            <img src="/../images/logo.png" alt="Dispute Bills"/>
          </Link>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav  navbar-right">
                <li class="menu_item" id="menu_item"><Link to="/deshboard">Home</Link></li>
                <li class="menu_item" id="menu_item"><Link to="">Activity Wall</Link></li>
                <li class="menu_item" id="menu_item"><Link to="/Job_listing">Jobs</Link></li>
                <li class="menu_item" id="menu_item"><Link to="/Candidate_listing">Candidate</Link></li>
                {/* <li class="menu_item" id="menu_item"><Link to="/candidate/Candidate_detail_page">Candidate1</Link></li> */}
                <li class="menu_item" id="menu_item"><Link to="/Interview_listing">Interview</Link></li>
                <li class="menu_item" id="menu_item"><Link to="/Client_Listing">Clients</Link></li>
                <li class="menu_item" id="menu_item"><Link to="/Vendor_listing">Vendors</Link></li>
                <li class="menu_item" id="menu_item"><Link to="/Contact_listing">Contact</Link></li>
                <li class="dropdown-submenu">
                    <a href="#" class="dropdown-toggle three_dots" data-toggle="dropdown"><i className="fa fa-circle"></i><i className="fa fa-circle"></i><i className="fa fa-circle"></i></a>
                    <ul class="dropdown-menu">
                        <li><Link to="/admin">Admin</Link></li>
                            <li><Link to="/candidate/Create_assessment">Assessment</Link></li>
                            <li><Link to="">Deshboard</Link></li>
                            <li><Link to="/email">Email</Link></li>
                            <li><Link to="/master_email">Email Template</Link></li>
                            <li><Link to="">Reports</Link></li>                          
                    </ul>
                </li>
                <li class="menu_item" id="menu_item"><Link to=""><i className="fa fa-envelope-o"></i></Link></li>
                <li className="profile_user"> 
                <Link to="/candidate/candidate_detail">
                  <img src="./../images/profile_.png" alt=""/>
              </Link>
              </li>
                <li>
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">Rakesh <b class="caret"></b></a>
                    <ul class="dropdown-menu multi-level">
                        <li><Link to="">Rakesh Yadav</Link></li>
                        <li><Link to="">Rakesh@techvilla.in</Link></li>
                        <li><Link to="/myprofile">My Profile</Link></li>
                        <li><Change_password/></li>
                        <li class="divider"></li>
                        <li><Link to="">Log Out</Link></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
</header>   


</>
);
}

}


const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default Header;
