import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ReactDOM from 'react-dom';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import MCQ_component from './../Component/Section/MCQ_component'
import SCQ_component from './../Component/Section/SCQ_component'
import SQ_component from './../Component/Section/SQ_component'
import LQ_component from './../Component/Section/LQ_component'
import Choose_job from './../Pop_up/Choose_job'
const $ = window.$;

class Create_assessment extends React.Component {

constructor(){
super();
this.state={ showOption: false, }
}

componentDidMount(){

}

//   onChange = (e) => {
// this.setState({e.target.name:e.target.value });
//     },
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

   handleOptionChange = (event) => {
      event.preventDefault();
      this.setState({
      showOption : true
    }) 
    console.log('changed')
   }
   
render() {
return (
<>


<div className="main-body emp_body">
<Header_after_login/>

<div className="homepage-outer form-container-listing">


    <div className="top_user_detail_block">
        <div className="top_user_detail_block-inner">
            <p>Welcome Rakesh</p>
        </div>
    </div>

    <div className="form-container">

        <div className="inner-form-container container">
            <div className="all-form-width interview assesment_form_">
                <div className="ass_top_line">
                    <div className="assesement_left_side">
                        <h1 className="main_title">  Create Assessment</h1>
                    </div>

                    <div className="assesement_left_side">
                        <button className="no_color">Publish</button>
                        <button>Preview</button>
                        <button>Draft</button>
                    </div>
                </div>

            <section className="create_full_section">
                <Tabs style={{border:'2px solid green'}} headerStyle={{fontWeight: 'bold'}} activeHeaderStyle={{borderButtom:'2px solid #34749e'}} contentStyle={{backgroundColor:'lightgoldenrodyellow'}}>
                    <Tab label="Assessment Details" >
                    {/* basic info */}

                <div className="create_ass_ment_block">
                    <div className="create_ass_detail">

                        <div className="form-inner-box full_with">					
                            <div className="floating-label">      
                                <input className="floating-input" type="text" placeholder=" "/>
                                <label>Assessment Name</label>
                            </div>
                        </div>
                        
                        <div className="test_expery full_with">
                            <p>Test Expiry</p>
                            <span>Candidates will not be able to access the test after this date and time.</span>
                        </div>

                        <div className="form-inner-box pr-10">					
                            <div className="floating-label">      
                                <input className="floating-input" type="date" placeholder=" "/>
                                <label>Assessment Date</label>
                            </div>
                        </div>


                        <div className="form-inner-box no_margin pl-10">					
                            <div className="floating-label">      
                                <select className="floating-select">
                                    <option value=""></option>
                                    <option value="1">09:00 P.M</option>
                                    <option value="2">09:30 P.M</option>
                                    <option value="3">10:00 P.M</option>
                                    <option value="4">10:30 P.M</option>
                                    <option value="4">11:00 P.M</option>
                                    <option value="4">11:30 P.M</option>
                                    <option value="4">12:00 P.M</option>
                                    <option value="4">12:30 P.M</option>
                                    <option value="4">01:00 P.M</option>
                                </select>
                                <label>Assessment Time</label>
                            </div>
                        </div>

                        <div className="test_expery full_with">
                            <p>Assessment Duration*</p>
                            <span>The Assessment will be automatically submitted when the duration ends.</span>
                        </div>

                        <div className="form-inner-box full_with">					
                            <div className="floating-label">      
                                <select className="floating-select">
                                    <option value=""></option>
                                    <option value="1">10 minutes</option>
                                    <option value="2">15 minutes</option>
                                    <option value="3">20 minutes</option>
                                    <option value="4">30 minutes</option>
                                    <option value="4">40 minutes</option>
                                    <option value="4">50 minutes</option>
                                    <option value="4">60 minutes</option>
                                </select>
                                <label>Assessment Duration</label>
                            </div>
                        </div>

                        <div className="test_expery full_with">
                            <p>Assessment Instructions*</p>
                            <span>Candidates will be shown these before the Assessment starts.</span>
                        </div>

                        <div className="assessment_instuction">
                        <p>Following instructions are common for all job seekers.</p>
                            <ol>
                                <li>The duration of test is 20 minutes*. Your answer gets automatically submitted after 20 minutes*.</li>
                                <li>This test consists of 15* multiple - choice questions.</li>
                                <li>You may attempt the questions in any order.</li>
                                <li>Please select the correct answer and click the "Save and next" button.</li>
                                <li>Please click "skip" if you wish to skip a question.You may come back and answer the question later.</li>
                                <li>Please click on the "Submit Assessment" button after answering all the questions.</li>
                                <li>Do not close the window before submitting the test.</li>
                                <li>Test will be automatically submitted after the given time limit.</li>
                            </ol>
                                <p>* - No of question and time duration will be shown as per the test.</p>

                        <span>Please add your instructions here(if any).</span>
                        <textarea className="ass_instu_new"></textarea>

                        </div>

                        <div className="create_ass_conti">
                        <button>Continue</button>
                        </div>

                    </div>
                </div>

                    {/* end of basic info */}
                    </Tab>
                    <Tab label="Assessment Qestions">
                    {/* add question */}


                    <div className="create_ass_ment_block question_section">
                        <div className="create_ass_detail">

                            <p>Selected Questions- 1/30 <span>(min 10 questions are required to publish)</span></p>

                        <div className="create_ass_conti">
                             <div><i className="fa fa-plus"></i><span>Add Question</span></div>
                        </div>

                        <div className="question_showcase">
                        <div className="question_inner">
                        <p><span>1 ) .</span> Will you Relocat ?</p>
                        </div>
                        <p id="view_ans">
                        View answer >>
                        </p>
                        <div className="answer_inner">
                        <ul>
                        <li>Yes</li>
                        <li>No</li>
                        </ul>
                        </div>
                        <div className="question_inner">
                        <p><span>2 ) .</span> Describe Yourself</p>
                        </div>
                        <p id="view_ans">
                        View answer >>
                        </p>
                        <div className="answer_inner">
                            <span>
                            I am web designer, experience in Html,Css,Jquery,Bootstrap, React.js
                            </span>
                        </div>

                        </div>

                        </div>
                        <div className="create_question">
                        
<div className="assessment_question_and_answer">
  <div className="form-inner-box choose_category">					
    <div className="floating-label">      
        <select name="question_type" className="floating-select" onChange={(e) => { this.onChange(e)} }  id="colorselector">
            <option value=""></option>
            <option value="MCQ">Multiple</option>
            <option value="SCQ">Single Choice</option>
            <option value="LQ">Long answer</option>
            <option value="SQ">Short answer</option>
        </select>
            <label>Assessment Type</label>
    </div>
  </div>
   { this.state.question_type=='MCQ'? <MCQ_component / > :null}
{ this.state.question_type=='SCQ'? <SCQ_component / > :null}
{ this.state.question_type=='LQ'? <LQ_component / > :null}
{ this.state.question_type=='SQ'? <SQ_component / > :null}
    {/* <div id="multiple" className="assess multiple">
        <div className="form-inner-box full_with">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>What would you like to ask?
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="add_question">
        
        <span className="add_que"><i className="fa fa-plus adqu"></i> Add more Option </span>
        </div>

        <form action="">

        <div className="form-submit-button">
        <button className="cancel_btn" type="submit"> Cancel </button>
        <button className="save_btn" type="submit"> Save </button>
        </div>



        </form>

    </div>



    <div id="single" className="assess single">
        <div className="form-inner-box full_with">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>What would you like to ask?
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>

        <div className="form-inner-box">					
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" "/>

        <label>Add Option
        </label>
        </div>
        </div>


        <div className="add_question">
            
             <span className="add_que"><i className="fa fa-plus adqu"></i> Add more Options  </span>
        </div>

            <form action="">

            <div className="form-submit-button">
            <button className="cancel_btn" type="submit"> Cancel </button>
            <button className="save_btn" type="submit"> Save </button>
            </div>



            </form>

    </div>






    <div id="short" className="assess short">
    <div className="form-inner-box full_with">					
    <div className="floating-label">      
    <textarea className="floating-input floating-textarea" placeholder=" "></textarea>
    <span>Not more than 200 Words</span>
    <label>What would you like to ask?</label>
    </div>
    </div> 

    <form action="">

    <div className="form-submit-button">
    <button className="cancel_btn" type="submit"> Cancel </button>
    <button className="save_btn" type="submit"> Save </button>
    </div>



    </form>
    </div>






    <div id="long" className="assess long">
    <div className="form-inner-box full_with">					
    <div className="floating-label">      
    <textarea className="floating-input floating-textarea" placeholder=" "></textarea>
    <span>Not More than 500 words</span>
    <label>What would you like to ask?</label>
    </div>
    </div>

    <form action="">

    <div className="form-submit-button">
    <button className="cancel_btn" type="submit"> Cancel </button>
    <button className="save_btn" type="submit"> Save </button>
    </div>



    </form>

    </div>*/}



</div>                        

                        </div>
                    </div>


                    {/* end of add question */}
                    </Tab>                
                </Tabs>
            </section>

                

            </div>

        </div>

    </div>

</div>



<Footer/>
</div>
</>
);
}

}
export default Create_assessment;