/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

class Industry_name extends React.Component {
constructor(){
super();
 this.state = {
      plainArray: ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"],
      objectArray: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" },
        { key: "Option 3", cat: "Group 1" },
        { key: "Option 4", cat: "Group 2" },
        { key: "Option 5", cat: "Group 2" },
        { key: "Option 6", cat: "Group 2" },
        { key: "Option 7", cat: "Group 2" }
      ],
      selectedValues: [
        { key: "Option 1", cat: "Group 1" },
        { key: "Option 2", cat: "Group 1" }
      ]
    };
    this.style = {
      chips: {
        background: "red"
      },
      searchBox: {
        border: "none",
        "border-bottom": "1px solid blue",
        "border-radius": "0px"
      },
      multiselectContainer: {
        color: "red"
      }
    };
    this.addItem = this.addItem.bind(this);
  }
  addItem() {
    this.selectedValues.push({ key: "Option 3", cat: "Group 1" });
  }

  render() {
    const { plainArray, objectArray, selectedValues } = this.state;
return (
<>
    <Autocomplete
      id="combo-box-demo"
      options={Indusy}
      getOptionLabel={(option) => option.title}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label="Industry" variant="outlined" />}
    />
</>
);
}

}

export default Industry_name;
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const Indusy = [
  
   { title: 'None'},
  { title: 'Communications'},
  { title: 'Technology'},
  { title: 'Government/Military'},
  { title: 'Manufacturing'},

];
