import React from 'react';
import {connect} from 'react-redux';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Notifications from 'react-notify-toast';
import { PrivateRoute, LoginRoute } from './services/Router';
import ScrollToTop from './services/ScrollToTop';
import Homepage from './pages/ATS/Homepage';
import Forgot from './pages/ATS_login/Forgot';
import OTP from './pages/ATS_login/OTP';
import Change_password from './pages/ATS_login/Change_password';

import Signin from './pages/ATS_login/Signin';
import Candidate_listing from './pages/ATS_listing/Candidate_listing';
import Job_listing from './pages/ATS_listing/Job_listing';
import Client_Listing from './pages/ATS_listing/Client_Listing';
import Vendor_listing from './pages/ATS_listing/Vendor_listing';
import Contact_listing from './pages/ATS_listing/Contact_listing';
import Interview_listing from './pages/ATS_listing/Interview_listing';
import Manage_user_list from './pages/ATS_listing/Manage_user_list';

import Candiadte_form from './pages/ATS_form/Candiadte_form';
import Email from './pages/ATS_form/Email';

import Client_form from './pages/ATS_form/Client_form';
import Contact_form from './pages/ATS_form/Contact_form';
import Vendor_form from './pages/ATS_form/Vendor_form';
import Contactdetail from './pages/ATS/Contactdetail';
import Admin from './pages/ATS/Admin';

import Vendordetail from './pages/ATS/Vendordetail';
import Clientdetail from './pages/ATS/Clientdetail';
import Deshboard from './pages/ATS/Deshboard';
// import Candidate_status_category from './pages/ATS_common/Candidate_status_category';
import Candidate_status_management from './pages/Hiring_mgmt/Candidate_status_management';

import Myprofile from './pages/ATS_form/Myprofile';
import System_config from './pages/ATS_form/System_config';
import Company_profile from './pages/ATS_form/Company_profile';
import Manage_user from './pages/ATS_form/Manage_user';
import User_access from './pages/ATS/User_access';

import Client_submit from './pages/ATS_form/Client_submit';
import Company_detail from './pages/ATS_form/Company_detail';
import Job_post_form from './pages/ATS_form/Job_post_form';
import Index from './pages/ATS/Index';
import Master from './pages/ATS/Master';
import Master_email from './pages/ATS/Master_emailer';
import Candidate_detail from './pages/Candidate/Candidate_detail';
import Candidate_detail_page from './pages/Candidate/Candidate_detail_page';
import Create_assessment from './pages/Candidate/Create_assessment';


import Interview_form from './pages/Candidate/Interview_form';
import Job_Detail from './pages/Jobs/Job_Detail';
import Assessment from './pages/Jobs/Assessment';


import Job_list_filter from './pages/Jobs/Job_list_filter';
import Navbar from 'react-bootstrap/Navbar'
import $ from 'jquery';


function App(prop) {
  if (prop.redirectToHome === true) {
    prop.redirect(false);
    return <Redirect to={'/'} />
  }

 
  return (
    <>
      <ScrollToTop />
      <Notifications />

      <Route exact path="/home" component={Homepage} />
      <Route exact path="/master" component={Master} />
      <Route exact path="/master_email" component={Master_email} />
      <Route exact path="/" component={Index} />
      <Route exact path="/Forgot" component={Forgot} />
      <Route exact path="/OTP" component={OTP} />
      <Route exact path="/Change_password" component={Change_password} />
      
      <Route exact path="/Signin" component={Signin} />
      <Route exact path="/Candidate_listing" component={Candidate_listing} />
      <Route exact path="/Client_Listing" component={Client_Listing} />
      <Route exact path="/Vendor_listing" component={Vendor_listing} />
      <Route exact path="/Contact_listing" component={Contact_listing} />
      <Route exact path="/Interview_listing" component={Interview_listing} />
      <Route exact path="/Assessment" component={Assessment} />
      <Route exact path="/manage_user_list" component={Manage_user_list} />
      <Route exact path="/email" component={Email} />
       
      <Route exact path="/Job_listing" component={Job_listing} />
      <Route exact path="/candidate/candidate_detail" component={Candidate_detail} />
      <Route exact path="/candidate/Candidate_detail_page" component={Candidate_detail_page} />
      <Route exact path="/candidate/Create_assessment" component={Create_assessment} />
      
      <Route exact path="/Interview_form" component={Interview_form} />
      <Route exact path="/Job/Job_Detail" component={Job_Detail} />
      <Route exact path="/Job/Job_list_filter" component={Job_list_filter} />
      <Route exact path="/client_submit" component={Client_submit} />
      <Route exact path="/myprofile" component={Myprofile} />
      <Route exact path="/system_config" component={System_config} />
      <Route exact path="/company_profile" component={Company_profile} />
      <Route exact path="/manage_user" component={Manage_user} />
      <Route exact path="/user_access" component={User_access} />
      <Route exact path="/admin" component={Admin} />
      
      <Route exact path="/contact_detail" component={Contactdetail} />
      <Route exact path="/vendordetail" component={Vendordetail} />
      <Route exact path="/clientdetail" component={Clientdetail} />
      <Route exact path="/client_form" component={Client_form} />
      <Route exact path="/contact_form" component={Contact_form} />
      <Route exact path="/vendor_form" component={Vendor_form} />
      
      <Route exact path="/Candiadte_form" component={Candiadte_form} />
      <Route exact path="/Company_detail" component={Company_detail} />
      <Route exact path="/Job_post_form" component={Job_post_form} />
      <Route exact path="/deshboard" component={Deshboard} />
      {/* <Route exact path="/Candidate_status_category" component={Candidate_status_category} /> */}
      <Route exact path="/Candidate_status_management" component={Candidate_status_management} />
      
      {/* <Route exact path="/assesment" component={Assesment} />
      <Route exact path="/new_assessment" component={Assessment_form} /> */}


    </>

  );
}

const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}
//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
