import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Header_after_login from './../ATS_common/Header_after_login'
import Footer from './../ATS_common/Footer'
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
// import Job_list_filter from './../Pop_up/Job_list_filter'


const $ = window.$;

class Job_list_filter extends React.Component {

  constructor(){
    super();
     this.state = {
      showMenu: false,
    };
    
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

showMenu(event,id) {
    event.preventDefault();
    
    this.setState({ showMenu: id }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }
	componentDidMount(){

}

render() {
  return (




    <>
  


  <div className="main-body emp_body">
  <Header_after_login/>
  

  <div className="homepage-outer form-container-listing candidate_page mt-50">
<div className="container-fluid">

<div className="job_filter_name_heading">
<div className="job_heding_name">
<span>
<Link to="">
<i className="fa fa-long-arrow-left"></i>
</Link>
</span>
<span>
Candidate associated for <strong>Font-End Developer</strong>
</span>
<div className="right_align">
{/* <Job_list_filter/> */}
</div>
</div>
</div>

<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 right_fix_side side_content_full jb_list_pge">

   <Tabs defaultTab="vertical-tab-one" vertical className="vertical-tabs mt-110">
      <TabList className="filter_type_menu">
<Tab tabFor="vertical-tab-one">    
<button onClick={(e)=>this.showMenu(e,1)} className={(this.state.showMenu==1)?"single_node_inner darkblue active":"single_node_inner darkblue"}>
<div className="job_upper_menu">
<span>1</span>
<span>Screening</span>
</div>
</button>
</Tab>
<Tab tabFor="vertical-tab-two">
<button onClick={(e)=>this.showMenu(e,2)} className={(this.state.showMenu==2)?"single_node_inner darkblue active":"single_node_inner violet"}>
<div className="job_upper_menu">
<span>1</span>
<span>Client Submission</span>
</div>
</button>

</Tab>
<Tab tabFor="vertical-tab-three">
<button onClick={(e)=>this.showMenu(e,3)} className={(this.state.showMenu==3)?"single_node_inner darkblue active":"single_node_inner  chocolate"}>
<div className="job_upper_menu">
<span>1</span>
<span>Client Interview</span>
</div>
</button>
</Tab>
<Tab tabFor="vertical-tab-four">
<button onClick={(e)=>this.showMenu(e,4)} className={(this.state.showMenu==4)?"single_node_inner darkblue active":"single_node_inner  yellow"}>
<div className="job_upper_menu">
<span>1</span>
<span>Offered</span>
</div>
</button>
</Tab>
<Tab tabFor="vertical-tab-five">
<button onClick={(e)=>this.showMenu(e,5)} className={(this.state.showMenu==5)?"single_node_inner darkblue active":"single_node_inner  lightgreen"}>
<div className="job_upper_menu">
<span>1</span>
<span>hired</span>
</div>
</button>
</Tab>
<Tab tabFor="vertical-tab-six">
<button onClick={(e)=>this.showMenu(e,6)} className={(this.state.showMenu==6)?"single_node_inner darkblue active":"single_node_inner  gray"}>
<div className="job_upper_menu">
<span>1</span>
<span>Other</span>
</div>
</button>

</Tab>
{this.props.children}
</TabList>

<TabPanel tabId="vertical-tab-one">
<table className="table-bordered table">
  <tr>
    <th></th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>
  <tr>
<td><input type="checkbox"/> </td>

<td>
<div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div>
</td>

<td><span>Gulfport</span></td>
<td><span>mr. dev</span></td>

<td><span>Interview Scheduled</span></td>

<td>Apr 11 , 2020</td>

<td>Resume inbox</td>

  </tr>
</table>
      </TabPanel>
      <TabPanel tabId="vertical-tab-two">
<table className="table-bordered table">
  <tr>
    <th><input type="checkbox"/> </th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>
  <tr>
<td><input type="checkbox"/> </td>

<td>
<div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div>
</td>

<td><span>Gulfport</span></td>
<td><span>mr. dev</span></td>

<td><span>Interview Scheduled</span></td>

<td>Apr 11 , 2020</td>

<td>Resume inbox</td>

  </tr>
</table>
      </TabPanel>

      <TabPanel tabId="vertical-tab-three">
<table className="table-bordered table">
  <tr>
    <th><input type="checkbox"/> </th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>
  <tr>
<td><input type="checkbox"/> </td>

<td>
<div className="rerating">
<span className="rating_sys">4.2 <i className="fa fa-star"></i></span>
</div>
</td>

<td><span>Gulfport</span></td>
<td><span>mr. dev</span></td>

<td><span>Interview Scheduled</span></td>

<td>Apr 11 , 2020</td>

<td>Resume inbox</td>

  </tr>
</table>
      </TabPanel>

      <TabPanel tabId="vertical-tab-four">
<table className="table-bordered table">
  <tr>
    <th><input type="checkbox"/> </th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>

</table>
      </TabPanel>

      <TabPanel tabId="vertical-tab-five">
<table className="table-bordered table">
  <tr>
    <th><input type="checkbox"/> </th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>

</table>
      </TabPanel>

      <TabPanel tabId="vertical-tab-six">
<table className="table-bordered table">
  <tr>
    <th><input type="checkbox"/> </th>
    <th>Rating </th>
    <th>Candidate Name</th>
    <th>City</th>
    <th>Candidate Status</th>
    <th>Modified Time</th>
    <th>Source</th>
  </tr>

</table>
      </TabPanel>



      </Tabs>

</div>

</div>
  </div>

          <Footer/>
  </div>
    </>
  );
}

}
export default Job_list_filter;