import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Tag_input from './../Pop_up/Tag_input'


const Change_password = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }


  return (


      
    <>

    <span onClick={toggle}> Change Password</span>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Change Password </ModalHeader>
        <ModalBody>
        <div className="row">
<div className="change_password">
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Old Password</label>
</div>
</div>

<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>New Password</label>
</div>
</div>
<div className="form-inner-box">					
<div className="floating-label">      
<input className="floating-input" type="text" placeholder=" "/>

<label>Confirm Password</label>
</div>
</div>

<div className="password_notes">
<p><strong>Note:</strong> Password must contain Minimum 8 and Maximum 16 characters</p>
<ul>
<li> Atleast 1 Upper Case Alphabet</li>
<li>1 Lower Case Alphabet</li>
<li>1 Number</li>
<li>1 Special Character</li>
</ul>
</div>

</div>
</div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Cancel</Button>          
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Change_password;